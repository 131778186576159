import { Injectable } from '@angular/core';
import { ApiProgramWorkoutUserInsightService } from '../services';
import { BehaviorSubject, Observable } from 'rxjs';
import { InsightType } from '../enums';
import { ProgramSection, WorkoutContent } from '../models';

@Injectable()
export class SectionContentStore {
	private currentSection: ProgramSection;
	private currentContentIndex: number;
	private currentSectionIndex: number;
	private sections: ProgramSection[];

	private selectedContent: BehaviorSubject<WorkoutContent | null> = new BehaviorSubject<WorkoutContent | null>(null);
	readonly selectedContent$: Observable<WorkoutContent | null> = this.selectedContent.asObservable();

	constructor(private userInsightService: ApiProgramWorkoutUserInsightService
	) {
		this.currentContentIndex = 0;
		this.currentSectionIndex = 0;
	}

	/**
		Assigns the sections that would be used for navigation.
	*/
	assignSections(sections: ProgramSection[]): void {
		this.sections = sections;
	}

	getCurrentSection(): ProgramSection {
		this.currentSection = this.sections[this.currentSectionIndex];
		return this.currentSection;
	}

	getCurrentSectionIndex(): number {
		return this.currentSectionIndex;
	}

	getCurrentContentIndex(): number {
		return this.currentContentIndex;
	}

	setCurrentSection(index: number): void {
		this.currentSectionIndex = index;
		this.resetContentIndexCount();
	}

	setSection(index: number): void {
		this.currentSectionIndex = index;
		this.setCurrentSection(this.currentSectionIndex);
	}

	nextContent(): void {
		const programSectionLength: number = this.sections.length - 1;
		if (programSectionLength === this.currentSectionIndex &&
			this.sections[programSectionLength].workouts.length - 1 === this.currentContentIndex) {
			// Do nothing since it's the last content
		} else {
			this.currentContentIndex++;

			if (this.getCurrentSection().workouts.length - 1 < this.currentContentIndex) {
				this.nextSection();
				this.resetContentIndexCount();
			}

			this.setCurrentContent(this.getCurrentSection().workouts[this.currentContentIndex]);

		}
	}

	nextSection(): void {
		this.currentSectionIndex++;
		this.setCurrentSection(this.currentSectionIndex);
	}

	previousContent(): void {
		if (this.getCurrentSectionIndex() === 0 && this.getCurrentContentIndex() === 0) {
			// Do nothing since you're at the first
		} else {
			this.currentContentIndex--;

			if (this.currentContentIndex < 0) {
				this.previousSection();
				this.currentContentIndex = this.getCurrentSection().workouts.length - 1;
			}

			this.setCurrentContent(this.getCurrentSection().workouts[this.currentContentIndex]);

		}
	}

	previousSection(): void {
		this.currentSectionIndex--;
		this.setCurrentSection(this.currentSectionIndex);
	}

	setCurrentContent(content: WorkoutContent): void {
		this.selectedContent.next(content);
		this.toggleHasViewedContent();
	}

	async toggleHasCompletedContent(index: number, hasCompletedState: boolean): Promise<void> {
		this.getCurrentSection().workouts[index].programSectionWorkoutHasCompleted = hasCompletedState;
		const workoutContent: WorkoutContent = this.getCurrentSection().workouts[index];

		try {
			await this.userInsightService.addInsights(workoutContent?.programSectionWorkoutId, InsightType.Completed);

		} catch (error) {
			throw error;
		}
	}

	async toggleHasViewedContent(): Promise<void> {
		const workoutContent: WorkoutContent = this.getCurrentSection()?.workouts[this.currentSectionIndex];
		try {
			setTimeout(async () => {
				await this.userInsightService.addInsights(workoutContent?.programSectionWorkoutId, InsightType.Viewed);
			}, 30000);

		} catch (error) {
			throw error;
		}
	}

	private resetContentIndexCount() {
		this.currentContentIndex = 0;
	}
}
