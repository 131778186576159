import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { CustomError } from '../models/custom-error.model';
import { Guid } from '@fitscovery/common/types';
import { ProgramService } from '../interfaces';
import { throwError, Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Program } from '../models';

import { Environment } from '@fitscovery/common/environments';
import { APP_ENVIRONMENT } from '../environment-injection';

@Injectable()
export class ApiProgramService implements ProgramService {

	constructor(
		@Inject(APP_ENVIRONMENT) private env: Environment,
		private httpService: HttpClient) {
	}

	public async getProgramsByPartnerId(partnerId: Guid) {
		return (this.httpService.get(`${this.env.azureFunctionsApi.workouts}/programs/partners/${partnerId}`) as any)
			.pipe(
				map((programs: Program[]) => {
					return programs;
				}),
				catchError((error: CustomError) => {
					return throwError(error);
				})
			);
	}

	getProgramsByPartnerIdWithPaging(
    partnerId: Guid,
    pageNumber: number,
    pageSize: number
  ): Observable<Program[]> {
    const baseUrl = this.env.azureFunctionsApi.workouts; 
    const url = `${baseUrl}/programs/partners/${partnerId}`;
    const body = { pageNumber, pageSize };
		return this.httpService.post(url, body) as Observable<Program[]>;
	}

	public getProgramById(programId: Guid) {
		return (this.httpService.get(`${this.env.azureFunctionsApi.workouts}/programs/${programId}`) as any)
			.pipe(
				map((programs: Program) => {
					return programs;
				}),
				catchError((error: CustomError) => {
					return throwError(error);
				})
			);
	}

	getProgramByIdAndSubdomain(programId: Guid, subdomain: string) {
		return this.httpService.get(`${this.env.azureFunctionsApi.workouts}/programs/${programId}/subdomain/${subdomain}`);
	}

	GetProgramByIdAsAdmin(id: string, partnerId: string): Observable<Program> {
		return (this.httpService.get(`${this.env.azureFunctionsApi.workouts}/programs/${id}/partners/${partnerId}/admin`) as any)
			.pipe(
				map((programs: Program) => {
					return programs;
				}),
				catchError((error: CustomError) => {
					return throwError(error);
				})
			);
	}

	CreateProgram(program: Program): Observable<any> {
		const postData: any = program;
		return this.httpService.post(`${this.env.azureFunctionsApi.workouts}/programs`, postData);
	}

	DeleteProgram(partnerId: string, programId: string): Observable<any> {
		return this.httpService.delete(`${this.env.azureFunctionsApi.workouts}/partners/${partnerId}/programs/${programId}`);
	}

	DeleteProgramSection(partnerId: string, programSectionId: string): Observable<any> {
		return this.httpService.delete(`${this.env.azureFunctionsApi.workouts}/partners/${partnerId}/programs/sections/${programSectionId}`);
	}

	DeleteProgramSectionWorkout(partnerId: string, programSectionWorkoutId: string): Observable<any> {
		return this.httpService.delete(`${this.env.azureFunctionsApi.workouts}/partners/${partnerId}/programs/sections/workouts/${programSectionWorkoutId}`);
	}

	UpdateProgram(program: Program): Observable<any> {
		const postData: any = program;
		return this.httpService.put(`${this.env.azureFunctionsApi.workouts}/programs`, postData);
	}

  setProgramOrder(id: Guid, partnerId: Guid, orderNumber: number) {
    const endpoint = `${this.env.azureFunctionsApi.workouts}/programs/${id}/order-number`;
    return this.httpService.put(endpoint, {
      id, partnerId, orderNumber
    })
  }
}
