export enum MembershipStatus {
	Pending,
	Active,
	Expired,
	Cancelled,
	Interested,
	Archived,
	Free,
	ForActivation
}

export enum TimePeriod {
	Days,
	Weeks,
	Months,
	Years
}

export enum ImportType {
  Ignore,
  Overwrite,
  Extend
}
