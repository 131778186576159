import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { Guid } from '@fitscovery/common/types';
import { GetEventActiveMembersResponse } from '../models';

import { Environment } from '@fitscovery/common/environments';
import { APP_ENVIRONMENT } from '../environment-injection';

@Injectable()
export class ApiPartnerMemberService {

  constructor(
    @Inject(APP_ENVIRONMENT) private env: Environment,
    private http: HttpClient
  ) { }

  getEventActiveMembers(partnerId: Guid, eventStart: Date, numberOfTicket: number): Observable<GetEventActiveMembersResponse> {
    return this.http.post<GetEventActiveMembersResponse>(`${this.env.azureFunctionsApi.memberships}/members/partners/${partnerId}/status-active`, {
      partnerId, eventStart, numberOfTicket
    });
  }

}
