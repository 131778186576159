import { NgModule } from '@angular/core';
import { NgxsModule, NoopNgxsExecutionStrategy } from '@ngxs/store';
import { NgxsStoragePluginModule, StorageOption } from '@ngxs/storage-plugin';
import { NgxsRouterPluginModule } from '@ngxs/router-plugin';
import { NgxsLoggerPluginModule } from '@ngxs/logger-plugin';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';

import { 
  STATES, 
  STORED_STATES, 
  STATE_MODULES_IMPORT, 
  filterActionInNgxsLoggerPlugin
} from './app-store.provider';

import { environment } from '../environments/environment';

@NgModule({
  imports: [
    NgxsModule.forRoot(STATES, {
      compatibility: {
        strictContentSecurityPolicy: true
      },
      executionStrategy: NoopNgxsExecutionStrategy
    }),
    NgxsStoragePluginModule.forRoot({
      key: STORED_STATES,
      storage: StorageOption.LocalStorage
    }),
    NgxsRouterPluginModule.forRoot(),
    NgxsLoggerPluginModule.forRoot({
      disabled: environment.production,
      filter: filterActionInNgxsLoggerPlugin
    }),
    NgxsReduxDevtoolsPluginModule.forRoot({
      disabled: environment.production
    }),
    ...STATE_MODULES_IMPORT
  ]
})
export class AppStoreModule { }
