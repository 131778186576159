import { Component, HostListener, OnInit } from '@angular/core';
import { ViewportScroller } from '@angular/common';

import { NavbarService } from './navbar.service';
import { SnackbarService } from '@fitscovery/ui/snackbar';

@Component({
  selector: 'navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {

  isShadow: boolean;
  isUserPartnerDetailsOpened: boolean;
  overlayState: boolean;

  constructor(
    private viewPortScroller: ViewportScroller,
    private snackbar: SnackbarService,
    public service: NavbarService
  ) { }

  ngOnInit(): void {
    this.snackbar.destroyAll();
    this.service.checkSession();
    this.service.checkSessionCookie();
  }

  dummyImgUrl(initial: string): string {
    return `https://dummyimage.com/48x48/c76262/ffffff.png&text=${initial[0]}`;
  }
  
  @HostListener('window:scroll', ['$event'])
  scroll() {
    const scrollPosition = this.viewPortScroller.getScrollPosition();
    this.isShadow = scrollPosition[1] > 10;
  }

  toggleOverlay() {
    this.isUserPartnerDetailsOpened = !this.isUserPartnerDetailsOpened;
    this.overlayState = this.isUserPartnerDetailsOpened;
  }

  closeOverlay() {
    this.overlayState = false;
    setTimeout(() => {
      this.isUserPartnerDetailsOpened = false;
    }, 250);
  }

}
