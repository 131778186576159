import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Workout, Exercise } from '../models';
import { CustomError } from '../models/custom-error.model';
import { Observable, throwError } from 'rxjs';
import { map } from 'rxjs/operators';
import { catchError } from 'rxjs/operators';
import { WorkoutService } from '../interfaces/workout-service.interface';

import { Environment } from '@fitscovery/common/environments';
import { APP_ENVIRONMENT } from '../environment-injection';
import { Guid } from '@fitscovery/common/types';

@Injectable()
export class ApiWorkoutService implements WorkoutService {

	constructor(
		@Inject(APP_ENVIRONMENT) private env: Environment,
		private httpService: HttpClient,
	) {
	}

	CreateWorkout(workout: Workout): Observable<any> {
		const postData: any = workout;
		return this.httpService.post(`${this.env.azureFunctionsApi.workouts}/workouts`, postData);
	}

	UpdateWorkout(workout: Workout): Observable<any> {
		const postData: any = workout;
		return this.httpService.put(`${this.env.azureFunctionsApi.workouts}/workouts/${workout.id}`, postData);
	}

	GetWorkout(id: string): Observable<Workout> {
		return this.httpService.get(`${this.env.azureFunctionsApi.workouts}/workouts/${id}`)
			.pipe(
				map((workouts: any) => {
					return workouts;
				}),
				catchError((error: CustomError) => {
					return throwError(error);
				})
			);
	}

	getWorkoutByIdWithSubdomain(id: string, subdomain: string): Observable<Workout> {
		return this.httpService.get<Workout>(`${this.env.azureFunctionsApi.workouts}/workouts/${id}/subdomain/${subdomain}`);
	}

	GetMemberOnlyWorkoutById(id: string): Observable<Workout> {
		return this.httpService.get(`${this.env.azureFunctionsApi.workouts}/workouts/members-only/${id}`)
			.pipe(
				map((workouts: any) => {
					return workouts;
				}),
				catchError((error: CustomError) => {
					return throwError(error);
				})
			);
	}

	getMemberOnlyWorkoutByIdWIthoutAuth(id: string): Observable<any> {
		return this.httpService.get(`${this.env.azureFunctionsApi.workouts}/workouts/members-only-for-seo/${id}`);
	}

	getMemberOnlyWorkoutByIdWithSubdomain(id: string, subdomain: string): Observable<any> {
		return this.httpService.get(`${this.env.azureFunctionsApi.workouts}/workouts/members-only/${id}/subdomain/${subdomain}`);
	}

	GetWorkoutByIdAsAdmin(id: string, partnerId: string): Observable<Workout> {
		return this.httpService.get(`${this.env.azureFunctionsApi.workouts}/workouts/${id}/partners/${partnerId}/admin`)
			.pipe(
				map((workouts: any) => {
					return workouts;
				}),
				catchError((error: CustomError) => {
					return throwError(error);
				})
			);
	}

	GetMemberOnlyWorkout(id: string, token: string): Observable<Workout> {
		return this.httpService.get(`${this.env.azureFunctionsApi.workouts}/workouts/members-only/${id}`)
			.pipe(
				map((workouts: any) => {
					return workouts;
				}),
				catchError((error: CustomError) => {
					return throwError(error);
				})
			);
	}

	GetWorkoutsBySubdomain(subdomain: string, isAdmin: boolean): Observable<Workout[]> {
		const postService = isAdmin ? this.httpService.get(`${this.env.azureFunctionsApi.workouts}/workouts/partners/subdomain/${subdomain}/admin`) as any
			: this.httpService.get(`${this.env.azureFunctionsApi.workouts}/workouts/partners/subdomain/${subdomain}`) as any;

		return postService
			.pipe(
				map((workouts: Workout[]) => {
					return workouts;
				}),
				catchError((error: CustomError) => {
					return throwError(error);
				})
			);
	}

	getWorkoutsBySubdomainWithPaging(
    subdomain: string,
    pageNumber: number,
    pageSize: number,
    isAdmin: boolean
  ): Observable<Workout[]> {
    const baseUrl = this.env.azureFunctionsApi.workouts;
    const url = `${baseUrl}/workouts/partners/subdomain/${subdomain}${isAdmin ? '/admin': ''}`;
    const body = { pageNumber, pageSize };
    return this.httpService.post(url, body) as Observable<Workout[]>;
	}

	UpdateExercise(exercise: Exercise): Observable<any> {
		const postData: any = exercise;
		return this.httpService.put(`${this.env.azureFunctionsApi.workouts}/workouts/${exercise.workoutId}/exercises/${exercise.id}`, postData);
	}

	CreateExercise(exercise: Exercise): Observable<any> {
		const postData: any = {
			exercises: [exercise],
			partnerId: exercise.partnerId,
			workoutId: exercise.workoutId
		};
		return this.httpService.post(`${this.env.azureFunctionsApi.workouts}/workouts/${exercise.workoutId}/exercises/`, postData);
	}

	DeleteWorkout(partnerId: string, workoutId: string): Observable<any> {
		return this.httpService.delete(`${this.env.azureFunctionsApi.workouts}/partners/${partnerId}/workouts/${workoutId}`);
	}

	DeleteExercise(exercise: Exercise): Observable<any> {
		return this.httpService.delete(`${this.env.azureFunctionsApi.workouts}/partners/${exercise.partnerId}/workouts/${exercise.workoutId}/exercises/${exercise.id}`);
	}

  setWorkoutOrder(id: Guid, partnerId: Guid, orderNumber: number) {
    const endpoint = `${this.env.azureFunctionsApi.workouts}/workouts/${id}/order-number`;
    return this.httpService.put(endpoint, {
      id, partnerId, orderNumber
    })
  }

}
  