import { NgModule } from '@angular/core';

import { PluralPipe } from './plural.pipe';

const PIPES = [
  PluralPipe
];

@NgModule({
  declarations: [
    ...PIPES,
  ],
  exports: [
    PluralPipe
  ]
})
export class PluralModule { }
