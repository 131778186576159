import { Injectable } from '@angular/core';
import { Action, createSelector, NgxsOnInit, State, StateContext, StateToken } from '@ngxs/store';
import { switchMap, tap } from 'rxjs/operators';

import { ApiMembershipService, ApiPartnerService } from '../services';
import { SnackbarService } from '@fitscovery/ui/snackbar';
import { MembershipAction } from '../actions';
import { GetMembershipsResponse, ImportMembersResponse, MembershipsStateModel, MembershipStatusState, MembersState } from '../models';
import { errorHandler } from '@fitscovery/common/providers';

export const membersState: MembersState = {
  data: [],
  page: 0,
  count: 0
}

export const membershipsStateDefaults: MembershipsStateModel = {
  pendingMembers: membersState,
  activeMembers: membersState,
  expiredMembers: membersState,
  cancelledMembers: membersState,
  interestedMembers: membersState,
  archivedMembers: membersState,
  freeMembers: membersState,
  forActivationMembers: membersState,
};

export const MEMBERSHIPS_STATE_TOKEN = new StateToken<MembershipsStateModel>('memberships');

@State<MembershipsStateModel>({
	name: MEMBERSHIPS_STATE_TOKEN,
	defaults: membershipsStateDefaults
})

@Injectable()
export class MembershipsState implements NgxsOnInit {

  static memberships(type: MembershipStatusState): (state: MembershipsStateModel) => MembersState | null {
    return createSelector([MembershipsState], (state: MembershipsStateModel) => {
      return state[type || 'activeMembers']!;
    });
  }

	constructor(
    private membershipService: ApiMembershipService,
    private partnerService: ApiPartnerService,
    private snackbar: SnackbarService
  ) { }

	ngxsOnInit(): void {
	}

	@Action(MembershipAction.ImportMembers)
	importMembers(_: StateContext<MembershipsStateModel>, action: MembershipAction.ImportMembers) {
    return this.membershipService.importMembers(action.formData).pipe(
      tap((response: ImportMembersResponse) => { // TODO: Make the code shorter using ngxs state operators 

        const userAndMembershipResponses = response.userAndMembershipResponses;
        const hasInvalidEmail = userAndMembershipResponses.errors.filter((e: string) => e.includes('INVALID_EMAIL')).length > 0;
        
        if (hasInvalidEmail) {
          this.snackbar.openSnack({ message: 'Invalid email(s) found. Please try again.' });
          return;
        }
  
        if (!userAndMembershipResponses.data) {
          this.snackbar.openSnack({ message: userAndMembershipResponses.errors[0] });
          return;
        }
  
        const message = userAndMembershipResponses.data;
        const numbers = message.replace('and', ',').split(',').map((e: string) => e.trim().replace(/\D/g, ''));
        const usersFound = numbers[0];
        const emailAlreadyExists = numbers[2];
  
        const foundMessage = `${usersFound} members found.`
        const existMessage = `${emailAlreadyExists} emails already exist.`
  
        this.snackbar.openSnack({ message: `New members added!` }).afterDismissed().pipe(
          switchMap(() => this.snackbar.openSnack({ message: foundMessage, duration: 1700 }).afterDismissed()),
          switchMap(() => this.snackbar.openSnack({ message: existMessage, duration: 1700 }).afterDismissed())
        ).subscribe();
      }),
      errorHandler(this.snackbar)
    );
	}

	@Action(MembershipAction.CreateMember)
	createMember(_: StateContext<MembershipsStateModel>, action: MembershipAction.CreateMember) {
    return this.membershipService.createMember(action.member).pipe(
      errorHandler(this.snackbar)
    );
	}

	@Action(MembershipAction.ArchiveMembership)
	archiveMembership(_: StateContext<MembershipsStateModel>, action: MembershipAction.ArchiveMembership) {
    return this.membershipService.archiveMembership(action.membership).pipe(
      errorHandler(this.snackbar)
    );
	}

	@Action(MembershipAction.UpdateMembership)
	updateMember(_: StateContext<MembershipsStateModel>, action: MembershipAction.UpdateMembership) {
    return this.membershipService.updateMembership(action.membership).pipe(
      errorHandler(this.snackbar)
    );
	}

	@Action(MembershipAction.StopRecurringMembership)
	stopRecurringMembership(_: StateContext<MembershipsStateModel>, action: MembershipAction.StopRecurringMembership) {
    return this.membershipService.stopRecurringMembership(action.membership).pipe(
      errorHandler(this.snackbar)
    );
	}

	@Action(MembershipAction.GetMembersByStatus)
	getMembersByPartnerId(ctx: StateContext<MembershipsStateModel>, { partnerId, state, data }: MembershipAction.GetMembersByStatus) {
    return this.partnerService.getMembersByStatus(partnerId, data.offset, data.limit, data.value).pipe(
      errorHandler(this.snackbar),
      tap((response: GetMembershipsResponse) => ctx.patchState({ [state]: {
        data: ctx.getState()[state].data?.concat(response.data), page: data.page, count: response.meta.count } }))
    );
	}

  @Action(MembershipAction.ResetMembersState)
  resetMembers(ctx: StateContext<MembershipsStateModel>, action: MembershipAction.ResetMembersState) {
    if (action.state) {
      ctx.patchState({ [action.state]: membersState });
    } else {
      ctx.patchState(membershipsStateDefaults);
    }
  }

}
