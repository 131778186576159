import { Inject, Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { Router } from '@angular/router';
import { SegmentAnalytics, USER_AUTH_EVENTS } from '@fitscovery/utils/logger';
import { Action, State, StateContext, StateToken } from '@ngxs/store';
import { firstValueFrom } from 'rxjs';

import {
  FirebaseState, AuthState, SessionState, AuthAzureState,
  authStateDefaults, sessionStateDefaults 
} from '.';
import { ApiAuthAction } from '../actions';
import { ApiAuthStateModel } from '../models';
import { ApiSessionService } from '../services';
import { APP_TOKEN, Token } from '../environment-injection';

export const apiAuthStateDefaults: ApiAuthStateModel = {
  auth: authStateDefaults,
  session: sessionStateDefaults
};

export const API_AUTH_STATE_TOKEN = new StateToken<ApiAuthStateModel>('api_auth');

@State<ApiAuthStateModel>({
	name: API_AUTH_STATE_TOKEN,
	defaults: apiAuthStateDefaults,
  children: [
    FirebaseState,
    AuthState,
    SessionState,
    AuthAzureState
  ]
})

@Injectable()
export class ApiAuthState {

	constructor(
    @Inject(APP_TOKEN) private appToken: Token,
    private router: Router,
    private auth: AngularFireAuth,
    private appInsights: SegmentAnalytics,
    private sessionService: ApiSessionService
  ) { }

  @Action(ApiAuthAction.SignOut)
	async signOut(ctx: StateContext<ApiAuthStateModel>) {
    this.appInsights.trackEvent(USER_AUTH_EVENTS.LOGOUT, { 'logout_site': this.appToken.app });
    try {
      await firstValueFrom(this.sessionService.signOutClearSession());
    } catch (error) {
    } finally {
      ctx.setState(apiAuthStateDefaults);
      this.auth.signOut();
      if (
        this.appToken.app === 'partner'
        || this.appToken.app === 'onboarding'
      ) return;
      if (this.appToken.app !== 'account') {
        sessionStorage.clear();
        localStorage.clear();
      }
      setTimeout(() => {
        this.router.navigate(
          [ this.appToken.app === 'account' ? '/auth/login' : '/' ],
          { queryParamsHandling: 'preserve' }
        );
      }, 100);
    }
  }

}
