import { NgModule } from '@angular/core';

import { DigitsPipe } from './currency.pipe';

const PIPES = [
  DigitsPipe
];

@NgModule({
  declarations: [
    ...PIPES,
  ],
  exports: [
    DigitsPipe
  ]
})
export class CurrencyModule { }
