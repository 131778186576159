import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { Guid } from '@fitscovery/common/types';
import { ApplyMembershipResponse, CreateMemberInfo, ImportMembersResponse, MemberCreateResponse, Membership, MembershipRequest, MembershipSubscriptionResponse } from '../models';

import { Environment } from '@fitscovery/common/environments';
import { APP_ENVIRONMENT } from '../environment-injection';

@Injectable()
export class ApiMembershipService {

  constructor(
    @Inject(APP_ENVIRONMENT) private env: Environment,
    private http: HttpClient
  ) { }

  importMembers(formData: FormData): Observable<ImportMembersResponse> {
    return this.http.post<ImportMembersResponse>(`${this.env.azureFunctionsApi.memberships}/memberships/import-members`, formData);
  }

  createMember(member: CreateMemberInfo): Observable<MemberCreateResponse> {
    return this.http.post<MemberCreateResponse>(`${this.env.azureFunctionsApi.memberships}/memberships/create-user-and-membership`, member);
  }

  archiveMembership(member: Membership): Observable<Membership> {
    return this.http.put<Membership>(`${this.env.azureFunctionsApi.memberships}/memberships/${member.id}/archive`, member);
  }

  updateMembership(membership: Membership): Observable<Membership> {
    return this.http.put<Membership>(`${this.env.azureFunctionsApi.memberships}/memberships/${membership.id}`, membership);
  }

  applyDefaultMembership(request: MembershipRequest): Observable<ApplyMembershipResponse> {
    return this.http.post<ApplyMembershipResponse>(`${this.env.azureFunctionsApi.memberships}/memberships/apply`, request);
  }

  applyMembership({ email, userId, partnerId, subscriptionId }: MembershipRequest): Observable<ApplyMembershipResponse> {
    return this.http.post<ApplyMembershipResponse>(`${this.env.azureFunctionsApi.memberships}/memberships/apply-subscription`, {
      email, userId, partnerId, partnerMembershipSubscriptionId: subscriptionId
    });
  }

  stopRecurringMembership({ id, membershipId, partnerId, isRecurring }: Membership): Observable<Membership> {
    return this.http.put<Membership>(`${this.env.azureFunctionsApi.memberships}/memberships/${id}/update-recurring`, {
      id, membershipId, partnerId, isRecurring
    });
  }

  getMembershipSubscriptionById(id: Guid): Observable<MembershipSubscriptionResponse> {
    return this.http.get<MembershipSubscriptionResponse>(`${this.env.azureFunctionsApi.memberships}/membership/partner-membership-subscription/${id}`);
  }

  getMembershipWithHistory(partnerId: Guid, membershipId: Guid): Observable<Membership[]> {
    return this.http.get<Membership[]>(`${this.env.azureFunctionsApi.memberships}/memberships/${membershipId}/partners/${partnerId}`);
  }

  getMembershipByUserId(userId: Guid): Observable<Membership[]> {
    return <Observable<Membership[]>>this.http.get(`${this.env.azureFunctionsApi.memberships}/memberships/userId/${userId}`);
  }

}
