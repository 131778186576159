export namespace NotificationActions {
  export class InitializeNotifications {
    static readonly type = '[Notification] InitializeNotifications';
    constructor(public readonly userId: string, public readonly partnerId: string, public readonly application: string) {}
  }

  export class GetUnseenNotificationIds {
    static readonly type = '[Notification] GetUnseenNotificationIds';
    constructor(public readonly userId: string, public readonly partnerId: string, public readonly application: string) {}
  }

  export class LoadMoreNotifications {
    static readonly type = '[Notification] LoadMoreNotifications';
    constructor(public readonly userId: string, public readonly partnerId: string, public readonly application: string, public readonly reset?: boolean) {}
  }

  export class SeenNotification {
    static readonly type = '[Notification] SeenNotification';
    constructor(public readonly notificationId: string) {}
  }

  export class SeenAllNotification {
    static readonly type = '[Notification] SeenAllNotification';
    constructor(public readonly application: string, public readonly partnerId?: string) {}
  }
}
