import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { Guid } from '@fitscovery/common/types';
import { MembershipSubscription, MembershipSubscriptionResponse } from '../models';

import { Environment } from '@fitscovery/common/environments';
import { APP_ENVIRONMENT } from '../environment-injection';

@Injectable()
export class ApiSubscriptionService {

  constructor(
    @Inject(APP_ENVIRONMENT) private env: Environment,
    private http: HttpClient
  ) { }

  createMembershipSubscription(membershipSubscription: MembershipSubscription): Observable<MembershipSubscriptionResponse> {
    return this.http.post<MembershipSubscriptionResponse>(`${this.env.azureFunctionsApi.memberships}/partner-membership-subscriptions/create-partner-membership-subscriptions`, membershipSubscription);
  }

  updateMembershipSubscription(membershipSubscription: MembershipSubscription): Observable<MembershipSubscription> {
    return this.http.put<MembershipSubscription>(`${this.env.azureFunctionsApi.memberships}/partner-memberships-subscriptions/${membershipSubscription.id}`, membershipSubscription);
  }

  deleteMembershipSubscription(membershipSubscriptionId: string): Observable<null> {
    return this.http.delete<null>(`${this.env.azureFunctionsApi.memberships}/partner-membership-subscriptions/${membershipSubscriptionId}`);
  }
  
  createMembershipSubscriptionWithPMSEmailTemplate(membershipSubscription: MembershipSubscription): Observable<MembershipSubscription> {
    return this.http.post<MembershipSubscription>(`${this.env.azureFunctionsApi.memberships}/partner-membership-subscription/create-with-pms-email-template`, membershipSubscription);
  }
  updateMembershipSubscriptionWithPMSEmailTemplate(membershipSubscription: MembershipSubscription): Observable<MembershipSubscription> {
    return this.http.put<MembershipSubscription>(`${this.env.azureFunctionsApi.memberships}/partner-membership-subscriptions/with-pms-email-template/${membershipSubscription.id}`, membershipSubscription);
  }

  setMembershipSubscriptionOrder(id: Guid, partnerId: Guid, orderNumber: number): Observable<boolean> {
    return this.http.put<boolean>(`${this.env.azureFunctionsApi.memberships}/partner-memberships-subscriptions/${id}/order-number`, {
      id, partnerId, orderNumber
    });
  }

}
