import { AfterViewInit, ChangeDetectorRef, Component, OnInit } from '@angular/core';

import { WatchNetworkService } from '../../watch-network/watch-network.service';

@Component({
  selector: 'fitscovery-network-prompt-overlay',
  templateUrl: './network-prompt-overlay.component.html',
  styleUrls: ['./network-prompt-overlay.component.scss']
})
export class NetworkPromptOverlayComponent implements OnInit, AfterViewInit {

  onEnter: boolean;
  onExit: boolean;

  constructor(
    private cd: ChangeDetectorRef,
    public service: WatchNetworkService
  ) { }

  ngOnInit(): void {
    this.onEnter = true;
  }

  ngAfterViewInit() {
    this.cd.detectChanges();
  }

  refresh() {
    location.reload();
  }

  closeOverlay() {
    this.onEnter = false;
    this.onExit = true;
    setTimeout(() => {
      this.service.closeOverlaySource$.next(true);
    }, 750);
  }

}
