import { Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
//import { HttpClientService } from '@fitscovery/partner-web/shared/services/http';
import { ICreateGateway } from './create-gateway.interface';
import { BaseResource } from './data-gateway.service';
import { Serializer } from './serializer.interface';

export class CreateGateway<T extends BaseResource> implements ICreateGateway<T>  {

  constructor(//@Inject('httpClient') private httpClient: HttpClientService,
    @Inject('endpoint') private endpoint: string,
    private serializer: Serializer<T>,
  ) { }

  // COMMENTED THIS FOR NOW
  create(item: T): T | Observable<T> | Promise<T> {
    // return this.httpClient
    //   .post<T>(`${this.endpoint}`, this.serializer.toJson(item))
    //   .pipe(map(data => this.serializer.fromJson(data) as T));
    throw new Error('Method not implemented');
  }
}
