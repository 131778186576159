import { getActionTypeFromInstance } from '@ngxs/store';
import { RouterDataResolved, RouterNavigation } from '@ngxs/router-plugin';

import { AuthDataAccessModule, API_AUTH_STATE_TOKEN } from '@fitscovery/auth/data-access';
import { UserDataAccessModule, API_USER_STATE_TOKEN } from '@fitscovery/user/data-access';
import { AccountWebSharedStoreModule, ACCOUNT_STORE_STATE_TOKEN } from '@fitscovery/account-web/shared/store';
import { SharedStoreModule, SHARED_STORE_STATE_TOKEN } from '@fitscovery/shared/store';

import { environment } from '../environments/environment';

export const STATES = [
];

export const STORED_STATES = [
  API_AUTH_STATE_TOKEN,
  API_USER_STATE_TOKEN,
  ACCOUNT_STORE_STATE_TOKEN,
  SHARED_STORE_STATE_TOKEN
];

export const STATE_MODULES_IMPORT = [
  AuthDataAccessModule.forRoot({ app: 'account', environment }),
  UserDataAccessModule.forRoot({ app: 'account', environment }),
  AccountWebSharedStoreModule,
  SharedStoreModule
];

export const filterActionInNgxsLoggerPlugin = (action: any) => 
  getActionTypeFromInstance(action) !== RouterDataResolved.type
  && getActionTypeFromInstance(action) !== RouterNavigation.type
