<drawer>

  <div 
    routeObserver
    networkPrompt
    id="root" 
    fxLayout="row wrap" 
    fxLayoutAlign="center" 
    [ngStyle]="{ 
      'padding-top': (
        (authenticated$ | async) 
        && !((routerState$ | async)?.url! | includes: '/auth/confirm-action')
      ) ? '80px' : '0' 
    }">

    <navbar 
      *ngIf="
        (authenticated$ | async) 
        && !((routerState$ | async)?.url! | includes: '/auth/confirm-action')" 
        class="navbar">
    </navbar>

    <div 
      [fxFlex]="
        (authenticated$ | async) 
        && !((routerState$ | async)?.url! | includes: '/auth/confirm-action') ? '0 1 830px' : ''">
      
      <router-outlet></router-outlet>

    </div>

    <ui-footer 
      fxFlex="grow" 
      *ngIf="
        (authenticated$ | async) 
        && !((routerState$ | async)?.url! | includes: '/auth/confirm-action')">
    </ui-footer>

  </div>

</drawer>
