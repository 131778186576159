import { Injectable } from '@angular/core';
import { Action, NgxsOnInit, Selector, State, StateContext, StateToken } from '@ngxs/store';
import { Observable, tap } from 'rxjs';

import { ApiBookingsService } from '../services';
import { SnackbarService } from '@fitscovery/ui/snackbar';

import { BookingsStateModel, Booking, BookingResponse, PartnerBooking } from '../models';
import { BookingsActions } from '../actions';
import { errorHandler } from '@fitscovery/common/providers';

export const bookingsStateDefaults: BookingsStateModel = {
  partnerBooking: null,
  bookings: null,
  loaded: false
};

export const API_BOOKINGs_STATE_TOKEN = new StateToken<BookingsStateModel>('api_bookings');

@State<BookingsStateModel>({
  name: API_BOOKINGs_STATE_TOKEN,
  defaults: bookingsStateDefaults
})

@Injectable()
export class BookingsState implements NgxsOnInit {

  @Selector()
  static bookingState(state: BookingsStateModel): BookingsStateModel {
    return state;
  }

  @Selector()
  static partnerBooking(state: BookingsStateModel): PartnerBooking {
    return state.partnerBooking!;
  }

  @Selector()
  static bookings(state: BookingsStateModel): Booking[] {
    return state.bookings!;
  }

  constructor(
    private bookingsService: ApiBookingsService,
    private snackbar: SnackbarService
  ) { }

  ngxsOnInit(): void {
  }

  @Action(BookingsActions.GetPartnerBooking)
  getPartnerBooking(ctx: StateContext<BookingsStateModel>, action: BookingsActions.GetPartnerBooking) {
    return this.bookingsService.getPartnerBooking(action.partnerId).pipe(
      errorHandler(this.snackbar),
      tap((response: PartnerBooking[]) => ctx.patchState({ partnerBooking: response[0], loaded: true }))
    );
  }

  @Action(BookingsActions.GetUpcomingBookings)
  getUpcomingBookings(ctx: StateContext<BookingsStateModel>, action: BookingsActions.GetUpcomingBookings) {
    return this.bookingsService.getUpcomingBookings(action.partnerId).pipe(
      errorHandler(this.snackbar),
      tap((bookings: Booking[]) => ctx.patchState({ bookings, loaded: true }))
    );
  }

  @Action(BookingsActions.UpdatePartnerBooking)
  updatePartnerBooking(ctx: StateContext<BookingsStateModel>, action: BookingsActions.UpdatePartnerBooking): Observable<PartnerBooking | unknown> {
    return this.bookingsService.updatePartnerBooking(action.bookingDetails).pipe(
      errorHandler(this.snackbar),
      tap((partnerBooking: PartnerBooking) => ctx.patchState({ partnerBooking }))
    );
  }

  @Action(BookingsActions.UpdateMemberBooking)
  updateMemberBooking(ctx: StateContext<BookingsStateModel>, action: BookingsActions.UpdateMemberBooking) {
    const updateBookingStatus = (bookingResponse: BookingResponse): Booking[] => {
      return ctx.getState().bookings!.map((booking: Booking) => {
        if (booking.id === bookingResponse.data.id) {
          booking.status = bookingResponse.data.status;
          return booking;
        } return booking;
      });
    }
    return this.bookingsService.updateMemberBooking(action.bookingData).pipe(
      errorHandler(this.snackbar),
      tap((bookingResponse: BookingResponse) => ctx.patchState({ bookings: updateBookingStatus(bookingResponse) }))
    );
  }

}
