import { Pipe, PipeTransform } from '@angular/core';
import { AccessService } from '@fitscovery/my-web/data-access';



@Pipe({
  name: 'loaded'
})
export class LoadedPipe implements PipeTransform {

  constructor(
    private accessService: AccessService
  ) { }

  transform(value: any, option1?: any, key?: string): any {
    this.accessService.updateSource('checkArrObs', { key, value });
    value = !!!value ? 0 : value;
    const emptyArray = new Array(option1);
    const isLoaded = value.length > 0;
    const isLoadedValue = isLoaded ? 1 : 0;
    this.accessService.updateSource('asyncLoaded', isLoadedValue);
    return isLoaded ? value : emptyArray;
  }

}
