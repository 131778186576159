import { Injectable } from '@angular/core';
import { Action, NgxsOnInit, Selector, State, StateContext, StateToken } from '@ngxs/store';


import { MembershipAction } from '../actions';
import { Membership, UserMembershipsStateModel } from '../models';
import { SnackbarService } from '@fitscovery/ui/snackbar';
import { errorHandler } from '@fitscovery/common/providers';
import { map, mergeMap, of, tap, toArray } from 'rxjs';
import { ApiMembershipService } from '../services';
import { MembershipStatus } from '../enums';
import { ApiPartnerWebsitesService, GetPartnerWebsiteByIdResponse } from '@fitscovery/partner-websites/data-access';

export const userMembershipsStateDefaults: UserMembershipsStateModel = {
  memberships: [],
  membershipsWithPartnerLogo: [],
  loaded: false,
  isProcessing: false
};

export const USER_MEMBERSHIPS_STATE_TOKEN = new StateToken<UserMembershipsStateModel>('user_memberships');

@State<UserMembershipsStateModel>({
  name: USER_MEMBERSHIPS_STATE_TOKEN,
  defaults: userMembershipsStateDefaults
})

@Injectable()
export class UserMembershipState implements NgxsOnInit {

  @Selector()
	static memberships(state: UserMembershipsStateModel): Membership[] {
		return state.memberships;
	}

  @Selector()
	static membershipsWithPartnerLogo(state: UserMembershipsStateModel): Membership[] {
		return state.membershipsWithPartnerLogo;
	}

  @Selector()
	static hasLoaded(state: UserMembershipsStateModel): boolean {
		return state.loaded;
	}

  @Selector()
	static isProcessing(state: UserMembershipsStateModel): boolean {
		return state.isProcessing;
	}

  constructor(
    private snackbar: SnackbarService,
    private membershipService: ApiMembershipService,
    private partnerWebsitesService: ApiPartnerWebsitesService
  ) { }

  ngxsOnInit(): void {}

  @Action(MembershipAction.GetMembershipsByUserId)
  getMembershipByUserId(ctx: StateContext<UserMembershipsStateModel>, action: MembershipAction.GetMembershipsByUserId) {
    this.switchIsProcessingTo(true, ctx);
    return this.membershipService.getMembershipByUserId(action.userId).pipe(
      errorHandler(this.snackbar),
      tap((memberships: Membership[]) => {
        const activeMemberships = memberships.filter(e=> e.status === MembershipStatus.Active);
        if (activeMemberships.length == 0) this.switchIsProcessingTo(false, ctx);
        ctx.patchState({ memberships: activeMemberships, loaded: true });
      })
    );
  }

  @Action(MembershipAction.GetMembershipsWithPartnerLogo)
  getMembershipWithPartnerLogo(ctx: StateContext<UserMembershipsStateModel>, action: MembershipAction.GetMembershipsWithPartnerLogo) {
    of(action.memberships).pipe(
      mergeMap((memberships: Membership[]) => memberships),
      mergeMap((membership: Membership) => {
        const partnerId = <any>membership.partnerId;
        return this.partnerWebsitesService.getPartnerWebsiteById(partnerId).pipe(
          map((response: GetPartnerWebsiteByIdResponse) => {
            const logoUrl = response?.data?.partner_website?.logoUrl;
            membership.partner['logoUrl'] = logoUrl;
            ctx.patchState({loaded: true });
            this.switchIsProcessingTo(false, ctx);
            return membership;
          })
        )
      }),
      toArray()
    ).subscribe(
      (memberships: Membership[]) => ctx.patchState({ membershipsWithPartnerLogo: memberships, loaded: true })
    );
  }

  private switchIsProcessingTo(isProcessing: boolean, ctx: StateContext<UserMembershipsStateModel>): void {
    ctx.patchState({
      isProcessing: isProcessing
    });
  }

}
