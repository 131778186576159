import { Injectable } from '@angular/core';
import { Action, NgxsOnInit, Selector, State, StateContext, StateToken } from '@ngxs/store';
import { produce } from 'immer';

import { AccountStoreActions } from './store.action';
import { StateModel, EmailValue } from './store.model';

export const accountStoreDefaults: StateModel = {
  emailValue: { previous: '', current: '' },
  verificationSent: false
};

export const ACCOUNT_STORE_STATE_TOKEN = new StateToken<StateModel>('app');

@State<StateModel>({
  name: ACCOUNT_STORE_STATE_TOKEN,
  defaults: accountStoreDefaults
})

@Injectable()
export class AccountStoreState implements NgxsOnInit {

  @Selector()
  static emailValue(state: StateModel): EmailValue {
    return state.emailValue;
  }

  @Selector()
  static verificationSent(state: StateModel): boolean {
    return state.verificationSent;
  }

  constructor() { }

  ngxsOnInit(): void { }

  @Action(AccountStoreActions.EmailPreviousValue)
  emailPreviousValue(ctx: StateContext<StateModel>, action: AccountStoreActions.EmailPreviousValue): void {
    const state = ctx.getState();
    const value = produce( state, draft => { draft.emailValue.previous = action.previous });
    ctx.setState(value)
  }

  @Action(AccountStoreActions.EmailCurrentValue)
  emailCurrentValue(ctx: StateContext<StateModel>, action: AccountStoreActions.EmailCurrentValue): void {
    const state = ctx.getState();
    const value = produce( state, draft => { draft.emailValue.current = action.current });
    ctx.setState(value)
  }

  @Action(AccountStoreActions.VerificationSent)
  verificationSent(ctx: StateContext<StateModel>, action: AccountStoreActions.VerificationSent): void {
    ctx.patchState({ verificationSent: action.verificationSent });
  }

}
