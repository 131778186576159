import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Guid } from '@fitscovery/common/types';
//import { HttpClientService } from '@fitscovery/partner-web/shared/services/http';
import { IDataGateway } from './data-gateway.interface';
import { Serializer } from './serializer.interface';


export class DataGateway<T extends BaseResource> //implements IDataGateway<T>  
{

  constructor(//@Inject('httpClient') private httpClient: HttpClientService,
    @Inject('endpoint') private endpoint: string,
    private serializer: Serializer<T>,
  ) {}

  // create(item: T): Observable<T> {
  //   // return this.httpClient
  //   //   .post<T>(`${this.endpoint}`, this.serializer.toJson(item));
  //   throw new Error('Method not implemented');
  // }
  // delete(id: Guid | number): void {
  //   // this.httpClient.delete(`${this.endpoint}/${id}`);
  // }
  // get(id: string | Guid): Observable<T> {
  //   return this.httpClient
  //     .get(`${this.endpoint}/${id}`)
  //     .pipe(map((data: any) => this.serializer.fromJson(data) as T));
  // }

  // getAll(): Observable<T[]> {
  //   return this.httpClient
  //     .get(`${this.endpoint}}`)
  //     .pipe(map((data: any) => this.convertData(data)));
  // }

  // getAllByUserId(id: string | Guid): Observable<T[]> {
  //   return this.httpClient
  //     .get(`${this.endpoint}/userId/${id}`)
  //     .pipe(map((data: any) => this.convertData(data)));
  // }

  // update(item: T): Observable<T> {
  //   // return this.httpClient
  //   //   .put<T>(`${this.endpoint}/${item.id}`,
  //   //     this.serializer.toJson(item));
  //   throw new Error('Method not implemented');
  // }

  private convertData(result: any): T[] {
    // return result.data.map(item => this.serializer.fromJson(item));
    throw new Error('Method not implemented');
  }
}

export class BaseResource {
  id: Guid | string | number;
}
