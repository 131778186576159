import { Guid } from '@fitscovery/common/types';
import { GoalMeasurement } from './goal-measurement.model';

export class Goal {

  id: Guid | string;
  userId: Guid | string;
  activityName: string;
  activityTarget: number;
  currentProgress: number;
  progress: string;
  goalMeasurementId: number;
  isActive: boolean;
  status: number | string;
  goalMeasurement: GoalMeasurement;

  contructor() {
    this.id = '';
    // TODO: (Fred) Why is the default and lowest value
    this.activityTarget = 1;
  }
}
