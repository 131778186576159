import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxsModule } from '@ngxs/store';

import { Environment } from '@fitscovery/common/environments';
import { APP_ENVIRONMENT } from './environment-injection';
import { WorkoutState, ProgramState } from './stores';
import { ApiProgramService, ApiWorkoutService, ApiWorkoutUserInsightService, ApiProgramWorkoutUserInsightService } from './services';
import { SnackbarService } from '@fitscovery/ui/snackbar';


@NgModule({
  imports: [
    CommonModule,
    NgxsModule.forFeature([WorkoutState, ProgramState]),
  ],
  providers: [
    ApiProgramService,
    ApiWorkoutService,
    ApiWorkoutUserInsightService, 
    ApiProgramWorkoutUserInsightService,
    SnackbarService
  ]
})
export class WorkoutsDataAccessModule {

  static forRoot(environment: Environment): ModuleWithProviders<WorkoutsDataAccessModule> {
    return {
      ngModule: WorkoutsDataAccessModule,
      providers: [{ provide: APP_ENVIRONMENT, useValue: environment }]
    };
  }

}
