import { HttpErrorResponse } from '@angular/common/http';
import { OperatorFunction, catchError } from 'rxjs';

import { SnackbarService } from '@fitscovery/ui/snackbar';

export const errorHandler = (snackbar?: SnackbarService): OperatorFunction<any, any> => {
  return catchError((response: HttpErrorResponse) => {
    snackbar?.openSnack({ message: errorMessage(response), duration: 5000 });
    throw response;
  });
}

export const errorMessage = (response: HttpErrorResponse): string => {
  const defaultErrorMessage = 'Sorry. An unexpected error occurred. Kindly try again or if error persists, contact francis@fitscovery.com';
  return (
    response.error?.errors 
      ? response.error?.errors[0] 
      : defaultErrorMessage
  ) 
  || defaultErrorMessage;
}
