import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Action, NgxsOnInit, Selector, State, StateContext, StateToken } from '@ngxs/store';
import { catchError, tap } from 'rxjs';

import { ApiAuthService } from '../services';
import { AuthAction } from '../actions';
import { AuthStateModel } from '../models';
import { SnackbarService } from '@fitscovery/ui/snackbar';
import { errorHandler, errorMessage } from '@fitscovery/common/providers';

export const authStateDefaults: AuthStateModel = {
  checked: false,
  succeeded: false,
};

export const AUTH_STATE_TOKEN = new StateToken<AuthStateModel>('auth');

@State<AuthStateModel>({
	name: AUTH_STATE_TOKEN,
	defaults: authStateDefaults
})

@Injectable()
export class AuthState implements NgxsOnInit {

	@Selector()
	static checked(state: AuthStateModel): boolean {
		return state.checked;
	}
  
	@Selector()
	static succeeded(state: AuthStateModel): boolean {
		return state.succeeded;
	}

	constructor(
    private authService: ApiAuthService,
    private snackbar: SnackbarService
  ) { }

	ngxsOnInit(): void {
	}

  @Action(AuthAction.HealthCheck)
	healthCheck(ctx: StateContext<AuthStateModel>) {
    return this.authService.healthCheck().pipe(
      errorHandler(this.snackbar),
      tap(() => ctx.patchState({ checked: true, succeeded: true })),
      catchError((response: HttpErrorResponse) => {
        ctx.patchState({ checked: true, succeeded: false });
        throw new Error(errorMessage(response));
      })
    );
  }

}
