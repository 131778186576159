export namespace OnboardingStoreActions {

  export class UserPartnersState {
    static readonly type = '[Onboarding Web] UserPartnersState';
    constructor(public state: boolean) { }
  }

  export class HasVendorPartner {
    static readonly type = '[Onboarding Web] HasVendorPartner';
    constructor(public state: boolean) { }
  }

  export class NavigateToAdmin {
    static readonly type = '[Onboarding Web] NavigateToAdmin';
    constructor(public subdomain?: string) { }
  }

}
