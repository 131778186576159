import { Pipe, PipeTransform } from '@angular/core';
import { Gender } from '@fitscovery/common/types';

@Pipe({
  name: 'gender'
})
export class GenderPipe implements PipeTransform {

  transform(value: Gender): string {
    switch (value) {
      case Gender.NoneProvided:
        return 'None';
      case Gender.Male:
        return 'Male';
      case Gender.Female:
        return 'Female';
      case Gender.NotSpecified:
        return 'Others';
    }
  }

}
