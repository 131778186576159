import { Injectable, Inject } from '@angular/core';
import { Event, NavigationStart, Router } from '@angular/router';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators'
import { WINDOW } from '@ng-web-apis/common';

import { PartnerWebsiteState, PartnerWebsite } from '@fitscovery/partner-websites/data-access';
import { EventPath, EventData } from '../interfaces';

@Injectable()
export class RoutePathService {

  @Select(PartnerWebsiteState.partnerWebsite) partnerWebsite$: Observable<PartnerWebsite>;

  constructor(
    @Inject(WINDOW) private window: any,
    private router: Router,
  ) { }

  get eventData$(): Observable<EventData> {
    return this.path$.pipe(
      switchMap((eventPath: EventPath) => this.partnerWebsite$.pipe(
        map((partnerWebsite: PartnerWebsite) => ({ eventPath, partnerWebsite }))
      ))
    );
  }

  private get path$(): Observable<EventPath> {

    const baseURL = this.window.location.origin;

    return (this.router.events as any).pipe(
      filter((event: Event) => event instanceof NavigationStart),
      map((event: NavigationStart) => ({
        path: this.getPath(event),
        url: `${baseURL}${event.url}`
      }))
    );
  }

  private getPath(event: NavigationStart) {
    return event.url.split('?')[0].replace('/', '');
  }

}
