import { Pipe, PipeTransform } from '@angular/core';

import { TimePeriod } from '@fitscovery/memberships/data-access';

@Pipe({
  name: 'timePeriod'
})
export class TimePeriodPipe implements PipeTransform {

  transform(value: TimePeriod, numbersToAdd: number): string {

    if (numbersToAdd === -1) {
      switch (value) {
        case TimePeriod.Days:   return 'daily';
        case TimePeriod.Weeks:  return 'weekly';
        case TimePeriod.Months: return 'monthly';
        case TimePeriod.Years:  return 'yearly'; } }

    if (numbersToAdd === -2) {
      switch (value) {
        case TimePeriod.Days:   return 'day';
        case TimePeriod.Weeks:  return 'week';
        case TimePeriod.Months: return 'month';
        case TimePeriod.Years:  return 'year'; } }

    switch (value) {
      case TimePeriod.Days:   return `day${this.toPlural(numbersToAdd)}`;
      case TimePeriod.Weeks:  return `week${this.toPlural(numbersToAdd)}`;
      case TimePeriod.Months: return `month${this.toPlural(numbersToAdd)}`;
      case TimePeriod.Years:  return `year${this.toPlural(numbersToAdd)}`;
    }
  }

  private toPlural(numbersToAdd: number): string {
    return numbersToAdd > 1 ? 's' : '';
  }

}
