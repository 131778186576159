import { HttpErrorResponse } from '@angular/common/http';
import { OperatorFunction } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { SnackbarService } from '@fitscovery/ui/snackbar';

export class StateErrorHandler {

  constructor(
    public snackbar: SnackbarService
  ) { }

  catchResponse(): OperatorFunction<unknown, any> {
    return catchError((response: HttpErrorResponse) => {
      const message = this.errorMessage(response);
      this.snackbar.openSnack({ message, duration: 5000 });
      throw new Error(message);
    });
  }

  private errorMessage(response: HttpErrorResponse): string {
    const defaultErrorMessage = 'Sorry. An unexpected error occurred in Events. Kindly try again or if error persists, contact francis@fitscovery.com';
    return (
      response.error?.errors 
        ? response.error?.errors[0] 
        : defaultErrorMessage
    ) 
    || defaultErrorMessage;
  }

}
