import { NgModule } from '@angular/core';

import { DummyImagePipe } from './dummy-image.pipe';

const PIPES = [
  DummyImagePipe
];

@NgModule({
  declarations: [
    ...PIPES
  ],
  exports: [
    DummyImagePipe
  ]
})
export class DummyImagePipeModule { }
