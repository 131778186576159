import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { catchError, tap } from 'rxjs/operators';
import {
    GetProgramById, GetProgramsByPartnerId, GetProgramsByPartnerIdWithPaging,
    CreateProgram,
    DeleteProgram,
    UpdateProgram,
    GetProgramByIdAsAdmin,
    SetProgramOrder,
    AddProgramWorkoutUserInsight,
    GetProgramByIdWithSubdomain
} from '../actions';
import { Program } from '../models';
import { ApiProgramService, ApiProgramWorkoutUserInsightService } from '../services';
import { SnackbarService } from '@fitscovery/ui/snackbar';
import { Observable, throwError } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { append, patch } from '@ngxs/store/operators';

export interface ProgramStateModel {
    program: Program[];
    selectedProgram: Program | null;
    loaded: boolean;
}

export const programStateDefaults: ProgramStateModel = {
    program: [],
    selectedProgram: null,
    loaded: false
};

@State<ProgramStateModel>({
    name: 'program',
    defaults: programStateDefaults
})

@Injectable()
export class ProgramState {

    @Selector()
    static program(state: ProgramStateModel): Program[] {
        return state.program;
    }
    @Selector()
    static selectedProgram(state: ProgramStateModel): Program | null {
        return state.selectedProgram;
    }
    @Selector()
    static loaded(state: ProgramStateModel): boolean {
        return state.loaded;
    }

    constructor(
        private programService: ApiProgramService,
        private programWorkoutUserInsightService: ApiProgramWorkoutUserInsightService,
        private snackbar: SnackbarService
    ) { }

    @Action(GetProgramById)
    getProgram(ctx: StateContext<ProgramStateModel>, action: GetProgramById) {
      return this.programService.getProgramById(action.id).pipe(
        tap((response: any) => ctx.patchState({ selectedProgram: response }))
      );
    }

    @Action(GetProgramByIdAsAdmin)
    async getProgramByIdAsAdmin(ctx: StateContext<ProgramStateModel>, { id, partnerId }: GetProgramByIdAsAdmin) {
        const state = ctx.getState();
        const programResult = await this.programService.GetProgramByIdAsAdmin(id, partnerId).toPromise();

        ctx.setState({
            ...state,
            selectedProgram: programResult!,
            loaded: true
        });
    }

    @Action(GetProgramsByPartnerId)
    async getProgramsByPartnerId(ctx: StateContext<ProgramStateModel>, action: GetProgramsByPartnerId): Promise<void> {
        const programResult = await (await this.programService.getProgramsByPartnerId(action.partnerId)).toPromise();
        ctx.patchState({
            program: programResult,
            loaded: true
        });
    }

    @Action(GetProgramByIdWithSubdomain)
    getProgramByIdWithSubdomain(ctx: StateContext<ProgramStateModel>, action: GetProgramByIdWithSubdomain) {
      return this.programService.getProgramByIdAndSubdomain(action.partnerId, action.subdomain).pipe(
        tap((response: any) => ctx.patchState({ selectedProgram: response }))
      );
    }

    @Action(GetProgramsByPartnerIdWithPaging)
    getProgramsByPartnerIdWithPaging(
        ctx: StateContext<ProgramStateModel>,
        action: GetProgramsByPartnerIdWithPaging
    ): Observable<Program[]> {
        return this.programService.getProgramsByPartnerIdWithPaging(
            action.partnerId,
            action.pageNumber,
            action.pageSize
        ).pipe(
            tap((programs: Program[]) => {
                action.pageNumber <= 1
                    ? ctx.patchState({
                        program: programs,
                        loaded: true,
                    })
                    : ctx.setState(patch({ program: append(programs) }));
            }),
            catchError((response: HttpErrorResponse) => {
                const errors = response.error.errors;
                this.snackbar.openSnack({ message: errors[0] })
                return throwError(errors[0]);
            })
        );
    }

    @Action(CreateProgram)
    async createProgram(ctx: StateContext<ProgramStateModel>, action: CreateProgram) {
        const state: ProgramStateModel = ctx.getState();
        const result = await this.programService.CreateProgram(action.program).toPromise();

        ctx.setState({
            ...state,
            program: [result, ...state.program],
        });
    }

    @Action(DeleteProgram)
    async deleteProgram(ctx: StateContext<ProgramStateModel>, action: DeleteProgram) {
        const state: ProgramStateModel = ctx.getState();
        await this.programService.DeleteProgram(action.partnerId, action.programId).toPromise();
        const filteredArray = state.program.filter(item => item.id !== action.programId);
        ctx.setState({
            ...state,
            program: filteredArray,
            loaded: true
        });
        return;
    }

    @Action(UpdateProgram)
    async updateProgram(ctx: StateContext<ProgramStateModel>, action: UpdateProgram) {
        const state: ProgramStateModel = ctx.getState();
        const result = await this.programService.UpdateProgram(action.program).toPromise();
        ctx.setState({
            ...state,
            program: [result, ...state.program],
        });
    }

    @Action(SetProgramOrder)
    async SetProgramOrder(ctx: StateContext<ProgramStateModel>, action: SetProgramOrder) {
        await this.programService.setProgramOrder(action.id, action.partnerId, action.orderNumber)?.toPromise();
    }

    @Action(AddProgramWorkoutUserInsight)
    async AddProgramWorkoutUserInsight(ctx: StateContext<ProgramStateModel>, action: AddProgramWorkoutUserInsight) {
        await this.programWorkoutUserInsightService.addInsights(action.programSectionWorkoutId, action.insightType)?.toPromise();
        ctx.patchState({ loaded: true });
    }
}
