import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatDividerModule } from '@angular/material/divider';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { LazyLoadImageModule } from 'ng-lazyload-image';

import { DummyImagePipeModule } from '@fitscovery/common/pipes';

import { UserDetailsComponent } from './user-details/user-details.component';

const MODULES = [
  CommonModule,
  TranslateModule,
  FlexLayoutModule,
  MatDividerModule,
  MatButtonModule,
  MatIconModule,
  LazyLoadImageModule
];

const LIBRARIES = [
  DummyImagePipeModule
];

const COMPONENTS = [
  UserDetailsComponent
];

@NgModule({
  imports: [
    ...MODULES,
    ...LIBRARIES
  ],
  declarations: [
    ...COMPONENTS
  ],
  exports: [
    UserDetailsComponent
  ]
})
export class AccountWebSharedUserDetailsModule {}
