import { User } from "@fitscovery/user/data-access";
import { CreateUser } from "../models";

export namespace AuthAzureAction {

  export class SignUp {
    static readonly type = '[Auth API] SignUp';
    constructor(public user: CreateUser) { }
  }

  export class UpdateUser {
    static readonly type = '[Auth API] UpdateUser';
    constructor(public user: User) { }
  }

  export class ConfirmEmailVerification {
    static readonly type = '[Auth API] ConfirmEmailVerification';
    constructor(public code: string) { }
  }

  export class SendEmailVerification {
    static readonly type = '[Auth API] SendEmailVerification';
    constructor() { }
  }

}
