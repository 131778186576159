import { Guid } from '@fitscovery/common/types';
import { MembershipRequest } from '../models';

export namespace PartnerMembershipAction {
  
  export class GetMembershipsByPartnerIdAndMemberId {
    static readonly type = '[Memberships API] GetMembershipsByPartnerIdAndMemberId';
    constructor(public partnerId: Guid, public memberId: Guid) { }
  }

  export class GetActiveMembershipSubscriptionsByPartnerId {
    static readonly type = '[Memberships API] GetActiveMembershipSubscriptionsByPartnerId';
    constructor(public partnerId: Guid) { }
  }

  export class ApplyDefaultMembership {
    static readonly type = '[Memberships API] ApplyDefaultMembership';
    constructor(public request: MembershipRequest) { }
  }

  export class ApplyMembership {
    static readonly type = '[Memberships API] ApplyMembership';
    constructor(public request: MembershipRequest) { }
  }

}
