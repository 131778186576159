import { Injectable } from '@angular/core';
import { Action, NgxsOnInit, Selector, State, StateContext, StateToken } from '@ngxs/store';
import { tap } from 'rxjs/operators';

import { ApiPartnerReportService } from '../services';
import { SnackbarService } from '@fitscovery/ui/snackbar';
import { PartnerReportAction } from '../actions';
import { PartnerReportResponse, PartnerReportStateModel, VendorReport } from '../models';
import { errorHandler } from '@fitscovery/common/providers';

export const partnerReportDefaults: PartnerReportStateModel = {
  data: null,
  loaded: false
};

export const PARTNER_REPORT_STATE_TOKEN = new StateToken<PartnerReportStateModel>('partnerReports');

@State<PartnerReportStateModel>({
	name: PARTNER_REPORT_STATE_TOKEN,
	defaults: partnerReportDefaults
})

@Injectable()
export class PartnerReportState implements NgxsOnInit {

	@Selector()
	static vendorReports(state: PartnerReportStateModel): VendorReport[] | null {
		return state.data;
	}

	constructor(
    private api: ApiPartnerReportService,
    private snackbar: SnackbarService
  ) { }

	ngxsOnInit(): void {
	}

  @Action(PartnerReportAction.GetPartnerReports)
  getPartnerReports(
    ctx: StateContext<PartnerReportStateModel>,
    { partnerId, filter, startTime, endTime }: PartnerReportAction.GetPartnerReports
  ) {
    return this.api.getPartnerReports(partnerId, filter, startTime!, endTime!).pipe(
      errorHandler(this.snackbar),
      tap((response: PartnerReportResponse) => ctx.patchState({ data: response.data.vendorReports, loaded: true }))
    );
  }

}
