import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Observable, of, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { GetMemberOnlyWorkout, GetWorkout, GetWorkoutsBySubdomain, GetWorkoutsBySubdomainWithPaging, AddWorkoutUserInsight, CreateWorkout, UpdateWorkout, GetMemberOnlyWorkoutById, GetWorkoutByIdAsAdmin, UpdateExercise, CreateExercise, DeleteWorkout, DeleteExercise, SetWorkoutOrder, GetMemberOnlyWorkoutByIdWithSubdomain, GetWorkoutByIdWithSubdomain } from '../actions';
import { Workout } from '../models';
import { ApiWorkoutService, ApiWorkoutUserInsightService } from '../services';
import { SnackbarService } from '@fitscovery/ui/snackbar';
import { patch, append } from '@ngxs/store/operators';

export interface WorkoutStateModel {
    workout: Workout[];
    selectedWorkout: Workout | null;
    loaded: boolean;
    pageNumber: number;
    pageSize: number;
}

export const workoutStateDefaults: WorkoutStateModel = {
    workout: [],
    selectedWorkout: null,
    loaded: false,
    pageNumber: 1,
    pageSize: 10
};

@State<WorkoutStateModel>({
    name: 'workout',
    defaults: workoutStateDefaults
})
@Injectable()
export class WorkoutState {

    @Selector()
    static workout(state: WorkoutStateModel): Workout[] {
        return state.workout;
    }
    @Selector()
    static selectedWorkout(state: WorkoutStateModel): Workout | null {
        return state.selectedWorkout;
    }
    @Selector()
    static loaded(state: WorkoutStateModel): boolean {
        return state.loaded;
    }

    @Selector()
    static pageNumber(state: WorkoutStateModel): [number, number] {
        return [state.pageNumber, state.pageSize];
    }

    @Selector()
    static pageSize(state: WorkoutStateModel): number {
        return state.pageSize;
    }

    constructor(
      private workoutService: ApiWorkoutService,
      private workoutuserInsightService: ApiWorkoutUserInsightService,
      private snackbar: SnackbarService
    ) { }

    @Action(GetWorkout)
    getWorkout(ctx: StateContext<WorkoutStateModel>, action: GetWorkout) {
      return this.workoutService.GetWorkout(action.id).pipe(
        tap((response) => ctx.patchState({ selectedWorkout: response }))
      )
    }

    @Action(GetWorkoutByIdWithSubdomain)
    GetWorkoutByIdWithSubdomain(ctx: StateContext<WorkoutStateModel>, action: GetWorkoutByIdWithSubdomain) {
      return this.workoutService.getWorkoutByIdWithSubdomain(action.id, action.subdomain).pipe(
        tap((response) => ctx.patchState({ selectedWorkout: response }))
      )
    }

    @Action(GetMemberOnlyWorkout)
    async getMemberOnlyWorkout(ctx: StateContext<WorkoutStateModel>, { id, token }: GetMemberOnlyWorkout) {
        const state = ctx.getState();
        const workoutResult = await this.workoutService.GetMemberOnlyWorkout(id, token).toPromise();

        ctx.setState({
            ...state,
            selectedWorkout: workoutResult!,
            loaded: true
        });
    }

    @Action(GetMemberOnlyWorkoutByIdWithSubdomain)
    getMemberOnlyWorkoutByIdWithSubdomain(ctx: StateContext<WorkoutStateModel>, action: GetMemberOnlyWorkoutByIdWithSubdomain) {
      return this.workoutService.getMemberOnlyWorkoutByIdWithSubdomain(action.id, action.subdomain).pipe(
        tap((response) => ctx.patchState({ selectedWorkout: response }))
      );
    }

    @Action(GetWorkoutByIdAsAdmin)
    async getWorkoutByIdAsAdmin(ctx: StateContext<WorkoutStateModel>, { id, partnerId }: GetWorkoutByIdAsAdmin) {
        const state = ctx.getState();
        const workoutResult = await this.workoutService.GetWorkoutByIdAsAdmin(id, partnerId).toPromise();

        ctx.setState({
            ...state,
            selectedWorkout: workoutResult!,
            loaded: true
        });
    }

    @Action(GetMemberOnlyWorkoutById)
    async getMemberOnlyWorkoutById(ctx: StateContext<WorkoutStateModel>, { id }: GetMemberOnlyWorkout) {
        const state = ctx.getState();
        const workoutResult = await this.workoutService.GetMemberOnlyWorkoutById(id).toPromise();

        ctx.setState({
            ...state,
            selectedWorkout: workoutResult!,
            loaded: true
        });
    }

    @Action(GetWorkoutsBySubdomain)
    async getWorkoutBySbdomain(ctx: StateContext<WorkoutStateModel>, action: GetWorkoutsBySubdomain) {
        const workoutResult = await this.workoutService.GetWorkoutsBySubdomain(action.subdomain, action.isAdmin || true).toPromise();
        ctx.patchState({
            workout: workoutResult,
            selectedWorkout: null,
            loaded: true
        });
    }

    @Action(CreateWorkout)
    async createWorkout(ctx: StateContext<WorkoutStateModel>, action: CreateWorkout) {
        const state: WorkoutStateModel = ctx.getState();
        const workoutResult = await this.workoutService.CreateWorkout(action.workout).toPromise();
        ctx.patchState({
            workout: [workoutResult, ...state.workout],
            loaded: true
        });
    }

    @Action(DeleteWorkout)
    async deleteWorkout(ctx: StateContext<WorkoutStateModel>, action: DeleteWorkout) {
        const state: WorkoutStateModel = ctx.getState();
        await this.workoutService.DeleteWorkout(action.partnerId, action.workoutId).toPromise();
        const filteredArray = state.workout.filter(item => item.id !== action.workoutId);
        ctx.setState({
            ...state,
            workout: filteredArray,
            loaded: true
        });
        return;
    }

    @Action(UpdateWorkout)
    async UpdateWorkout(ctx: StateContext<WorkoutStateModel>, action: UpdateWorkout) {
        const state: WorkoutStateModel = ctx.getState();
        const workoutResult = await this.workoutService.UpdateWorkout(action.workout).toPromise();

        const workoutList = [...state.workout];
        const workoutIndex = workoutList.findIndex(item => item.id === action.workout.id);
        workoutList[workoutIndex] = workoutResult;

        ctx.setState({
            ...state,
            workout: workoutList,
        });
    }

    @Action(GetWorkoutsBySubdomainWithPaging)
    getWorkoutsBySubdomainWithPaging(
      ctx: StateContext<WorkoutStateModel>,
      action: GetWorkoutsBySubdomainWithPaging
    ): Observable<Workout[]> {
      return this.workoutService.getWorkoutsBySubdomainWithPaging(
        action.subdomain,
        action.pageNumber,
        workoutStateDefaults.pageSize,
        action.isAdmin
      ).pipe(
        tap((workouts: Workout[]) => {
          action.pageNumber <= 1
            ? ctx.patchState({
                workout: workouts,
                loaded: true,
                pageNumber: action.pageNumber
              })
            : ctx.setState(
                patch({ workout: append(workouts) })
              );
        }),
        catchError((response: HttpErrorResponse) => {
          const errors = response.error.errors;
          this.snackbar.openSnack({ message: errors[0] })
          return throwError(errors[0]);
        })
      );
    }

    @Action(AddWorkoutUserInsight)
    async AddWorkoutUserInsight(ctx: StateContext<WorkoutStateModel>, action: AddWorkoutUserInsight) {
        await this.workoutuserInsightService.addInsights(action.workoutId, action.insightType)?.toPromise();
        ctx.patchState({ loaded: true });
    }

    @Action(UpdateExercise)
    async UpdateExercise(ctx: StateContext<WorkoutStateModel>, action: UpdateExercise) {
        await this.workoutService.UpdateExercise(action.exercise).toPromise();
        ctx.patchState({
            loaded: true
        });
    }

    @Action(CreateExercise)
    async CreateExercise(ctx: StateContext<WorkoutStateModel>, action: CreateExercise) {
        await this.workoutService.CreateExercise(action.exercise).toPromise();
        ctx.patchState({
            loaded: true
        });
    }

    @Action(DeleteExercise)
    async deleteExercise(ctx: StateContext<WorkoutStateModel>, action: DeleteExercise) {
        const workoutResult = await this.workoutService.DeleteExercise(action.exercise).toPromise();
        ctx.patchState({
            loaded: true
        });
    }

    @Action(SetWorkoutOrder)
    async SetWorkoutOrder(ctx: StateContext<WorkoutStateModel>, action: SetWorkoutOrder) {
        await this.workoutService.setWorkoutOrder(action.id, action.partnerId, action.orderNumber)?.toPromise();
    }

}
