import { ModuleWithProviders, NgModule } from '@angular/core';
import { AccessService } from './services';
import { Environment } from '@fitscovery/common/environments';
import { APP_ENVIRONMENT } from './environment-injection';
import { MyWebPostState, MyWebUserState } from './stores';
import { NgxsModule } from '@ngxs/store';
import { ApiPostService } from '@fitscovery/posts/data-access';
import { ApiUserService } from '@fitscovery/user/data-access';

const STATES = [
  MyWebUserState,
  MyWebPostState
];
const PROVIDERS = [
  AccessService,
  ApiPostService,
  ApiUserService
]

@NgModule({
  imports: [
    NgxsModule.forFeature(STATES)
  ],
  providers: [
    ...PROVIDERS
  ]
})
export class MyWebDataAccessModule {
  static forRoot(environment: Environment): ModuleWithProviders<MyWebDataAccessModule> {
    return {
      ngModule: MyWebDataAccessModule,
      providers: [{ provide: APP_ENVIRONMENT, useValue: environment }]
    };
  }
}
