import { PartnerWebsite } from '@fitscovery/partner-websites/data-access';
import { Metadata, EventData, EventPath } from '../interfaces';

export class ApplicationProvider {

  private _eventData: EventData;
  private _eventPath: EventPath;
  private _partnerWebsite: PartnerWebsite;

  constructor(
    eventData: EventData
  ) {
    this._eventData = eventData;
    this._eventPath = eventData.eventPath;
    this._partnerWebsite = eventData.partnerWebsite;
  }

  public get eventData(): EventData {
    return this._eventData;
  } 

  protected get path(): string {
    return this._eventPath.path;
  }

  protected get defaultMetadataConfig(): Metadata {
    
    const url = this._eventPath.url;
    const partnerWebsite = this._partnerWebsite;

    return {
      url,
      site: partnerWebsite?.subdomain || '',
      imageUrl: partnerWebsite?.bannerImage || ''
    };
  }

}
