import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, fromEvent, merge, Observable, of } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { WINDOW } from '@ng-web-apis/common';

@Injectable()
export class WatchNetworkService {

  private _isOnline: boolean;

  isOnlineSource$: BehaviorSubject<boolean>;
  isOnline$: Observable<boolean>;

  closeOverlaySource$: BehaviorSubject<any>;
  closeOverlay$: Observable<boolean>;

  constructor(
    @Inject(WINDOW) private window: any
  ) {
    this.isOnlineSource$ = new BehaviorSubject(navigator.onLine);
    this.isOnline$ = this.isOnlineSource$.asObservable();
    this.closeOverlaySource$ = new BehaviorSubject(false);
    this.closeOverlay$ = this.closeOverlaySource$.asObservable().pipe( filter(e => !!e) );
  }

  get isOnline(): boolean { return this._isOnline; }
  set isOnline(isOnline: boolean) { this._isOnline = isOnline; }

  checkNetworkStatus() {

    const merge$ = merge(
      of(null),
      fromEvent(this.window, 'online'),
      fromEvent(this.window, 'offline')
    );

    merge$.pipe(map(() => navigator.onLine)).subscribe((networkStatus: boolean) => {
      this.isOnline = networkStatus;
      this.isOnlineSource$.next(networkStatus);
    });
  }

}
