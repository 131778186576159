import * as moment from 'moment-timezone';

import { Timezone } from './timezone.enum';
import { MONTHS } from './timezone.provider';

export const toUTC = (dateTime: Date): Date => {
  return moment.utc(dateTime).format('YYYY-MM-DDThh:mm:ss') as any;
}

export const toUTC00 = (dateTime: Date): Date => {
  return moment.utc(dateTime).format('YYYY-MM-DDT00:00:00') as any;
}

export const convertDateToUTC = (dateTime: Date) => {
  return moment.utc(dateTime).format('YYYY-MM-DDThh:mm:ss');
}

export const convertDateToUTCToday = (): string => {
  const dateTime = new Date();
  dateTime.setDate(dateTime.getDate() - 1);
  return moment.utc(dateTime).format('YYYY-MM-DDT16:00:00') + '.000Z';
}

export const convertToLocalTime = (dateTime: string | Date, format?: string, timezone?: Timezone): Date | string => {
  const selectedTimezone = (timezone === null || timezone === undefined) ? getTimezone() : timezone;
  const convertedDate: moment.Moment | Date | string = moment.utc(dateTime).tz(selectedTimezone);

  return format === null ? convertedDate.toDate() : convertedDate.format(format);
}

const getTimezone = (): string => {
  return Intl.DateTimeFormat().resolvedOptions().timeZone;
}

export class TimezoneProvider {

  public static getTimezone(): string {
    return Intl.DateTimeFormat().resolvedOptions().timeZone;
  }

  static convertDateToUTC(dateTime: Date): string {
    return moment.utc(dateTime).format('YYYY-MM-DDThh:mm:ss');
  }

  static convertToLocalTime(dateTime: string | Date, format?: string, timezone?: Timezone): Date | string {
    const selectedTimezone = (timezone === null || timezone === undefined) ? this.getTimezone() : timezone;
    const convertedDate: moment.Moment | Date | string = moment.utc(dateTime).tz(selectedTimezone);

    return format === null ? convertedDate.toDate() : convertedDate.format(format);
  }

  static convertToLocalTimeKeepLocalTime(dateTime: string | Date, format?: string, timezone?: Timezone): Date | string {
    const selectedTimezone = (timezone === null || timezone === undefined) ? this.getTimezone() : timezone;
    const convertedDate: moment.Moment | Date | string = moment.utc(dateTime).tz(selectedTimezone, true);

    return format === null ? convertedDate.toDate() : convertedDate.format(format);
  }

  static convertToLocalDateTime(date: moment.MomentInput, format?: string): string {

    const months = MONTHS;;
    const dateTime = moment.utc(date).tz(this.getTimezone());

    const mobileMonths = (dateTime.month() < 10) ? '0' + dateTime.month() : dateTime.month();
    const mobileDate = (dateTime.date() < 10) ? '0' + dateTime.date() : dateTime.date();
    const hours = (dateTime.hours() < 12) ? dateTime.hours() : dateTime.hours() - 12;
    const minutes = (dateTime.minutes() < 10) ? '0' + dateTime.minutes() : dateTime.minutes();
    const meridian = (dateTime.hours() < 12) ? 'AM' : 'PM';

    const web = months[ dateTime.month() ] + ' ' + dateTime.date() + ', ' + dateTime.years() + ' ' + hours + ':' + minutes + ' ' + meridian;
    const mobile = mobileMonths + '/' + mobileDate + '/' + dateTime.years() + ' ' + hours + ':' + minutes + ' ' + meridian;
    const formattedDateTime = (format === 'web') ? web : mobile;

    return formattedDateTime;
  }

}
