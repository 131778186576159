import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { firstValueFrom } from 'rxjs';

import { getStatusWithState } from '@fitscovery/memberships/common';
import { MembershipsState } from '../store';
import { MembershipAction } from '../actions';
import { MembershipStatusState } from '../models';
import { PartnerWebsiteState } from '@fitscovery/partner-websites/data-access';

@Injectable()
export class MemberPaginationService {

  private _limit: number;

  constructor(
    private store: Store
  ) {
    this._limit = 13;
  }

  load(state: MembershipStatusState, page?: number): Promise<any> {

    const partnerId = this.store.selectSnapshot(PartnerWebsiteState.partnerId);
    let pageInState = this.store.selectSnapshot(MembershipsState.memberships(state))?.page!;

    if (page) { pageInState = page; }

    return firstValueFrom(this.store.dispatch(new MembershipAction.GetMembersByStatus(
      partnerId, state, {
        offset: this.computeOffset(pageInState),
        limit: this._limit,
        page: ((page: number) => ++page)(pageInState),
        value: getStatusWithState(state)
      }
    )));
  }

  private computeOffset(page: number): number {
    ++page;
    const pageSize = 13;
    return page === 1 ? 0 : (pageSize * (page - 1)) + 1
  }

}
