import { Injectable } from '@angular/core';
import { Action, NgxsOnInit, State, StateContext, StateToken } from '@ngxs/store';

import { ApiAuthAzureService } from '../services';
import { AuthAzureAction } from '../actions';
import { AuthAzureStateModel } from '../models';
import { SnackbarService } from '@fitscovery/ui/snackbar';
import { errorHandler } from '@fitscovery/common/providers';

export const AUTH_AZURE_STATE_TOKEN = new StateToken<AuthAzureStateModel>('authAzure');

@State<AuthAzureStateModel>({
	name: AUTH_AZURE_STATE_TOKEN
})

@Injectable()
export class AuthAzureState implements NgxsOnInit {

	constructor(
    private authAzureService: ApiAuthAzureService,
    private snackbar: SnackbarService
  ) { }

	ngxsOnInit(): void {
	}

  @Action(AuthAzureAction.SignUp)
	signUp(_: StateContext<AuthAzureStateModel>, action: AuthAzureAction.SignUp) {
    return this.authAzureService.signUp(action.user).pipe(
      errorHandler(this.snackbar)
    );
  }

  @Action(AuthAzureAction.UpdateUser)
	updateUser(_: StateContext<AuthAzureStateModel>, action: AuthAzureAction.UpdateUser) {
    return this.authAzureService.updateUser(action.user).pipe(
      errorHandler(this.snackbar)
    );
  }

  @Action(AuthAzureAction.ConfirmEmailVerification)
	confirmEmailVerification(_: StateContext<AuthAzureStateModel>, action: AuthAzureAction.ConfirmEmailVerification) {
    return this.authAzureService.confirmEmailVerification(action.code).pipe(
      errorHandler(this.snackbar)
    );
  }

  @Action(AuthAzureAction.SendEmailVerification)
	sendEmailVerification(_: StateContext<AuthAzureStateModel>) {
    return this.authAzureService.sendEmailVerification().pipe(
      errorHandler(this.snackbar)
    );
  }

}
