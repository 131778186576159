import { getActionTypeFromInstance, NGXS_PLUGINS } from "@ngxs/store";

import { ApiAuthAction } from "@fitscovery/auth/data-access";
import { userStateDefaults } from "@fitscovery/user/data-access";
import { eventStateDefaults } from "@fitscovery/events/data-access";
import { apiMembershipsStateDefaults } from "@fitscovery/memberships/data-access";
import { bookingsStateDefaults } from "@fitscovery/bookings/data-access";
import { apiReportsStateDefaults } from "@fitscovery/reports/data-access";
import { notificationStateDefaults } from "@fitscovery/notification/data-access";
import { workoutStateDefaults, programStateDefaults } from "@fitscovery/workouts/data-access";

function adminWebLogoutPlugin(state: any, action: any, next: any) {

  if (getActionTypeFromInstance(action) === ApiAuthAction.SignOut.type) {
    state = {
      api_user: userStateDefaults,
      api_partners: null,
      api_partner_websites: null,
      api_events: eventStateDefaults,
      api_memberships: apiMembershipsStateDefaults,
      api_reports: apiReportsStateDefaults,
      api_notification: notificationStateDefaults,
      api_bookings: bookingsStateDefaults,

      workout: workoutStateDefaults,
      program: programStateDefaults,

      domain_bookings: state.domain_bookings,
      route: state.route,
      shared: state.shared,
      state: state.state
    };
  }

  return next(state, action);
}

export const AdminWebLogoutPluginProvider = {
  provide: NGXS_PLUGINS,
  useValue: adminWebLogoutPlugin,
  multi: true
};
