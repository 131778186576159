export class CustomError extends Error {

	constructor(httpErrorCode: number, data: any, errorMessage: string) {
		super();
		this.data = data;
		this.message = errorMessage;
		this.httpErrorCode = httpErrorCode;
	}
	data: any;
	httpErrorCode: number;
}