import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Guid } from '@fitscovery/common/types';
//import { HttpClientService } from '@fitscovery/partner-web/shared/services/http';
import { BaseResource } from './data-gateway.service';
import { IReadGateway } from './read-gateway.interface';
import { Serializer } from './serializer.interface';

export class ReadGateway<T extends BaseResource> //implements IReadGateway<T>  
{

  constructor(
    //@Inject('httpClient') private httpClient: HttpClientService,
    @Inject('endpoint') private endpoint: string,
    private serializer: Serializer<T>
  ) {
  }

  // get(id: string | Guid): Observable<T> {
  //   return this.httpClient
  //     .get(`${this.endpoint}/${id}`)
  //     .pipe(map((data: any) => this.serializer.fromJson(data) as T));
  // }

  // getAll(): Observable<T[]> {
  //   return this.httpClient
  //     .get(`${this.endpoint}`)
  //     .pipe(map((data: any) => this.convertData(data)));
  // }

  // getAllByUserId(id: string | Guid): Observable<T[]> {
  //   return this.httpClient
  //     .get(`${this.endpoint}/userId/{id}`)
  //     .pipe(map((data: any) => this.convertData(data)));
  // }

  private convertData(result: any): T[] {
    return result.data.map((item: any) => this.serializer.fromJson(item));
  }
}
