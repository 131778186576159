<div id="navbar" fxLayout="row" fxLayoutAlign="start center" [ngClass]="{ 'shadow': isShadow }">

  <div class="logo-wrapper web" fxLayout="row" fxLayoutAlign="start center">
    <img src="assets/images/fitscovery-black.png" alt="fitscovery-logo" height="45" routerLink="/">
  </div>
  <div class="logo-wrapper mobile">
    <img src="https://fitscovery-ng-cdn.s3.ap-southeast-1.amazonaws.com/admin-ng/fitscovery-icon.png" alt="fitscovery-icon" height="30" routerLink="/">
  </div>

  <div fxLayout="row" fxLayoutAlign="center">
    <img
      class="user"
      alt="partner-image"
      defaultImage="https://fitscovery-ng-cdn.s3.ap-southeast-1.amazonaws.com/default-image.png" 
      errorImage="https://fitscovery-ng-cdn.s3.ap-southeast-1.amazonaws.com/default-image.png" 
      [lazyLoad]="(service.user$ | async)?.imageUrl!"
      (click)="toggleOverlay()">
    <div
      class="cdk-origin"
      cdkOverlayOrigin
      #userDetails="cdkOverlayOrigin">
    </div>
  </div>

  <div fxLayout="row" fxLayoutAlign="center center" [matMenuTriggerFor]="menu" matRipple class="select-language">
    <mat-icon>language</mat-icon>
    <span>{{ (service.language$ | async)| uppercase }}</span>
  </div>

</div>

<ng-template
  cdkConnectedOverlay
  (overlayOutsideClick)="closeOverlay()"
  (overlayKeydown)="closeOverlay()"
  [cdkConnectedOverlayOrigin]="userDetails"
  [cdkConnectedOverlayOpen]="isUserPartnerDetailsOpened">

  <fitscovery-user-details (closeOverlay)="closeOverlay()" (logout)="service.logout()" [overlayState]="overlayState" [user]="service.user$ | async"></fitscovery-user-details>

</ng-template>

<mat-menu #menu="matMenu" xPosition="before" class="select-language">
  <button mat-menu-item (click)="service.changeLanguage('en')">{{ 'navbar.english' | translate }}</button>
  <button mat-menu-item (click)="service.changeLanguage('kr')">{{ 'navbar.korean' | translate }}</button>
</mat-menu>
