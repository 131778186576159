import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { FlexLayoutModule } from '@angular/flex-layout';
import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { AccountWebFeatureRootModule } from '@fitscovery/account-web/feature-root';
import { AccountWebLogoutPluginProvider } from '@fitscovery/utils/ngxs-plugins/logout';
import { AuthTokenInterceptorModule } from '@fitscovery/auth/token-interceptor';
import { UtilsLoggerModule, SEGMENT_DEFAULT_CONFIG } from '@fitscovery/utils/logger';

import { environment as env } from '../environments/environment';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

const MODULES = [
  BrowserModule,
  BrowserAnimationsModule,
  HttpClientModule,
  FlexLayoutModule,
  AngularFireAuthModule,
  MatSnackBarModule
];

const LIBRARIES = [
  AccountWebFeatureRootModule
];

const PROVIDERS = [
  AccountWebLogoutPluginProvider
];

@NgModule({
  imports: [
    ...MODULES,
    ...LIBRARIES,
    AngularFireModule.initializeApp(env.firebase),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [ HttpClient ]
      }
    }),
    AuthTokenInterceptorModule.forRoot(env),
    UtilsLoggerModule.forRoot(env, {
      apiKey: env.apiKeys.segmentKey,
      debug: !env.production, 
      loadOnInitialization: true,
      segmentHost: SEGMENT_DEFAULT_CONFIG.segmentHost,
      segmentUri: SEGMENT_DEFAULT_CONFIG.segmentUri
    })
  ],
  providers: [
    ...PROVIDERS
  ]
})
export class AppLibrariesModule { }
