import { NgModule } from '@angular/core';

import { SearchMembersPipe } from './search-members.pipe';

const PIPES = [
  SearchMembersPipe
];

@NgModule({
  declarations: [
    ...PIPES
  ],
  exports: [
    SearchMembersPipe
  ]
})
export class SearchMembersModule { }
