//import { Membership } from "@fitscovery/memberships/data-access";

export class GetMembershipByUserId {
  static readonly type = '[Subscriptions] Get Membership By User Id';

  constructor(public userId: string, public token: string) { }
}

export class GetMembershipActiveVendorSubscriptionByMembershipsAndUserId {
  static readonly type = '[Subscriptions] Get Membership Active Vendor Subscription By Memberships Ans User Id';

  constructor(/*public userId: string, public memberships: Membership[], public token: string*/) { }
}

export class ResetSubscriptionsToDefault {
  static readonly type = '[Subscriptions] Reset Subscriptions To Default';

  constructor() { }
}
