import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { CreatePartnerWebsitePrivateRequest, CreatePartnerWebsitePrivateResponse, DeletePartnerWebsitePrivateResponse } from '../interfaces';
import { Guid } from '@fitscovery/common/types';
import { Environment } from '@fitscovery/common/environments';
import { APP_ENVIRONMENT } from '../environment-injection';

@Injectable()
export class ApiPrivatePartnerWebsitesService {

  constructor(
    @Inject(APP_ENVIRONMENT) private env: Environment,
    private http: HttpClient
  ) { }

  createPartnerWebsitePrivate(partner: CreatePartnerWebsitePrivateRequest): Observable<CreatePartnerWebsitePrivateResponse> {
    partner.partnerId = (partner.partnerId.toString().toUpperCase() as unknown) as Guid;
    return this.http.post<CreatePartnerWebsitePrivateResponse>(`${this.env.nodeApi.partnerWebsites}/private/partner-websites`, partner, {
      headers: { 'api-private-key': '42909530-e87b-424d-9cb5-5acf36a4cd8a' }
    });
  }

  deletePartnerWebsitePrivate(partnerId: Guid): Observable<DeletePartnerWebsitePrivateResponse> {
    return this.http.get<DeletePartnerWebsitePrivateResponse>(`${this.env.nodeApi.partnerWebsites}/private/partner-websites/${partnerId}`);
  }

}
