import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { Guid } from '@fitscovery/common/types';
import { EmailTemplate } from '../models';

import { Environment } from '@fitscovery/common/environments';
import { APP_ENVIRONMENT } from '../environment-injection';

@Injectable()
export class ApiEmailTemplateService {
    
  constructor(
    @Inject(APP_ENVIRONMENT) private env: Environment,
    private http: HttpClient
  ) { }
 
  // needs discussion
  // createEmailTemplate(emailTemplate: EmailTemplate): Observable<> {
  //   return <Observable<>>this.http.post(`${this.env.azureFunctionsApi.memberships}/email-templates`, emailTemplate);
  // }

  // needs discussion
  // updateEmailTemplate(emailTemplate: EmailTemplate): Observable<EmailTemplate> {
  //   return <Observable<EmailTemplate>>this.http.put(`${this.env.azureFunctionsApi.memberships}/email-templates/${emailTemplate.id}`, emailTemplate);
  // }

  // needs discussion
  // deleteEmailTemplate(emailTemplateId: Guid): Observable<> {
  //   return this.http.delete(`${this.env.azureFunctionsApi.memberships}/email-templates/${emailTemplateId}`);
  // }

  // needs discussion
  // getEmailTemplateById(emailTemplateId: Guid): Observable<EmailTemplate[]> {
  //   return <Observable<EmailTemplate[]>>this.http.get(`${this.env.azureFunctionsApi.memberships}/email-templates/${emailTemplateId}`);
  // }

  getEmailTemplatesByPartnerId(partnerId: Guid): Observable<EmailTemplate[]> {
    return <Observable<EmailTemplate[]>>this.http.get(`${this.env.azureFunctionsApi.memberships}/email-templates/partners/${partnerId}`);
  }

}
