export enum PartnerLevel {
  MainOrganizer,
  CoOrganizer,
  Sponsor
}

export enum ShowOnlineUrlLinkIn {
  None = 0,
  Page,
  Email,
  AllWays = 10,
}

export enum EventFrequency {
  OneTime,
  Daily,
  Weekly,
  Monthly
}

export enum AttendanceStatus {
	None = -1,
	NotGoing = 0,
	Going = 1,
	Interested = 2,
	WaitListed = 3,
	Cancelled = 4,
	Invited = 5,
	ForPayment = 6,
	Unpaid = 7,
  EventCancelled,
  EventDeleted,
}

export enum UpdateRecurringEventType{
  ThisEvent,
  ThisAndFollowingEvents,
  AllEvents
}
