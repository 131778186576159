import { Injectable } from "@angular/core";
import { errorHandler } from "@fitscovery/common/providers";
import { SnackbarService } from "@fitscovery/ui/snackbar";
import { Action, NgxsOnInit, Selector, State, StateContext, StateToken } from "@ngxs/store";
import { Observable, tap } from "rxjs";
import { BookingsActions } from "../actions";
import { Booking, UserBookingStateModel } from "../models";
import { ApiBookingsService } from "../services";

export const userBookingStateDefaults: UserBookingStateModel = {
    bookings: [],
    loaded: false
  };
  
  export const USER_BOOKING_STATE_TOKEN = new StateToken<UserBookingStateModel>('user_bookings');

  @State<UserBookingStateModel>({
    name: USER_BOOKING_STATE_TOKEN,
    defaults: userBookingStateDefaults
  })

@Injectable()
export class UserBookingState implements NgxsOnInit {

  @Selector()
  static userBookingState(state: UserBookingStateModel): UserBookingStateModel {
    return state;
  }

  @Selector()
  static bookings(state: UserBookingStateModel): Booking[] {
    return state.bookings!;
  }

  constructor(
    private bookingService: ApiBookingsService,
    private snackbar: SnackbarService
  ) { }

  ngxsOnInit(): void {
  }

  @Action(BookingsActions.GetBookingsByUserId)
  getBookingsByUserId(ctx: StateContext<UserBookingStateModel>, action: BookingsActions.GetBookingsByUserId): Observable<Booking[]> {
    return this.bookingService.getBookingsByUserId(action.userId).pipe(
      tap((bookings: Booking[]) => {
        ctx.patchState({ bookings, loaded: true });
    }),
      errorHandler(this.snackbar)
    );
  }



}