import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import * as qs from 'qs';

import { GetBackofficePartnerWebsiteByIdResponse, GetBackofficePartnerWebsiteCountResponse, GetBackofficePartnerWebsitesResponse, PartnerCountRequest, PartnerWebsitesRequest } from '../interfaces';
import { Guid } from '@fitscovery/common/types';
import { Environment } from '@fitscovery/common/environments';
import { APP_ENVIRONMENT } from '../environment-injection';

@Injectable()
export class ApiBackofficePartnerWebsitesService {

  constructor(
    @Inject(APP_ENVIRONMENT) private env: Environment,
    private http: HttpClient
  ) { }

  getBackofficePartnerWebsitesCount(request: PartnerCountRequest): Observable<GetBackofficePartnerWebsiteCountResponse> {
    return this.http.get<GetBackofficePartnerWebsiteCountResponse>(`${this.env.nodeApi.partnerWebsites}/back-office/partner-websites/count`, {
      params: qs.parse(qs.stringify(request, { skipNulls: true })) as Record<string, any>
    });
  }

  getBackofficePartnerWebsites(request: PartnerWebsitesRequest): Observable<GetBackofficePartnerWebsitesResponse> {
    const params = qs.parse(qs.stringify(request, { skipNulls: true })) as Record<string, any>;
    return this.http.get<GetBackofficePartnerWebsitesResponse>(`${this.env.nodeApi.partnerWebsites}/back-office/partner-websites`, {
      params: { ...params, orderBy: 'createdAt|desc' }
    });
  }

  getBackofficePartnerWebsiteById(partnerId: Guid): Observable<GetBackofficePartnerWebsiteByIdResponse> {
    return this.http.get<GetBackofficePartnerWebsiteByIdResponse>(`${this.env.nodeApi.partnerWebsites}/back-office/partner-websites/${partnerId}`);
  }

}
