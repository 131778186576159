export const USER_AUTH_EVENTS = {
  FORGOT_PASSWORD: 'User Forgot Password',
  LOGIN: 'User Logged In',
  LOGOUT: 'User Logged Out',
  SIGN_UP: 'User Signed Up',
  VERIFY_EMAIL: 'User Requested Email Verification'
};

export const AUTH_METHOD = {
  GOOGLE: 'Google',
  FACEBOOK: 'Facebook',
  EMAIL_PASSWORD: 'Email and Password'
};