import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DrawerComponent } from './drawer/drawer.component';
import { MatSidenavModule } from '@angular/material/sidenav';

const MODULES = [
  CommonModule,
  MatSidenavModule
];

const COMPONENTS = [
  DrawerComponent
];

@NgModule({
  imports: [
    ...MODULES
  ],
  declarations: [
    ...COMPONENTS
  ],
  exports: [
    DrawerComponent
  ],
})
export class AccountWebSharedDrawerModule {}
