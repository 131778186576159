import { Metadata, EventData } from '../interfaces';
import { ApplicationProvider } from './application.provider';

export class OnboardingWebProvider extends ApplicationProvider {

  constructor(eventData: EventData) {
    super(eventData);
  }

  get routeMetadata(): Metadata {
    return {
      ...this.customMetadata,
      ...this.defaultMetadataConfig
    }
  }

  private get customMetadata(): Metadata | undefined {

    const path = this.defaultMetadataConfig.url!;

    if (path.includes('auth/login')) return {
      title: 'Login or sign up | Fitscovery',
      description: '',
    }
    if (path.includes('auth/forgot-password')) return {
      title: 'Forgot password | Fitscovery',
      description: '',
    }
    if (path.includes('auth/register')) return {
      title: 'Create Account | Fitscovery',
      description: '',
    }
    if (path.includes('vendor/dashboard')) return {
      title: 'Onboarding Dashboard | Fitscovery',
      description: '',
    }
    if (path.includes('vendor/create')) return {
      title: 'Create your website | Fitscovery',
      description: '',
    }
    if (path.includes('vendor')) return {
      title: 'Onboarding Dashboard | Fitscovery',
      description: '',
    }
    else return {
      title: 'Onboarding Portal | Fitscovery',
      description: '',
    }

  }

}
