<div id="network-prompt-overlay" fxLayout="row" fxLayoutAlign="center center" class="animate__animated animate__faster" [ngClass]="{ 'animate__fadeInLeft': onEnter, 'animate__fadeOutLeft': onExit }">

  <ng-container *ngIf="!service.isOnline">
    <mat-icon>wifi_off</mat-icon><span>Your are currently offline.</span><span class="refresh" (click)="refresh()">Refresh</span> <button mat-icon-button (click)="closeOverlay()"><mat-icon>close</mat-icon></button>
  </ng-container>

  <ng-container *ngIf="service.isOnline">
    <mat-icon class="isOnline">wifi</mat-icon><span>Your internet connection was restored.</span><button mat-icon-button (click)="closeOverlay()"><mat-icon>close</mat-icon></button>
  </ng-container>

</div>
