import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FlexLayoutModule } from '@angular/flex-layout';

import { SharedRouteObserverModule } from '@fitscovery/shared/route-observer';
import { SharedNetworkConnectionObserverModule } from '@fitscovery/shared/network-connection-observer';
import { UiFootersModule } from '@fitscovery/ui/footers';
import { AccountWebSharedNavbarModule } from '@fitscovery/account-web/shared/navbar';
import { AccountWebSharedDrawerModule } from '@fitscovery/account-web/shared/drawer';
import { IncludesModule } from '@fitscovery/account-web/utils/common';

import { FeatureRootComponent } from './feature-root/feature-root.component';
import { environment } from 'apps/account-web/src/environments/environment';

const MODULES = [
  CommonModule,
  RouterModule,
  FlexLayoutModule
];

const LIBRARIES = [
  SharedNetworkConnectionObserverModule,
  UiFootersModule,
  AccountWebSharedNavbarModule,
  AccountWebSharedDrawerModule,
  IncludesModule
];

const COMPONENTS = [
  FeatureRootComponent
];

@NgModule({
  imports: [
    ...MODULES,
    ...LIBRARIES,
    SharedRouteObserverModule.forRoot({ app: 'account', environment })
  ],
  declarations: [
    ...COMPONENTS
  ]
})
export class AccountWebFeatureRootModule {}
