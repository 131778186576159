import { Injectable } from '@angular/core';
import {
  MatSnackBar,
  MatSnackBarConfig,
  MatSnackBarHorizontalPosition,
  MatSnackBarRef,
  MatSnackBarVerticalPosition,
  TextOnlySnackBar
} from '@angular/material/snack-bar';

interface ISnack {
  message: string;
  duration?: number;
  class?: string[],
  horizontal?: MatSnackBarHorizontalPosition,
  vertical?: MatSnackBarVerticalPosition
}

@Injectable({
  providedIn: 'root'
})
export class SnackbarService {

  constructor(
    private snack: MatSnackBar
  ) { }
  
  openSnack(option: ISnack): MatSnackBarRef<TextOnlySnackBar> {
    return this.snack.open(option.message, 'X', this.config(option));
  }

  destroyAll() {
    this.snack.dismiss();
  }
  
  private config(option: ISnack) {
    const config = new MatSnackBarConfig();
    config.duration = option.duration || 3000;
    config.panelClass = option.class || 'snackbar-custom';
    config.verticalPosition = option.vertical || 'bottom';
    config.horizontalPosition = option.horizontal || 'center';
    return config;
  }

}
