import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { FlexLayoutModule } from '@angular/flex-layout';

import { FooterComponent } from './footer/footer.component';

const MODULES = [
  CommonModule,
  TranslateModule,
  FlexLayoutModule
];

@NgModule({
  declarations: [
    FooterComponent
  ],
  imports: [
    ...MODULES
  ],
  exports: [
    FooterComponent
  ],
})
export class UiFootersModule {}
