import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { CreateUser, SignUpResponse, SendEmailVerificationResponse, UserResponse } from '../models';

import { User } from '@fitscovery/user/data-access';
import { APP_TOKEN, Token } from '../environment-injection';

@Injectable()
export class ApiAuthAzureService {

  constructor(
    @Inject(APP_TOKEN) private appToken: Token,
    private http: HttpClient
  ) { }

  signUp(user: CreateUser): Observable<SignUpResponse> {
    return this.http.post<SignUpResponse>(`${this.appToken.environment.azureFunctionsApi.auth}/auth/sign-up`, user);
  }

  updateUser(user: User): Observable<UserResponse> {
    return this.http.put<UserResponse>(`${this.appToken.environment.azureFunctionsApi.auth}/auth/account`, user);
  }

  confirmEmailVerification(verificationCode: string): Observable<any> {
		return this.http.post<any>(`${this.appToken.environment.azureFunctionsApi.auth}/auth/account/confirm-email-verification`, {
      verificationCode
    });
	}
    
  sendEmailVerification() : Observable<SendEmailVerificationResponse> {
    return this.http.post<SendEmailVerificationResponse>(`${this.appToken.environment.azureFunctionsApi.auth}/auth/account/send-email-verification`, null);
	}

}
