import { Guid } from '@fitscovery/common/types';
import { TimeFrame } from '../enums';

export namespace PartnerReportAction {
  
  export class GetPartnerReports {
    static readonly type = '[Reports API] GetPartnerReports';
    constructor(public partnerId: Guid, public filter: TimeFrame, public startTime?: Date, public endTime?: Date) { }
  }

}
