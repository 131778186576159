import { Inject, Injectable } from '@angular/core';
import { Environment } from '@fitscovery/common/environments';
import { SnackbarService } from '@fitscovery/ui/snackbar';
import { Action, State, StateContext, StateToken } from '@ngxs/store';
import { firstValueFrom } from 'rxjs';
import { WebPushNotificationActions } from '../actions';
import { APP_ENVIRONMENT } from '../environment-injection';
import { StateErrorHandler } from '../helpers';
import { WebPushNotificationApiService } from '../services';

export interface WebPushNotificationStateModel {
  subscription: PushSubscription | null;
  subscriptionString: string;
}

export const webPushNotificationStateDefaults: WebPushNotificationStateModel = {
  subscription: null,
  subscriptionString: '',
};

export const WEB_PUSH_NOTIFICATION_STATE_TOKEN = new StateToken<WebPushNotificationStateModel>('web_push_notification');

@State<WebPushNotificationStateModel>({
  name: WEB_PUSH_NOTIFICATION_STATE_TOKEN,
  defaults: webPushNotificationStateDefaults,
})
@Injectable()
export class WebPushNotificationState extends StateErrorHandler {

  constructor(@Inject(APP_ENVIRONMENT) private env: Environment, public override snackbar: SnackbarService, private readonly webPushNotificationApiService: WebPushNotificationApiService) {
    super(snackbar)
  }

  @Action(WebPushNotificationActions.InitializeWebPushNotification)
  async initializeWebPushNotification(
    ctx: StateContext<WebPushNotificationStateModel>,
    action: WebPushNotificationActions.InitializeWebPushNotification
  ): Promise<void> {
    if ('serviceWorker' in navigator) {
      try {
        const swReg = await navigator.serviceWorker.register('/sw.js', { scope: '/' });

        await swReg.update();

        const subscription = await swReg.pushManager.subscribe({
          userVisibleOnly: true,
          applicationServerKey: urlB64ToUint8Array(this.env.apiKeys.pushKey as string),
        });

        const subscriptionString = JSON.stringify(subscription)

        ctx.patchState({
          subscription,
          subscriptionString,
        })

        if (action.userId) {
          await firstValueFrom(this.webPushNotificationApiService.loginToWebPushApi(subscriptionString, action.userId?.toString() || '', action.application))
        } else {
          await firstValueFrom(this.webPushNotificationApiService.logoutToWebPushApi(subscriptionString))
        }
      } catch (err) {
        console.error('initializeWebPushNotification err', err);
      }
    } else {
      console.log('serviceWorker not supported');
    }
  }
}

function urlB64ToUint8Array(base64String: string) {
  const padding = '='.repeat((4 - (base64String.length % 4)) % 4);
  const base64 = (base64String + padding).replace(/\-/g, '+').replace(/_/g, '/');

  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
}
