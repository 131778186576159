
import { GoalMeasurement } from '@fitscovery/my-web/shared/models';
import { IReadGateway, ReadGateway, Serializer } from '../infrastructure/data';
//import { HttpClientService } from '@fitscovery/partner-web/shared/services/http';
// import { environment } from 'src/environments/environment';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class HttpGoalMeasurementService //extends ReadGateway<GoalMeasurement> implements IGoalMeasurementService 
{
  items: GoalMeasurement[];

  constructor(//httpClient: HttpClientService
    ) {
    // super(httpClient, `${environment.azureFunctionsApi.goals}/measurements/`, new GoalMeasurementSerializer());
    //super(httpClient, `test`, new GoalMeasurementSerializer());
    // (this.getAll() as Observable<GoalMeasurement[]>).subscribe((result: GoalMeasurement[]) => {
    //   this.items = result;
    // });
  }

  getGoalMeasurementDisplay(id: number): string {
    return this.items.filter(g => g.id === id)[0].displayName;
  }
}

export interface IGoalMeasurementService extends IReadGateway<GoalMeasurement> { }

export class GoalMeasurementSerializer implements Serializer<GoalMeasurement> {
  fromJson(json: any): GoalMeasurement {
    const item = new GoalMeasurement();
    item.id = json.id;
    item.displayName = json.displayName;
    return item;
  }

  toJson(item: GoalMeasurement): any {
    return {
      id: item.id,
      displayName: item.displayName
    };
  }
}
