import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot, CanActivate, CanActivateChild } from '@angular/router';
import { Store } from '@ngxs/store';

import { SessionState } from '@fitscovery/auth/data-access';

@Injectable()
export class NotAuthenticatedGuard implements CanActivate, CanActivateChild {

  constructor(
    private router: Router,
    private store: Store,
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {

    const authenticated = this.store.selectSnapshot(SessionState.authenticated);

    if (authenticated) {
      this.router.navigate([ 'user', 'details' ], {
        queryParams: route.queryParams,
        queryParamsHandling: 'merge'
      });
    }

    return !authenticated;
  }

  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    return this.canActivate(childRoute, state);
  }
  
}
