import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { OverlayModule } from '@angular/cdk/overlay';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';

import { WatchNetworkService } from './watch-network/watch-network.service';
import { NetworkPromptDirective } from './directives/network-prompt.directive';
import { NetworkPromptOverlayComponent } from './ui/network-prompt-overlay/network-prompt-overlay.component';

const MODULES = [
  CommonModule,
  FlexLayoutModule,
  OverlayModule,
  MatButtonModule,
  MatIconModule
];

const PROVIDERS = [
  WatchNetworkService
];

const COMPONENTS = [
  NetworkPromptOverlayComponent
];

const DIRECTIVES = [
  NetworkPromptDirective,
];

@NgModule({
  imports: [
    ...MODULES
  ],
  providers: [
    ...PROVIDERS
  ],
  declarations: [
    ...COMPONENTS,
    ...DIRECTIVES
  ],
  entryComponents: [
    NetworkPromptOverlayComponent
  ],
  exports: [
    NetworkPromptDirective,
    NetworkPromptOverlayComponent
  ]
})
export class SharedNetworkConnectionObserverModule {}
