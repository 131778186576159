import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { Guid } from '@fitscovery/common/types';

import { Environment } from '@fitscovery/common/environments';
import { APP_ENVIRONMENT } from '../environment-injection';
import { PMSEmailTemplate } from '../models';

@Injectable()
export class ApiPMSEmailTemplateService {
    
  constructor(
    @Inject(APP_ENVIRONMENT) private env: Environment,
    private http: HttpClient
  ) { }
  
  
  // needs discussion
  // createPMSEmailTemplate(pmsEmailTemplate: PMSEmailTemplate) : Observable<>{
  //   return <Observable<>>this.http.post(`${this.env.azureFunctionsApi.memberships}/partner-membership-subscription-email-template`, pmsEmailTemplate);
  // }

  // needs discussion
  // updatePMSEmailTemplate(pmsEmailTemplate: PMSEmailTemplate): Observable<PMSEmailTemplate> {
  //   return <Observable<PMSEmailTemplate>>this.http.put(`${this.env.azureFunctionsApi.memberships}/partner-membership-subscription-email-template/partner-membership-subscription/${pmsEmailTemplate.partnerMembershipSubscriptionId}`, pmsEmailTemplate);
  // }

  // needs discussion
  // deletePMSEmailTemplate(pmsEmailTemplate: PMSEmailTemplate): Observable<> {
  //   return this.http.delete(`${this.env.azureFunctionsApi.memberships}/partner-membership-subscription-email-template/${pmsEmailTemplate.partnerMembershipSubscriptionId}`);
  // }

  getPMSEmailTemplatesByPartnerId(partnerId: Guid): Observable<PMSEmailTemplate[]>{
    return <Observable<PMSEmailTemplate[]>>this.http.get(`${this.env.azureFunctionsApi.memberships}/partner-membership-subscription-email-template/partners/${partnerId}`);
  }

  // needs discussion
  // getPMSEMailTemplate(partnerMembershipSubscriptionId: Guid): Observable<PMSEmailTemplate[]>{
  //   return <Observable<PMSEmailTemplate[]>>this.http.get(`${this.env.azureFunctionsApi.memberships}/partner-membership-subscription-email-template/partner-membership-subscription/${partnerMembershipSubscriptionId}`);
  // }

}
