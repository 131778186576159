export class PostPoints {
    currentWeekPostsPoints: number;
    lastWeekPostsPoints: number;
    currentMonthPostsPoints: number;
    lastMonthPostsPoints: number;
    workoutPostsCount: number;
    nutritionPostsCount: number;
  
    constructor(){
      this.currentWeekPostsPoints = 0;
      this.lastWeekPostsPoints = 0;
      this.currentMonthPostsPoints = 0;
      this.lastMonthPostsPoints = 0;
      this.workoutPostsCount = 0;
      this.nutritionPostsCount  = 0;
    }
  }