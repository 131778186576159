import { Guid } from '@fitscovery/common/types';
import { FitnessConsumerProfile } from '@fitscovery/user/data-access';

export namespace UserActions {

  export class GetCurrentUser {
    static readonly type = '[User API] GetCurrentUser';
    constructor() { }
  }

  export class GetUserPartnersByUserId {
    static readonly type = '[User API] GetUserPartnersByUserId';
    constructor(public id: Guid) { }
  }
  
  export class GetFitnessConsumerProfileByUserId {
    static readonly type = '[User API] GetFitnessConsumerProfileByUserId';
    constructor(public id: Guid) { }
  }
  
  export class UpdateFitnessConsumerProfile {
    static readonly type = '[User API] UpdateFitnessConsumerProfile';
    constructor(public profile: FitnessConsumerProfile) { }
  }
  
  export class UpdateUserPartnerLogoUrlById {
    static readonly type = '[User API] UpdateUserPartnerLogoUrlById';
    constructor(public id: Guid, public logoUrl: string) {}
  }
  
  export class GetFitnessConsumerProfileByUsername {
    static readonly type = '[User API] GetFitnessConsumerProfileByUsername';
    constructor(public username: string) { }
  }

}
