import { Injectable } from '@angular/core';
import { SnackbarService } from '@fitscovery/ui/snackbar';
import * as signalR from '@microsoft/signalr';
import { Action, Selector, State, StateContext, StateToken } from '@ngxs/store';
import { catchError, from, map, Observable, tap } from 'rxjs';
import { NotificationActions } from '../actions';
import { StateErrorHandler } from '../helpers';
import { Notification } from '../models';
import { NotificationService, SignalRService } from '../services';

export interface ApiNotificationStateModel {
  unseenNotificationIds: string[];
  gettingNotifications: boolean;
  notifications: Notification[];
  hasMoreNotification: boolean;
  signalRHubConnection: signalR.HubConnection | null;
}

export const notificationStateDefaults: ApiNotificationStateModel = {
  unseenNotificationIds: [],
  gettingNotifications: false,
  notifications: [],
  hasMoreNotification: true,
  signalRHubConnection: null,
};

export const API_NOTIFICATIONS_STATE_TOKEN = new StateToken<ApiNotificationStateModel>('api_notification');
@State<ApiNotificationStateModel>({
  name: API_NOTIFICATIONS_STATE_TOKEN,
  defaults: notificationStateDefaults,
})
@Injectable()
export class NotificationState extends StateErrorHandler {
  @Selector()
  static unseenNotificationIds(state: ApiNotificationStateModel): string[] {
    return state.unseenNotificationIds;
  }

  @Selector()
  static gettingNotifications(state: ApiNotificationStateModel): boolean {
    return state.gettingNotifications;
  }

  @Selector()
  static notifications(state: ApiNotificationStateModel): Notification[] {
    return state.notifications;
  }

  @Selector()
  static hasMoreNotification(state: ApiNotificationStateModel): boolean {
    return state.hasMoreNotification;
  }

  constructor(private notificationService: NotificationService, private signalRService: SignalRService, public override snackbar: SnackbarService) {
    super(snackbar)
  }

  private newNotificationHandler(ctx: StateContext<ApiNotificationStateModel>, newNotification: Notification) {
    const unseenNotificationIds = <string[]>JSON.parse(JSON.stringify(ctx.getState().unseenNotificationIds))
    const notifications = <Notification[]>JSON.parse(JSON.stringify(ctx.getState().notifications))

    notifications.unshift(newNotification);

    if (!newNotification.seenAt) {
      unseenNotificationIds.push(newNotification.id);
    }

    ctx.patchState({
      notifications: [...notifications],
      unseenNotificationIds: [...unseenNotificationIds],
    });
  }

  private seenNotificationHandler(ctx: StateContext<ApiNotificationStateModel>, seenNotification: Notification) {
    const unseenNotificationIds = <string[]>JSON.parse(JSON.stringify(ctx.getState().unseenNotificationIds))
    const notifications = <Notification[]>JSON.parse(JSON.stringify(ctx.getState().notifications))
    const unseenNotificationIdIndex = unseenNotificationIds.indexOf(seenNotification.id);

    if (unseenNotificationIdIndex > -1) {
      unseenNotificationIds.splice(unseenNotificationIdIndex, 1);
      ctx.patchState({
        unseenNotificationIds: [...unseenNotificationIds],
      });
    }

    const notificationIndex = notifications.findIndex(({ id }) => id === seenNotification.id);

    if (notificationIndex > -1) {
      notifications.splice(notificationIndex, 1, seenNotification);
      ctx.patchState({
        notifications: [...notifications],
      });
    }
  }

  private seenAllNotificationHandler(ctx: StateContext<ApiNotificationStateModel>) {
    const notifications = <Notification[]>JSON.parse(JSON.stringify(ctx.getState().notifications))

    notifications.forEach((notification) => {
      notification.seenAt = notification.seenAt || '0001-01-01 00:00:00';
    });

    ctx.patchState({
      notifications: [...notifications],
      unseenNotificationIds: [],
    });
  }

  @Action(NotificationActions.InitializeNotifications)
  initializeNotifications(
    ctx: StateContext<ApiNotificationStateModel>,
    action: NotificationActions.InitializeNotifications
  ): Observable<signalR.HubConnection | null> {
    const { signalRHubConnection } = ctx.getState();

    if (signalRHubConnection && signalRHubConnection?.stop) {
      signalRHubConnection.stop();
    }

    if (!action.userId) {
      return from(Promise.resolve(null));
    }

    return from(this.signalRService.initialize(action.userId?.toUpperCase())).pipe(
      tap((signalRHubConnection) => {
        signalRHubConnection.on('newNotification', (newNotification: Notification) => {
          if (newNotification.application === action.application && (!action.partnerId || action.partnerId.toUpperCase() === newNotification.partnerId?.toUpperCase())) {
            this.newNotificationHandler(ctx, newNotification);
          }
        });

        signalRHubConnection.on('seenNotification', (seenNotification: Notification) => {
          if (seenNotification.application === action.application && (!action.partnerId || action.partnerId.toUpperCase() === seenNotification.partnerId?.toUpperCase())) {
            this.seenNotificationHandler(ctx, seenNotification);
          }
        });

        signalRHubConnection.on('seenAllNotification', (application: string, partnerId: string) => {
          if (application === action.application && (!action.partnerId || action.partnerId.toUpperCase() === partnerId?.toUpperCase())) {
            this.seenAllNotificationHandler(ctx);
          }
        });

        ctx.patchState({
          signalRHubConnection,
        });
      }),
      this.catchResponse(),
      catchError((err) => {
        ctx.patchState({
          signalRHubConnection: null,
        });

        throw err;
      })
    );
  }

  @Action(NotificationActions.GetUnseenNotificationIds)
  getUnseenNotificationIds(ctx: StateContext<ApiNotificationStateModel>, action: NotificationActions.GetUnseenNotificationIds): Observable<string[]> {
    if (!action.userId) {
      ctx.patchState({
        unseenNotificationIds: [],
      });
      return from(Promise.resolve([]));
    }

    return this.notificationService
      .getNotifications({
        user_id: action.userId,
        partner_id: action.partnerId,
        application: action.application,
        seen_at: {
          null: 'true',
        },
        limit: '0',
        offset: '0',
        fields: ['id'],
      })
      .pipe(
        map((notifications: Notification[]) => {
          const unseenNotificationIds = notifications.map((notification) => notification.id);
          ctx.patchState({
            unseenNotificationIds,
          });
          return unseenNotificationIds;
        }),
        this.catchResponse(),
        catchError((err) => {
          ctx.patchState({
            unseenNotificationIds: [],
          });

          throw err;
        })
      );
  }

  @Action(NotificationActions.LoadMoreNotifications)
  loadMoreNotifications(ctx: StateContext<ApiNotificationStateModel>, action: NotificationActions.LoadMoreNotifications): Observable<Notification[]> {
    if (!action.userId) {
      ctx.patchState({
        notifications: [],
        hasMoreNotification: true,
      });
      return from(Promise.resolve([]));
    }

    if (action.reset) {
      ctx.patchState({
        notifications: [],
        hasMoreNotification: true,
      });
    }

    ctx.patchState({
      gettingNotifications: true,
    });

    const limit = 5;
    const currentNotifications = <Notification[]>JSON.parse(JSON.stringify(ctx.getState().notifications))

    return this.notificationService
      .getNotifications({
        user_id: action.userId,
        partner_id: action.partnerId,
        application: action.application,
        limit: limit.toString(),
        offset: currentNotifications.length.toString(),
      })
      .pipe(
        tap((notifications: Notification[]) => {
          const currentNotifications = <Notification[]>JSON.parse(JSON.stringify(ctx.getState().notifications))
          ctx.patchState({
            gettingNotifications: false,
            notifications: [...currentNotifications, ...notifications],
            hasMoreNotification: notifications.length >= limit,
          });
        }),
        this.catchResponse(),
        catchError((err) => {
          ctx.patchState({
            gettingNotifications: false,
            notifications: [],
            hasMoreNotification: true,
          });

          throw err;
        })
      );
  }

  @Action(NotificationActions.SeenNotification)
  seenNotification(ctx: StateContext<ApiNotificationStateModel>, action: NotificationActions.SeenNotification): Observable<Notification> {
    const unseenNotificationIds = <string[]>JSON.parse(JSON.stringify(ctx.getState().unseenNotificationIds))
    const notifications = <Notification[]>JSON.parse(JSON.stringify(ctx.getState().notifications))
    const unseenNotificationIdIndex = unseenNotificationIds.indexOf(action.notificationId);
    const notificationIndex = notifications.findIndex(({ id }) => id === action.notificationId);

    if (unseenNotificationIdIndex > -1) {
      unseenNotificationIds.splice(unseenNotificationIdIndex, 1);
      ctx.patchState({
        unseenNotificationIds: [...unseenNotificationIds],
      });
    }

    if (notificationIndex > -1) {
      const notification = notifications[notificationIndex];
      notification.seenAt = '0001-01-01 00:00:00';
      notifications.splice(notificationIndex, 1, notification);
      ctx.patchState({
        notifications: [...notifications],
      });
    }

    return this.notificationService.seenNotification(action.notificationId).pipe(
      tap((notification: Notification) => {
        const notifications = <Notification[]>JSON.parse(JSON.stringify(ctx.getState().notifications))
        const notificationIndex = notifications.findIndex(({ id }) => id === action.notificationId);

        if (notificationIndex > -1) {
          notifications.splice(notificationIndex, 1, notification);
          ctx.patchState({
            notifications: [...notifications],
          });
        }
      }),
      this.catchResponse(),
    );
  }

  @Action(NotificationActions.SeenAllNotification)
  seenAllNotification(ctx: StateContext<ApiNotificationStateModel>, action: NotificationActions.SeenAllNotification): Observable<Notification> {
    const notifications = <Notification[]>JSON.parse(JSON.stringify(ctx.getState().notifications))

    notifications.forEach((notification) => {
      notification.seenAt = notification.seenAt || '0001-01-01 00:00:00';
    });

    ctx.patchState({
      notifications: [...notifications],
      unseenNotificationIds: [],
    });

    return this.notificationService.seenAllNotification(action.application, action.partnerId).pipe(
      this.catchResponse(),
    );
  }
}
