import { MembershipStatus, MembershipStatusState } from "@fitscovery/memberships/data-access";

export const getStatusWithState = (state: MembershipStatusState): MembershipStatus => {
  switch (state) {
    case 'pendingMembers':       return MembershipStatus.Pending;
    case 'activeMembers':        return MembershipStatus.Active;
    case 'expiredMembers':       return MembershipStatus.Expired;
    case 'cancelledMembers':     return MembershipStatus.Cancelled;
    case 'interestedMembers':    return MembershipStatus.Interested;
    case 'archivedMembers':      return MembershipStatus.Archived;
    case 'freeMembers':          return MembershipStatus.Free;
    case 'forActivationMembers': return MembershipStatus.ForActivation;
  }
}

export const getStateWithStatus = (state: MembershipStatus): MembershipStatusState => {
  switch (state) {
    case MembershipStatus.Pending:       return 'pendingMembers';
    case MembershipStatus.Active:        return 'activeMembers';
    case MembershipStatus.Expired:       return 'expiredMembers';
    case MembershipStatus.Cancelled:     return 'cancelledMembers';
    case MembershipStatus.Interested:    return 'interestedMembers';
    case MembershipStatus.Archived:      return 'archivedMembers';
    case MembershipStatus.Free:          return 'freeMembers';
    case MembershipStatus.ForActivation: return 'forActivationMembers';
  }
}
