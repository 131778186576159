import { StateContext } from "@ngxs/store";

import { PartnerImageDescription, PartnerWebsite, PartnerWebsiteStateModel } from "./partner-website.model";
import { UpdateAdminBannerResponse, UpdateAdminMediaResponse } from "../interfaces";
import { MediaIdentifier } from "./partner-website.enum";

export function parseBanner(ctx: StateContext<PartnerWebsiteStateModel>, response: UpdateAdminBannerResponse): PartnerWebsite {
    
  const imageDescriptions = ctx.getState()!.imageDescriptions.map((imageDescription: PartnerImageDescription) => {
    if (imageDescription.identifier === MediaIdentifier.BannerImage) {
      return {
        ...imageDescription,
        buttons: response.request.buttons
      };
    }
    return imageDescription;
  });

  return {
    ...response.data.partner_website,
    imageDescriptions
  };
}
