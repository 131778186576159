import { Injectable } from '@angular/core';
import { State, StateToken } from '@ngxs/store';

import { PartnerReportState, partnerReportDefaults } from '.';
import { ApiMembershipStateModel } from '../models';

export const apiReportsStateDefaults: ApiMembershipStateModel = {
  partner: partnerReportDefaults
};

export const API_REPORTS_STATE_TOKEN = new StateToken<ApiMembershipStateModel>('api_reports');

@State<ApiMembershipStateModel>({
	name: API_REPORTS_STATE_TOKEN,
	defaults: apiReportsStateDefaults,
  children: [
    PartnerReportState
  ]
})

@Injectable()
export class ApiReportState {}
