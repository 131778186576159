import { Pipe, PipeTransform } from '@angular/core';

import { MembershipSubscription, Membership } from '@fitscovery/memberships/data-access';

@Pipe({
  name: 'activationStartDate'
})
export class ActivationStartDatePipe implements PipeTransform {

  transform(memberships: Membership[], subscription: MembershipSubscription): Date {

    const subscribedMembership = memberships.find(e => e.partnerMembershipSubscriptionId.toString() === subscription.id!.toString());
    const hasSubscribedMembership = !!subscribedMembership;

    return hasSubscribedMembership 
      ? subscribedMembership.startDate! 
      : subscription.startDate!;
  }

}
