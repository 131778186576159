import { Guid } from '@fitscovery/common/types';
import { Observable } from 'rxjs';
import { Post, GetUserPostsPointsByPartnerIdResponse } from '../models';

export abstract class PostService {

  constructor() { }

  abstract create(post: Post): Observable<Post>;
  abstract delete(id: Guid | string): any;
  abstract getPostsByUserId(id: Guid | undefined): Observable<Post[]>;
  abstract getMemberPostsByPartnerId(id: Guid | string, pageNumber: number, pageSize: number): Observable<Post[]>;
  abstract update(post: Post): Observable<Post>;
  abstract getUserPostsPointsByPartnerId(id: string | Guid, startDate: string, endDate: string): Observable<GetUserPostsPointsByPartnerIdResponse>;
}
