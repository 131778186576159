import { Guid } from '@fitscovery/common/types';

export interface Post {
  id: Guid | string;
  userId: Guid | string;
  mediaUrl: string;
  title: string;
  description: string;
  postCategoryId: number | string;
  privacy: Privacy;
  postCategory: PostCategory;
  createdAt: Date;
  displayName: string;
}

export interface PostCategory {
  id: number;
  title: string;
  description: string;
}

export enum Privacy {
  Private,
  Public
}

export interface MyWebImage {
	caption: string;
	file: File;
	base64: string;
	filePath: string;
	owner: string;
}
export interface GetPostsByUserIdRequest {
  id: Guid | undefined;
  pageNumber: number; 
  pageSize: number;
}

export interface GetPostsByUserIdResponse {
  request: GetPostsByUserIdRequest;
  data: Post[];
  succeeded: boolean;
  errors: any[];
}
