export namespace AccountStoreActions {

  export class EmailPreviousValue {
    static readonly type = '[App] EmailPreviousValue';
    constructor(public previous: string) { }
  }
  
  export class EmailCurrentValue {
    static readonly type = '[App] EmailCurrentValue';
    constructor(public current: string) { }
  }
  
  export class VerificationSent {
    static readonly type = '[App] VerificationSent';
    constructor(public verificationSent: boolean) { }
  }

}
