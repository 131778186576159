import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import * as qs from 'qs';

import { GetPartnerWebsiteByIdResponse, GetPartnerWebsiteBySortKeyResponse, GetPartnerWebsiteBySubdomainResponse, GetPartnerWebsiteCountResponse, GetPartnerWebsitesResponse, PartnerCountRequest, PartnerWebsitesRequest } from '../interfaces';
import { Guid } from '@fitscovery/common/types';
import { Environment } from '@fitscovery/common/environments';
import { APP_ENVIRONMENT } from '../environment-injection';

@Injectable()
export class ApiPartnerWebsitesService {

  constructor(
    @Inject(APP_ENVIRONMENT) private env: Environment,
    private http: HttpClient
  ) { }

  getPartnerWebsitesCount(request: PartnerCountRequest): Observable<GetPartnerWebsiteCountResponse> {
    return this.http.get<GetPartnerWebsiteCountResponse>(`${this.env.nodeApi.partnerWebsites}/partner-websites/count`, {
      params: qs.parse(qs.stringify(request, { skipNulls: true })) as Record<string, any>
    });
  }

  getPartnerWebsites(request: PartnerWebsitesRequest): Observable<GetPartnerWebsitesResponse> {
    const params = qs.parse(qs.stringify(request, { skipNulls: true })) as Record<string, any>;
    return this.http.get<GetPartnerWebsitesResponse>(`${this.env.nodeApi.partnerWebsites}/partner-websites`, {
      params: { ...params, orderBy: 'createdAt|desc' }
    });
  }

  getPartnerWebsiteBySortKey(sortKey: string): Observable<GetPartnerWebsiteBySortKeyResponse> {
    return this.http.get<GetPartnerWebsiteBySortKeyResponse>(`${this.env.nodeApi.partnerWebsites}/partner-websites/sort-key/${sortKey}`);
  }

  getPartnerWebsiteBySubdomain(subdomain: string): Observable<GetPartnerWebsiteBySubdomainResponse> {
    return this.http.get<GetPartnerWebsiteBySubdomainResponse>(`${this.env.nodeApi.partnerWebsites}/partner-websites/subdomain/${subdomain}`);
  }

  getPartnerWebsiteById(partnerId: Guid): Observable<GetPartnerWebsiteByIdResponse> {
    return this.http.get<GetPartnerWebsiteByIdResponse>(`${this.env.nodeApi.partnerWebsites}/partner-websites/${partnerId}`);
  }

}
