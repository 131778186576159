export enum MediaIdentifier {
  BannerImage,
  BannerText,
  ImageDescription1,
  ImageDescription2,
  ImageDescription3,
}

export enum ButtonAlignment {
	Left,
	Center,
	Right
}

export enum ButtonSize {
	Small,
	Medium,
	Large,
	ExtraLarge
}

export enum SocialMediaType {
  Facebook,
  Instagram,
  Twitter,
  Email,
  LinkedIn
}

export enum MediaImageKey {
  Logo = 'logo',
  Details = 'details'
}
