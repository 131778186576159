import { ModuleWithProviders, NgModule } from '@angular/core';
import { NgxsModule } from '@ngxs/store';

import { Environment } from '@fitscovery/common/environments';
import { APP_ENVIRONMENT } from './environment-injection';
import { ApiMembershipsState, MembershipsState, SubscriptionsState, PartnerMembershipsState } from './store';

import { ApiMembershipService, ApiSubscriptionService, ApiPartnerMembershipService, ApiPartnerMemberService, ApiPartnerService, ApiEmailTemplateService, ApiPMSEmailTemplateService, ApiMemberService } from './services';

const STATES = [
  ApiMembershipsState,
  MembershipsState,
  SubscriptionsState,
  PartnerMembershipsState
];

const PROVIDERS = [
  ApiMembershipService,
  ApiSubscriptionService,
  ApiPartnerMembershipService,
  ApiPartnerMemberService,
  ApiPartnerService,
  ApiEmailTemplateService,
  ApiPMSEmailTemplateService,
  ApiMemberService
];

@NgModule({
  imports: [
    NgxsModule.forFeature(STATES)
  ],
  providers: [
    ...PROVIDERS
  ]
})
export class MembershipsDataAccessModule {
  static forRoot(environment: Environment): ModuleWithProviders<MembershipsDataAccessModule> {
    return {
      ngModule: MembershipsDataAccessModule,
      providers: [{ provide: APP_ENVIRONMENT, useValue: environment }]
    };
  }
}
