export class CDNImageProvider {
  static readonly CameraLogo: string = '';

  // default images
  public static imageNotFound =
    'https://d3uee0gs4p7cty.cloudfront.net/default-images/image-not-found.png';
  public static warningIcon =
    'https://d3uee0gs4p7cty.cloudfront.net/default-images/warning.png';
  public static anonymousUser =
    'https://d3uee0gs4p7cty.cloudfront.net/default-images/user/anonymous-user.png';
  public static lock =
    'https://d3uee0gs4p7cty.cloudfront.net/default-images/lock-members-only.png';
  public static iconBlack =
    'https://d3uee0gs4p7cty.cloudfront.net/default-images/logo/icon-black.png';
  public static iconWhite =
    'https://d3uee0gs4p7cty.cloudfront.net/default-images/logo/icon-white.png';
  public static logoBlack =
    'https://d3uee0gs4p7cty.cloudfront.net/default-images/logo/logo-black.png';
  public static logoWhite =
    'https://d3uee0gs4p7cty.cloudfront.net/default-images/logo/logo-white.png';
  public static uploadPhoto =
    'https://d3uee0gs4p7cty.cloudfront.net/icons/upload-photo.png';

  // admin images
  public static manageEventsIcon =
    'https://d3uee0gs4p7cty.cloudfront.net/admin-images/dashboard-icons/create-event.png';
  public static manageWebsiteIcon =
    'https://d3uee0gs4p7cty.cloudfront.net/admin-images/dashboard-icons/browser.png';

  public static background =
    'https://d3uee0gs4p7cty.cloudfront.net/admin-images/woman-in-white-sportswear.webp';
  public static homepageComputer =
    'https://d3uee0gs4p7cty.cloudfront.net/admin-images/homepage-computer.webp';
  public static homepageNotebook =
    'https://d3uee0gs4p7cty.cloudfront.net/admin-images/homepage-notebook.webp';
  public static homepageDumbell =
    'https://d3uee0gs4p7cty.cloudfront.net/admin-images/homepage-dumbell.webp';
  public static homepageBooking =
    'https://s3-us-west-2.amazonaws.com/prod-api-lymberfitness/studios/148359/gallery_images/928216/cropped_20190129_035636242_iOS.jpg?1569758255';

}
