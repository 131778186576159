import { Environment, values } from '@fitscovery/common/environments';

export const environment: Environment = {
  production: false,
  fitscoveryApps: values.dev.fitscoveryApps,
  apiKeys: {
    appInsights: values.dev.apiKeys.appInsights.account,
    googleMaps: values.dev.apiKeys.googleMaps,
    recaptchaKey: values.dev.apiKeys.recaptchaKey,
    segmentKey: values.dev.apiKeys.segmentKey.account,
    pushKey: values.dev.apiKeys.pushKey.account,
    facebookAppId: values.dev.apiKeys.facebookAppId,
    facebookClientToken: values.dev.apiKeys.facebookClientToken
  },
  azureFunctionsApi: values.dev.azureFunctionsApi,
  azureFunctions: {
    images: values.dev.azureFunctions.images
  },
  paymentsApi: values.dev.paymentsApi,
  firebase: values.dev.firebase,
  version: '0.0.0',
  nodeApi: values.dev.nodeApi
};
