import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { Guid } from '@fitscovery/common/types';
import { GetMembershipsResponse, MembershipSubscription } from '../models';
import { MembershipStatus } from '../enums';
import { Environment } from '@fitscovery/common/environments';
import { APP_ENVIRONMENT } from '../environment-injection';

@Injectable()
export class ApiPartnerMembershipService {
    
  constructor(
    @Inject(APP_ENVIRONMENT) private env: Environment,
    private http: HttpClient
  ) { }
  
  getActiveMembershipSubscriptionsByPartnerId(partnerId: Guid): Observable<MembershipSubscription[]> {
    return this.http.get<MembershipSubscription[]>(`${this.env.azureFunctionsApi.memberships}/memberships/partners/${partnerId}/active-subscriptions`);
  }

  getMembersByPartnerId(partnerId: Guid, offset: number, limit: number, filterValue: MembershipStatus): Observable<GetMembershipsResponse> {
    return <Observable<GetMembershipsResponse>>this.http.post(`${this.env.azureFunctionsApi.memberships}/memberships/partners/${partnerId}`, {
      partnerId, offset, limit, filterOperator: '=', filterProperty: 'status', filterValue
    });
  }

  getMembershipSubscriptionsByPartnerId(partnerId: Guid): Observable<MembershipSubscription[]> {
    return this.http.get<MembershipSubscription[]>(`${this.env.azureFunctionsApi.memberships}/memberships/partners/${partnerId}/subscriptions`);
  }

  hasAccessToWorkoutOrProgram(partnerId: Guid, userId: Guid, selectedId: Guid): Observable<boolean> {
    return this.http.post<boolean>(`${this.env.azureFunctionsApi.memberships}/memberships/partners/${partnerId}/users/${userId}/has-access/workout-or-program/${selectedId}`, {
      partnerId, userId, selectedId
    });
  }

}
