import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { Guid } from '@fitscovery/common/types';
import { Booking, BookingData, BookingResponse, PartnerBooking } from '../models/bookings.model';

import { Environment } from '@fitscovery/common/environments';
import { APP_ENVIRONMENT } from '../environment-injection';

@Injectable()
export class ApiBookingsService {

  constructor(
    @Inject(APP_ENVIRONMENT) private env: Environment,
    private http: HttpClient
  ) { }

  getPartnerBooking(partnerId: Guid): Observable<PartnerBooking[]> {
    return this.http.get<PartnerBooking[]>(`${this.env.azureFunctionsApi.bookings}/bookings/partnerId/${partnerId}/pricing`);
  }
  
  getUpcomingBookings(partnerId: Guid): Observable<Booking[]> {
    return this.http.get<Booking[]>(`${this.env.azureFunctionsApi.bookings}/bookings/partners/${partnerId}/members/upcoming`);
  }

  updatePartnerBooking(bookingDetails: PartnerBooking): Observable<PartnerBooking> {
    return this.http.post<PartnerBooking>(`${this.env.azureFunctionsApi.bookings}/bookings/partnerId/${bookingDetails.partnerId}/pricing`, bookingDetails);
  }

  updateMemberBooking(bookingData: BookingData): Observable<BookingResponse> {
    return this.http.put<BookingResponse>(`${this.env.azureFunctionsApi.bookings}/bookings`, bookingData);
  }

  getBookingsByUserId(userId: Guid): Observable<Booking[]> {
    return <Observable<Booking[]>>this.http.get(`${this.env.azureFunctionsApi.bookings}/bookings/memberId/${userId}`);
  }

}
