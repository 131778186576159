import { ValidatorFn, Validators } from '@angular/forms';

export class CreditCard {

  private static amex = new RegExp('^3[47][0-9]{13}$');
  private static visa = new RegExp('^4[0-9]{12}(?:[0-9]{3})?$');
  private static mastercard = new RegExp('^5[1-5][0-9]{14}$');
  private static mastercard2 = new RegExp('^2[2-7][0-9]{14}$');
  private static disco1 = new RegExp('^6011[0-9]{12}[0-9]*$');
  private static disco2 = new RegExp('^62[24568][0-9]{13}[0-9]*$');
  private static disco3 = new RegExp('^6[45][0-9]{14}[0-9]*$');
  private static diners = new RegExp('^3[0689][0-9]{12}[0-9]*$');
  private static jcb =  new RegExp('^35[0-9]{14}[0-9]*$');

  public static get validators(): ValidatorFn[] {
    return [
      Validators.pattern(this.amex.source),
      Validators.pattern(this.visa.source),
      Validators.pattern(this.mastercard.source),
      Validators.pattern(this.mastercard2.source),
      Validators.pattern(this.disco1.source),
      Validators.pattern(this.disco2.source),
      Validators.pattern(this.disco3.source),
      Validators.pattern(this.diners.source),
      Validators.pattern(this.jcb.source)
    ];
  }

  public static creditCardType(cc: string): string | undefined {
    if (this.visa.test(cc)) {
      return 'VISA';
    }
    if (this.amex.test(cc)) {
      return 'AMEX';
    }
    if (this.mastercard.test(cc) || this.mastercard2.test(cc)) {
      return 'MASTERCARD';
    }
    if (this.disco1.test(cc) || this.disco2.test(cc) || this.disco3.test(cc)) {
      return 'DISCOVER';
    }
    if (this.diners.test(cc)) {
      return 'DINERS';
    }
    if (this.jcb.test(cc)) {
      return 'JCB';
    }
    return undefined;
  }

}
