import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';


type sources = 
  'uploadProgress' | 'deleteProgress' | 'asyncLoaded' | 'isMobileWidth' | 
  'modalUploaded' | 'checkArrObs';

@Injectable()
export class AccessService {

  private _source = {
    uploadProgress: new BehaviorSubject(null),
    deleteProgress: new BehaviorSubject(null),
    asyncLoaded: new BehaviorSubject(null),
    modalUploaded: new BehaviorSubject(null),
    checkArrObs: new BehaviorSubject(null),
    isMobileWidth: new BehaviorSubject(null)
  };

  $ = {
    uploadProgress: this.clean(this._source.uploadProgress.asObservable()),
    deleteProgress: this.clean(this._source.deleteProgress.asObservable()),
    asyncLoaded: this.clean(this._source.asyncLoaded.asObservable()),
    modalUploaded: this.clean(this._source.modalUploaded.asObservable()).pipe(
      filter(r => (r === 1))
    ),
    checkArrObs: this.clean(this._source.checkArrObs.asObservable()).pipe(
      filter(r => r.value !== null)
      ),
    isMobileWidth: this.clean(this._source.isMobileWidth.asObservable()).pipe(
      map(r => (r === 1))
    )
  };

  constructor(
  ) { }

  updateSource(key: sources, data: any) {
    this._source[key].next(data);
  }

  private clean(observable: Observable<any>) {
    return observable.pipe(filter(data => data !== null))
  }

}