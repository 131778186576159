import { Guid } from '@fitscovery/common/types';
import { InsightType, Workout, Exercise } from '@fitscovery/workouts/data-access';

export class GetWorkout {
  static readonly type = '[Workout] GetWorkout';

  constructor(public id: string) { }
}

export class GetWorkoutByIdWithSubdomain {
  static readonly type = '[Workout] GetWorkoutByIdWithSubdomain';

  constructor(public id: string, public subdomain: string) { }
}

export class GetMemberOnlyWorkout {
  static readonly type = '[Workout] GetMemberOnlyWorkout';

  constructor(public id: string, public token: string) { }
}

export class GetMemberOnlyWorkoutByIdWithSubdomain {
  static readonly type = '[Workout] GetMemberOnlyWorkoutByIdWithSubdomain';

  constructor(public id: string, public subdomain: string) { }
}

export class GetWorkoutByIdAsAdmin {
  static readonly type = '[Workout] GetWorkoutByIdAsAdmin';

  constructor(public id: string, public partnerId: string) { }
}

export class GetMemberOnlyWorkoutById {
  static readonly type = '[Workout] GetMemberOnlyWorkoutById';

  constructor(public id: string) { }
}

export class GetWorkoutsBySubdomain {
  static readonly type = '[Workout] GetWorkoutsBySubdomain';

  constructor(public subdomain: string, public isAdmin: boolean) { }
}

export class GetWorkoutsBySubdomainWithPaging {
  static readonly type = '[Workout] GetWorkoutsBySubdomainWithPaging';

  constructor(public subdomain: string, public pageNumber: number, public isAdmin: boolean = false) { }
}

export class AddWorkoutUserInsight {
  static readonly type = '[Workout] AddWorkoutUserInsight';

  constructor(public workoutId: Guid | string, public insightType: InsightType) { }
}

export class DeleteWorkout {
  static readonly type = '[Workout] DeleteWorkout';

  constructor(public partnerId: string, public workoutId: string) { }
}

export class CreateWorkout {
  static readonly type = '[Workout] CreateWorkout';

  constructor(public workout: Workout) { }
}

export class UpdateWorkout {
  static readonly type = '[Workout] UpdateWorkout';

  constructor(public workout: Workout) { }
}

export class UpdateExercise {
  static readonly type = '[Workout] UpdateExercise';

  constructor(public exercise: Exercise) { }
}

export class CreateExercise {
  static readonly type = '[Workout] CreateExercise';

  constructor(public exercise: Exercise) { }
}

export class DeleteExercise {
  static readonly type = '[Workout] DeleteExercise';

  constructor(public exercise: Exercise) { }
}

export class SetWorkoutOrder {
  static readonly type = '[Workout] SetWorkoutOrder';

  constructor(public id: Guid, public partnerId: Guid, public orderNumber: number) { }
}
