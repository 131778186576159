import { Guid } from '@fitscovery/common/types';
import { BookingData, PartnerBooking } from '../models';

export namespace BookingsActions {
  
  export class GetPartnerBooking {
    static readonly type = '[Bookings API] GetPartnerBooking';
    constructor(public partnerId: Guid) { }
  }
  
  export class GetUpcomingBookings {
    static readonly type = '[Bookings API] GetUpcomingBookings';
    constructor(public partnerId: Guid) { }
  }
  
  export class UpdatePartnerBooking {
    static readonly type = '[Bookings API] UpdatePartnerBooking';
    constructor(public bookingDetails: PartnerBooking) { }
  }
  
  export class UpdateMemberBooking {
    static readonly type = '[Bookings API] UpdateMemberBooking';
    constructor(public bookingData: BookingData) { }
  }

  export class GetBookingsByUserId {
    static readonly type = '[Booking] GetBookingsByUserId';
    constructor(public userId: Guid) {}
  }

}
