import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { CheckAuthStatusResponse, CreateSessionResponse } from '../models';
import { APP_TOKEN, Token } from '../environment-injection';

@Injectable()
export class ApiSessionService {

  constructor(
    @Inject(APP_TOKEN) private appToken: Token,
    private http: HttpClient
  ) { }

  createSession(idToken: string): Observable<CreateSessionResponse> {
    return this.http.post<CreateSessionResponse>(`${this.appToken.environment.nodeApi.auth}/sessions`, {
      idToken
    }, { withCredentials: true });
  }

  checkAuthStatus(): Observable<CheckAuthStatusResponse> {
    return this.http.post<CheckAuthStatusResponse>(`${this.appToken.environment.nodeApi.auth}/sessions/check-auth-status`, {}, { withCredentials: true });
  }

  signOutClearSession(): Observable<void> {
    return this.http.post<void>(`${this.appToken.environment.nodeApi.auth}/sessions/sign-out`, {}, { withCredentials: true });
  }

}
