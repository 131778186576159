import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { tap } from 'rxjs';
import { GetPostsPointsByUserId, GetUserPostsPointsByPartnerId } from '../actions';
import { GetPostsPointsByUserIdResponse, GetUserPostsPointsByPartnerIdResponse, PostPoints, UserPostsPointsModel } from '../models';
import { ApiPostService } from '../services';

export interface PostUserPointsStateModel {
  isLoaded: boolean;
  isProcessing: boolean;
  userPostsPoints: GetUserPostsPointsByPartnerIdResponse | null;
  postPoints: PostPoints;
}

export const postUserPointsDefaultStateValues: PostUserPointsStateModel = {
  isLoaded: false,
  isProcessing: false,
  userPostsPoints: null,
  postPoints: new PostPoints
};

@State<PostUserPointsStateModel>({
  name: 'postUserPoints',
  defaults: postUserPointsDefaultStateValues
})
@Injectable()
export class PostUserPointsState {

  constructor(private postService: ApiPostService) {
  }

  @Selector()
  static allUserPostPoints(state: PostUserPointsStateModel): GetUserPostsPointsByPartnerIdResponse {
    return state.userPostsPoints!;
  }

  @Selector()
  static topInResults(state: PostUserPointsStateModel): UserPostsPointsModel | null {
    return state.userPostsPoints!.resultAll.length > 0 ? (state.userPostsPoints!.resultAll as any).length[0] : null;
  }

  @Selector()
  static postPointsByUserId(state: PostUserPointsStateModel): PostPoints {
    return state.postPoints;
  }

  @Action(GetUserPostsPointsByPartnerId)
  async getUserPostsPointsByPartnerId(ctx: StateContext<PostUserPointsStateModel>, action: GetUserPostsPointsByPartnerId): Promise<void> {
    this.switchIsProcessingTo(true, ctx);

    try {
      const userPostsPoints = await this.postService.getUserPostsPointsByPartnerId(action.partnerId, action.startDate, action.endDate).toPromise();
      ctx.patchState({
        isLoaded: true,
        isProcessing: false,
        userPostsPoints: userPostsPoints
      });
    } catch (error) {
      this.switchIsProcessingTo(false, ctx);
    }
  }

  private switchIsProcessingTo(isProcessing: boolean, ctx: StateContext<PostUserPointsStateModel>): void {
    ctx.patchState({
      isProcessing: isProcessing
    });
  }

  @Action(GetPostsPointsByUserId)
	getPostPointsByUserId(ctx: StateContext<PostUserPointsStateModel>, action: GetPostsPointsByUserId) {
    return this.postService.getPostsPointsByUserId(action.userId).pipe(
      //this.catchResponse(),
      tap((response: GetPostsPointsByUserIdResponse) => {
        ctx.patchState({ postPoints: response.data });
      })
    );
	}
}
