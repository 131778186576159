import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { HealthCheckResponse } from '../models';

import { APP_TOKEN, Token } from '../environment-injection';

@Injectable()
export class ApiAuthService {

  constructor(
    @Inject(APP_TOKEN) private appToken: Token,
    private http: HttpClient
  ) { }

  healthCheck(): Observable<HealthCheckResponse> {
    return this.http.get<HealthCheckResponse>(`${this.appToken.environment.nodeApi.auth}/auth/health-check`);
  }

}
