import { Component } from '@angular/core';
import { Router, RouterStateSnapshot, ResolveEnd } from '@angular/router';
import { Select, Store } from '@ngxs/store';
import { RouterState } from '@ngxs/router-plugin';
import { filter, map, Observable, switchMap, take, tap } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

import { SessionState } from '@fitscovery/auth/data-access';
import { Language, StoreState } from '@fitscovery/shared/store';

@Component({
  selector: 'fitscovery-root',
  templateUrl: './feature-root.component.html',
  styleUrls: ['./feature-root.component.scss'],
})
export class FeatureRootComponent {

  @Select(SessionState.authenticated) authenticated$: Observable<boolean>;
  @Select(RouterState.state) routerState$: Observable<RouterStateSnapshot>;

  constructor(
    router: Router,
    store: Store,
    translate: TranslateService
  ) {
    
    translate.addLangs([ 'en', 'kr' ]);
    translate.setDefaultLang('en');
    store.select(StoreState.language).subscribe((language: Language) => {
      translate.use(language);
    });

    store.select(SessionState.authenticated).pipe(
      filter(Boolean),
      switchMap(() => router.events),
      filter((e: any) => e instanceof ResolveEnd),
      map((e: ResolveEnd) => e.url === '/auth'),
      filter(Boolean),
      tap(() => router.navigateByUrl('user/details')),
      take(1)
    ).subscribe();
  }

}
