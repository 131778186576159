import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxsModule } from '@ngxs/store';

import { APP_TOKEN, Token } from './environment-injection';
import { ApiUserService } from './services';
import { UserState } from './store';

@NgModule({
  imports: [
    CommonModule,
    NgxsModule.forFeature([ UserState ]),
  ],
  providers: [
    ApiUserService
  ]
})
export class UserDataAccessModule {
  static forRoot(token: Token): ModuleWithProviders<UserDataAccessModule> {
    return {
      ngModule: UserDataAccessModule,
      providers: [{ provide: APP_TOKEN, useValue: token }]
    };
  }
}
