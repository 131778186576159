import { getActionTypeFromInstance, NGXS_PLUGINS } from "@ngxs/store";

import { ApiAuthAction } from "@fitscovery/auth/data-access";
import { userStateDefaults } from "@fitscovery/user/data-access";
import { userMembershipsStateDefaults } from "@fitscovery/memberships/data-access";
import { postDefaultStateValues, postUserPointsDefaultStateValues } from "@fitscovery/posts/data-access";
import { userBookingStateDefaults } from "@fitscovery/bookings/data-access";
import { myWebPostDefaultStateValues, myWebUserStateDefaults } from "@fitscovery/my-web/data-access";

function myWebLogoutPlugin(state: any, action: any, next: any) {

  if (getActionTypeFromInstance(action) === ApiAuthAction.SignOut.type) {
    state = {
      api_my_web_post: myWebPostDefaultStateValues,
      api_my_web_user: myWebUserStateDefaults,
      api_user: userStateDefaults,
      postUserPoints: postUserPointsDefaultStateValues,
      posts: postDefaultStateValues,
      route: state.route,
      shared: state.shared,
      user_bookings: userBookingStateDefaults,
      user_memberships: userMembershipsStateDefaults
    };
  }

  return next(state, action);
}

export const MyWebLogoutPluginProvider = {
  provide: NGXS_PLUGINS,
  useValue: myWebLogoutPlugin,
  multi: true
};
