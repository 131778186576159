import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { GetAdminPartnerByIdResponse, UpdateAdminBannerRequest, UpdateAdminBannerResponse, UpdateAdminLogoRequest, UpdateAdminLogoResponse, UpdateAdminMediaRequest, UpdateAdminMediaResponse, UpdateAdminSocialMediaLinksRequest, UpdateAdminSocialMediaLinksResponse } from '../interfaces';
import { Guid } from '@fitscovery/common/types';
import { Environment } from '@fitscovery/common/environments';
import { APP_ENVIRONMENT } from '../environment-injection';

@Injectable()
export class ApiAdminPartnerWebsitesService {

  constructor(
    @Inject(APP_ENVIRONMENT) private env: Environment,
    private http: HttpClient
  ) { }

  getAdminPartnerById(partnerId: Guid): Observable<GetAdminPartnerByIdResponse> {
    return this.http.get<GetAdminPartnerByIdResponse>(`${this.env.nodeApi.partnerWebsites}/admin/partner-websites/${partnerId}`);
  }

  updateAdminBanner(partnerId: Guid, banner: UpdateAdminBannerRequest): Observable<UpdateAdminBannerResponse> {
    return this.http.put<UpdateAdminBannerResponse>(`${this.env.nodeApi.partnerWebsites}/admin/partner-websites/${partnerId}/banner`, banner);
  }

  updateAdminMedia(partnerId: Guid, media: UpdateAdminMediaRequest): Observable<UpdateAdminMediaResponse> {
    return this.http.put<UpdateAdminMediaResponse>(`${this.env.nodeApi.partnerWebsites}/admin/partner-websites/${partnerId}/media`, media);
  }

  updateAdminLogo(partnerId: Guid, logo: UpdateAdminLogoRequest): Observable<UpdateAdminLogoResponse> {
    return this.http.put<UpdateAdminLogoResponse>(`${this.env.nodeApi.partnerWebsites}/admin/partner-websites/${partnerId}/logo`, logo);
  }

  updateAdminSocialMediaLinks(partnerId: Guid, socialMediaLink: UpdateAdminSocialMediaLinksRequest): Observable<UpdateAdminSocialMediaLinksResponse> {
    return this.http.put<UpdateAdminSocialMediaLinksResponse>(`${this.env.nodeApi.partnerWebsites}/admin/partner-websites/${partnerId}/social-media-links`, socialMediaLink);
  }

}
