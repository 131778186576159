import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import firebase from 'firebase/app';

@Component({
  selector: 'fitscovery-user-details',
  templateUrl: './user-details.component.html',
  styleUrls: ['./user-details.component.scss']
})
export class UserDetailsComponent implements OnInit {

  @Output() logout = new EventEmitter();
  @Output() closeOverlay = new EventEmitter();

  @Input() overlayState: boolean;
  @Input() user: firebase.UserInfo | any;

  constructor() { }

  ngOnInit(): void {
  }

}
