import { Injectable } from '@angular/core';
import { Action, NgxsOnInit, Selector, State, StateContext, StateToken } from '@ngxs/store';
import { TranslateService } from '@ngx-translate/core';

import { StoreActions } from './store.action';
import { Language, StateModel } from './store.model';

export const defaultStoreDefaults: StateModel = {
  language: 'en'
};

export const SHARED_STORE_STATE_TOKEN = new StateToken<StateModel>('shared');

@State<StateModel>({
  name: SHARED_STORE_STATE_TOKEN,
  defaults: defaultStoreDefaults
})
@Injectable()
export class StoreState implements NgxsOnInit {

  @Selector()
  static language(state: StateModel): Language {
    return state.language;
  }

  constructor(
    private translateService: TranslateService
  ) { }

  ngxsOnInit(): void { }

  @Action(StoreActions.ChangeLanguage)
  changeLanguage( ctx: StateContext<StateModel>, action: StoreActions.ChangeLanguage): void {
    this.translateService.use(action.language);
    ctx.patchState({ language: action.language });
  }

}
