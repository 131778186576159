import { NgModule } from '@angular/core';
import { ClientTimePipe } from './client-time.pipe';

const PIPES = [
  ClientTimePipe
];

@NgModule({
  declarations: [ 
    ...PIPES
  ],
  exports: [
    ClientTimePipe
  ]
})
export class ClientTimeModule { }
