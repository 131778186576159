import { NgModule } from '@angular/core';

import { IncludesPipe } from './includes.pipe';

const PIPES = [
  IncludesPipe
];

@NgModule({
  declarations: [
    ...PIPES
  ],
  exports: [
    IncludesPipe
  ]
})
export class IncludesModule { }
