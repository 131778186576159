import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { pickBy } from 'lodash'

import { GetClassificationsResponse } from '../interfaces';
import { PaginationRequest } from '@fitscovery/common/types';
import { Environment } from '@fitscovery/common/environments';
import { APP_ENVIRONMENT } from '../environment-injection';

@Injectable()
export class ApiClassificationsService {

  constructor(
    @Inject(APP_ENVIRONMENT) private env: Environment,
    private http: HttpClient
  ) { }

  getClassifications({ id, search, limit, offset }: Partial<PaginationRequest>): Observable<GetClassificationsResponse> {
    const params = pickBy({ id, search, limit, offset, orderBy: 'createdAt|desc' }, x => x !== undefined) as any;
    return this.http.get<GetClassificationsResponse>(`${this.env.nodeApi.partners}/classifications`, {
      params
    });
  }

}
