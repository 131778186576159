import { ModuleWithProviders, NgModule } from '@angular/core';
import { NgxsModule } from '@ngxs/store';

import { Environment } from '@fitscovery/common/environments';
import { APP_ENVIRONMENT } from './environment-injection';
import { EventsState } from './store';

import { ApiEventService, ApiEventPartnerService } from './services';

@NgModule({
  imports: [
    NgxsModule.forFeature([ EventsState ])
  ],
  providers: [
    ApiEventService,
    ApiEventPartnerService
  ]
})
export class EventsDataAccessModule {
  static forRoot(environment: Environment): ModuleWithProviders<EventsDataAccessModule> {
    return {
      ngModule: EventsDataAccessModule,
      providers: [{ provide: APP_ENVIRONMENT, useValue: environment }]
    };
  }
}
