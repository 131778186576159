import { Guid } from '@fitscovery/common/types';
import { CreateMemberInfo, Membership, MembershipStatusState } from '../models';
import { MembershipStatus } from '../enums';

export namespace MembershipAction {

  export class ImportMembers {
    static readonly type = '[Memberships API] ImportMembers';
    constructor(public formData: FormData) { }
  }
  
  export class CreateMember {
    static readonly type = '[Memberships API] CreateMember';
    constructor(public member: CreateMemberInfo) { }
  }
  
  export class ArchiveMembership {
    static readonly type = '[Memberships API] ArchiveMembership';
    constructor(public membership: Membership) { }
  }
  
  export class UpdateMembership {
    static readonly type = '[Memberships API] UpdateMembership';
    constructor(public membership: Membership) { }
  }
  
  export class StopRecurringMembership {
    static readonly type = '[Memberships API] StopRecurringMembership';
    constructor(public membership: Membership) { }
  }
  
  export class GetMembersByStatus {
    static readonly type = '[Memberships API] GetMembersByStatus';
    constructor(public partnerId: Guid, public state: MembershipStatusState, public data: {
      offset: number, limit: number, page: number, value: MembershipStatus
    }) { }
  }

  export class ResetMembersState {
    static readonly type = '[Memberships API] ResetMembersState';
    constructor(public state?: MembershipStatusState) { }
  }

  export class GetMembershipsByUserId {
    static readonly type = '[Memberships API] GetMembershipsByUserId';
  
    constructor(public userId: Guid) { }

  }

  export class GetMembershipsWithPartnerLogo {
    static readonly type = '[Memberships Api] GetMembershipWithPartnerLogo';
  
    constructor(public memberships: Membership[]) { }
  }

}
