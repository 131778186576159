import { Guid } from "@fitscovery/common/types";
import { UpdateAdminBannerRequest, UpdateAdminLogoRequest, UpdateAdminMediaRequest, UpdateAdminSocialMediaLinksRequest } from "../interfaces";

export namespace PartnerWebsiteAction {

  export class GetPartnerWebsiteBySubdomain {
    static readonly type = '[Partner Websites API] GetPartnerWebsiteBySubdomain';
    constructor(public subdomain: string) { }
  }

  export class UpdatePartnerWebsiteLogo {
    static readonly type = '[Partner Websites API] UpdatePartnerWebsiteLogo';
    constructor(public partnerId: Guid, public request: UpdateAdminLogoRequest) { }
  }

  export class UpdatePartnerWebsiteBanner {
    static readonly type = '[Partner Websites API] UpdatePartnerWebsiteBanner';
    constructor(public partnerId: Guid, public request: UpdateAdminBannerRequest) { }
  }

  export class UpdatePartnerWebsiteMedia {
    static readonly type = '[Partner Websites API] UpdatePartnerWebsiteMedia';
    constructor(public partnerId: Guid, public request: UpdateAdminMediaRequest) { }
  }

  export class UpdatePartnerWebsiteSocialMedia {
    static readonly type = '[Partner Websites API] UpdatePartnerWebsiteSocialMedia';
    constructor(public partnerId: Guid, public request: UpdateAdminSocialMediaLinksRequest) { }
  }

}
