import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { pickBy } from 'lodash'

import { Offerings } from '../store';
import { CreatePartnerResponse, GetAdminClassificationsResponse, GetAdminOfferingsResponse, GetAdminPartnersCountResponse, UpdateAdminClassificationsResponse, UpdateAdminContactInfoRequest, UpdateAdminContactInfoResponse, UpdateAdminOfferingsResponse, UpdateAdminSettingsRequest, UpdateAdminSettingsResponse, GetEntitiesResponse, GetEntityByIdResponse, PartnerClassificationIdsRequest, PartnerRequest } from '../interfaces';
import { Guid, PaginationRequest, QueryParamRequest } from '@fitscovery/common/types';
import { Environment } from '@fitscovery/common/environments';
import { APP_ENVIRONMENT } from '../environment-injection';

@Injectable()
export class ApiAdminPartnersService {

  constructor(
    @Inject(APP_ENVIRONMENT) private env: Environment,
    private http: HttpClient
  ) { }

  getAdminPartnersCount({ id, search }: QueryParamRequest): Observable<GetAdminPartnersCountResponse> {
    return this.http.get<GetAdminPartnersCountResponse>(`${this.env.nodeApi.partners}/admin/partners/count`, {
      params: { id, search }
    });
  }

  getAdminPartners({ id, search, limit, offset }: Partial<PaginationRequest>): Observable<GetEntitiesResponse> {
    const params = pickBy({ id, search, limit, offset, orderBy: 'createdAt|desc' }, x => x !== undefined) as any;
    return this.http.get<GetEntitiesResponse>(`${this.env.nodeApi.partners}/admin/partners`, {
      params
    });
  }

  createPartner(partner: PartnerRequest): Observable<CreatePartnerResponse> {
    return this.http.post<CreatePartnerResponse>(`${this.env.nodeApi.partners}/admin/partners`, partner);
  }

  getAdminPartnerById(partnerId: Guid): Observable<GetEntityByIdResponse> {
    return this.http.get<GetEntityByIdResponse>(`${this.env.nodeApi.partners}/admin/partners/${partnerId}`);
  }

  updateAdminSettings(partnerId: Guid, settings: UpdateAdminSettingsRequest): Observable<UpdateAdminSettingsResponse> {
    return this.http.put<UpdateAdminSettingsResponse>(`${this.env.nodeApi.partners}/admin/partners/${partnerId}/settings`, settings);
  }

  updateAdminContactInfo(partnerId: Guid, contactInfo: UpdateAdminContactInfoRequest): Observable<UpdateAdminContactInfoResponse> {
    return this.http.put<UpdateAdminContactInfoResponse>(`${this.env.nodeApi.partners}/admin/partners/${partnerId}/contact-info`, contactInfo);
  }

  getAdminOfferings(partnerId: Guid): Observable<GetAdminOfferingsResponse> {
    return this.http.get<GetAdminOfferingsResponse>(`${this.env.nodeApi.partners}/admin/partners/${partnerId}/offerings`);
  }

  updateAdminOfferings(partnerId: Guid, offerings: Offerings): Observable<UpdateAdminOfferingsResponse> {
    return this.http.put<UpdateAdminOfferingsResponse>(`${this.env.nodeApi.partners}/admin/partners/${partnerId}/offerings`, offerings);
  }

  getAdminClassifications(partnerId: Guid): Observable<GetAdminClassificationsResponse> {
    return this.http.get<GetAdminClassificationsResponse>(`${this.env.nodeApi.partners}/admin/partners/${partnerId}/classifications`);
  }

  updateAdminClassifications(partnerId: Guid, classificationIds: PartnerClassificationIdsRequest): Observable<UpdateAdminClassificationsResponse> {
    return this.http.put<UpdateAdminClassificationsResponse>(`${this.env.nodeApi.partners}/admin/partners/${partnerId}/classifications`, classificationIds);
  }

}
