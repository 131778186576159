import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Injectable()
export class UpdateTabTitleService {

  constructor(
    private title: Title
  ) { }

  setTitle(title: string) {
    this.title.setTitle(title);
  }

}
