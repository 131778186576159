<div id="user-details" fxLayout="row wrap" fxLayoutAlign="center" class="animate__animated animate__faster" [ngClass]="{ 'animate__slideInDown': overlayState, 'animate__fadeOut': !overlayState }">

  <img
    alt="user-admin-photo"
    defaultImage="https://fitscovery-ng-cdn.s3.ap-southeast-1.amazonaws.com/default-image.png" 
    errorImage="https://fitscovery-ng-cdn.s3.ap-southeast-1.amazonaws.com/default-image.png" 
    [lazyLoad]="user?.photoURL || user?.imageUrl || (user?.firstName | dummyImage)">

  <span fxFlex="grow" [ngStyle]="{ margin: '8px 0 0 0' }">{{ user?.firstName }} {{ user?.lastName }}</span>
  <span fxFlex="grow" [ngStyle]="{ margin: '0 0 16px 0' }">{{ user?.email }}</span>

  <mat-divider fxFlex="grow"></mat-divider>
  
  <div fxFlex="grow" fxLayout="row" fxLayoutAlign="center" class="logout" (click)="logout.emit();closeOverlay.emit();">
    <span>{{ 'navbar.logoutButtonText' | translate }}</span>
  </div>

  <button mat-icon-button (click)="closeOverlay.emit()">
    <mat-icon>close</mat-icon>
  </button>

</div>
