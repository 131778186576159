import { Inject, Injectable } from '@angular/core';
import { AngularFireAnalytics } from '@angular/fire/analytics';
import { Environment } from '@fitscovery/common/environments';
import { APP_ENVIRONMENT } from './environment-injection';
import { CustomProperties, Logger, PartnerCustomProperties } from './logger';
import { SeverityLevel } from './serverity-level';

@Injectable()
export class GoogleAnalytics implements Logger {

  constructor(@Inject(APP_ENVIRONMENT) private env: Environment,
    protected analytics: AngularFireAnalytics
  ) {
  }

  async setUserId(id: string): Promise<void> {
    await this.analytics.setUserId(id);
  }

  async trackError(exception: Error, id?: string, severityLevel?: SeverityLevel): Promise<void> {
    const isFatal = severityLevel === SeverityLevel.Critical || severityLevel === SeverityLevel.Error ? true : false;
    await this.analytics.logEvent('exception', { 'description': exception.message, 'isFatal': isFatal });
  }

  async trackEvent(name: string, customProps?: CustomProperties): Promise<void> {
    await this.analytics.logEvent(name, customProps);
  }

  trackEventByPartner(name: string, customProps: PartnerCustomProperties): void | Promise<void> {
    throw new Error('Method not implemented.');
  }

  trackPageView(path: string, customProps?: CustomProperties, isLoggedIn?: boolean): void | Promise<void> {
    throw new Error('Method not implemented.');
  }
  trackPageViewByPartner(path: string, customProps: PartnerCustomProperties, isLoggedIn?: boolean): void | Promise<void> {
    throw new Error('Method not implemented.');
  }

  trackTrace(message: string, properties?: { [key: string]: any; }, severityLevel?: SeverityLevel): void {
    throw new Error('Method not implemented.');
  }
}