import { NgModule } from '@angular/core';

import { ToLocalTimePipe } from './to-local-time.pipe';

const PIPES = [
  ToLocalTimePipe
];

@NgModule({
  declarations: [
    ...PIPES,
  ],
  exports: [
    ToLocalTimePipe
  ]
})
export class ToLocalTimeModule { }
