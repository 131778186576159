export class FitscoveryError extends Error {

  constructor (error: { message: string,
    code?: number | FitscoveryGenericErrorCode | FitscoveryCRUDOperationError,
    title?:
    string, stack?: string,
    httpStatusCode?: number
    data?: any,
  }) {
    super(error.message);

    this.message = error.message;
    this.code = error.code ?? FitscoveryGenericErrorCode.UnknownError;
    this.title = error.title ?? FitscoveryGenericErrorCode.UnknownError.toString();
    this.stack = error.stack ?? '';
    this.data = error.data ?? {};
    this.httpErrorCode = error.httpStatusCode ?? FitscoveryGenericErrorCode.UnknownError;
  }

  code: number | FitscoveryGenericErrorCode | FitscoveryCRUDOperationError;
  title: string;
  data?: any;
  httpErrorCode?: number;
}

export enum FitscoveryGenericErrorCode {
  UnknownError = 0,
  NetworkError = 1,
  ServerSideError = 500,
  ClientSideError = 999
}

/*
* 10X - Arrary
* 11X - Create
* 12X - Read / Get
* 13X - Update
* 14X - Delete
*/
export enum FitscoveryCRUDOperationError {
  EmptyArrayError = 100,

  ObjectNotFound = 120
}
