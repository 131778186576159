import { ModuleWithProviders, NgModule } from '@angular/core';
import { Environment } from '@fitscovery/common/environments';
import { NgxsModule } from '@ngxs/store';
import { APP_ENVIRONMENT } from './environment-injection';
import { ApiPostService } from './services';
import { PostState, PostUserPointsState } from './store';

const STATES = [
  PostState,
  PostUserPointsState
];

const PROVIDERS = [
  ApiPostService
]

@NgModule({
  imports: [
    NgxsModule.forFeature(STATES)
  ],
  providers: [
    ...PROVIDERS
  ]
})
export class PostsDataAccessModule {
  static forRoot(environment: Environment): ModuleWithProviders<PostsDataAccessModule> {
    return {
      ngModule: PostsDataAccessModule,
      providers: [{ provide: APP_ENVIRONMENT, useValue: environment }]
    };
  }
}
