import { Injectable } from "@angular/core";
import { ApiPostService, Post } from "@fitscovery/posts/data-access";
import { SnackbarService } from "@fitscovery/ui/snackbar";
import { Action, Selector, State, StateContext, StateToken } from "@ngxs/store";
import { MyWebPostActions } from "../action";
import { MyWebPostStateModel } from "../models";

export const myWebPostDefaultStateValues: MyWebPostStateModel = {
    isLoaded: false,
    isProcessing: false,
    posts: null
};


export const API_MY_WEB_POST_STATE_TOKEN = new StateToken<MyWebPostStateModel>('api_my_web_post');

@State<MyWebPostStateModel>({
    name: API_MY_WEB_POST_STATE_TOKEN,
    defaults: myWebPostDefaultStateValues
})

@Injectable()
export class MyWebPostState {

    @Selector()
    static posts(state: MyWebPostStateModel): Post[] {
        return state.posts!;
    }

    @Selector()
    static isLoaded(state: MyWebPostStateModel): boolean {
      return state.isLoaded;
    }
  
    @Selector()
    static isProcessing(state: MyWebPostStateModel): boolean{
      return state.isProcessing;
    }
    
    constructor(
        private postService: ApiPostService,
        private snackbar: SnackbarService
    ) {}

    @Action(MyWebPostActions.GetPostsByUserId)
    async getPostsByUserId(ctx: StateContext<MyWebPostStateModel>, action: MyWebPostActions.GetPostsByUserId): Promise<void> {
      this.switchIsProcessingTo(true, ctx);
  
      try {
        const posts = await this.postService.getPostsByUserId(action.userId, action.pageNumber, action.pageSize).toPromise() as any;
        ctx.patchState({
          isLoaded: true,
          isProcessing: false,
          posts: posts.data
        });
      } catch (error) {
        this.switchIsProcessingTo(false, ctx);
      }
    }

    private switchIsProcessingTo(isProcessing: boolean, ctx: StateContext<MyWebPostStateModel>): void {
        ctx.patchState({
          isProcessing: isProcessing
        });
      }
}

