import { Inject, Injectable } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { Metadata } from '../interfaces';

import { APP_TOKEN, Token } from '../environment-injection';

@Injectable()
export class MetaTagService {

  constructor(
    @Inject(APP_TOKEN) private token: Token,
    private meta: Meta
  ) { }

  updateMetaTagDetails(metadata: Metadata) {
    this.updateMetaTag(metadata);
    this.updateMetaTagFacebook(metadata);
    this.updateMetaTagTwitter(metadata);
  }

  private updateMetaTag(metadata: Metadata) {
    this.meta.updateTag({ property: 'site_name', content: metadata.site! });
    this.meta.updateTag({ property: 'title', content: metadata.title! });
    this.meta.updateTag({ property: 'description', content: metadata.description! });
    this.meta.updateTag({ property: 'image', content: metadata.imageUrl! });
    this.meta.updateTag({ property: 'url', content: metadata.url! });
  }

  private updateMetaTagFacebook(metadata: Metadata) {
    this.meta.updateTag({ property: 'fb:app_id', content: this.token.environment.apiKeys.facebookAppId });
    this.meta.updateTag({ property: 'og:site_name', content: metadata.site! });
    this.meta.updateTag({ property: 'og:title', content: metadata.title! });
    this.meta.updateTag({ property: 'og:description', content: metadata.description! });
    this.meta.updateTag({ property: 'og:image', content: metadata.imageUrl! });
    this.meta.updateTag({ property: 'og:url', content: metadata.url! });
  }

  private updateMetaTagTwitter(metadata: Metadata) {
    this.meta.updateTag({ name: 'twitter:site', content: metadata.site! });
    this.meta.updateTag({ name: 'twitter:title', content: metadata.title! });
    this.meta.updateTag({ name: 'twitter:description', content: metadata.description! });
    this.meta.updateTag({ name: 'twitter:image', content: metadata.imageUrl! });
    this.meta.updateTag({ name: 'twitter:url', content: metadata.url! });
  }

}