import { Injectable } from '@angular/core';
import { Action, NgxsOnInit, Selector, State, StateContext, StateToken } from '@ngxs/store';
import { tap } from 'rxjs/operators';

import { ApiMembershipService, ApiPartnerMembershipService, ApiSubscriptionService } from '../services';
import { SnackbarService } from '@fitscovery/ui/snackbar';
import { SubscriptionAction } from '../actions';
import { SubscriptionsStateModel, MembershipSubscription, MembershipSubscriptionResponse } from '../models';
import { Guid } from '@fitscovery/common/types';
import { errorHandler } from '@fitscovery/common/providers';

export const subscriptionsStateDefaults: SubscriptionsStateModel = {
  data: null,
  selectedSubscription: null,
  hasAccessToWorkoutOrProgram: false,
  loaded: false
};

export const SUBSCRIPTIONS_STATE_TOKEN = new StateToken<SubscriptionsStateModel>('subscriptions');

@State<SubscriptionsStateModel>({
	name: SUBSCRIPTIONS_STATE_TOKEN,
	defaults: subscriptionsStateDefaults
})

@Injectable()
export class SubscriptionsState implements NgxsOnInit {

	@Selector()
	static subscriptions(state: SubscriptionsStateModel): MembershipSubscription[] | null {
		return state.data;
	}

	@Selector()
	static subscriptionWorkouts(state: SubscriptionsStateModel): Guid[] | undefined {
		return state.selectedSubscription?.workouts;
	}

	@Selector()
	static subscriptionPrograms(state: SubscriptionsStateModel): Guid[] | undefined {
		return state.selectedSubscription?.programs;
	}

  @Selector()
	static hasAccessToWorkoutOrProgram(state: SubscriptionsStateModel): boolean {
		return state.hasAccessToWorkoutOrProgram;
	}

	constructor(
    private snackbar: SnackbarService,
    private subscriptionService: ApiSubscriptionService,
    private membershipService: ApiMembershipService,
    private partnerMembershipService: ApiPartnerMembershipService
  ) { }

	ngxsOnInit(): void {
	}

  @Action(SubscriptionAction.GetMembershipSubscriptionById)
	getMembershipSubscriptionById(ctx: StateContext<SubscriptionsStateModel>, action: SubscriptionAction.GetMembershipSubscriptionById) {
    return this.membershipService.getMembershipSubscriptionById(action.id).pipe(
      errorHandler(this.snackbar),
      tap((response: MembershipSubscriptionResponse) => ctx.patchState({ selectedSubscription: response.data }))
    );
	}

  @Action(SubscriptionAction.GetMembershipSubscriptionsByPartnerId)
	getMembershipSubscriptionsByPartnerId(ctx: StateContext<SubscriptionsStateModel>, action: SubscriptionAction.GetMembershipSubscriptionsByPartnerId) {
    return this.partnerMembershipService.getMembershipSubscriptionsByPartnerId(action.partnerId).pipe(
      errorHandler(this.snackbar),
      tap((subscriptions: MembershipSubscription[]) => ctx.patchState({ data: subscriptions }))
    );
	}

	@Action(SubscriptionAction.CreateMembershipSubscription)
	createMembershipSubscription(ctx: StateContext<SubscriptionsStateModel>, action: SubscriptionAction.CreateMembershipSubscription) {
    return this.subscriptionService.createMembershipSubscription(action.membershipSubscription).pipe(
      errorHandler(this.snackbar)
    );
	}

	@Action(SubscriptionAction.UpdateMembershipSubscription)
	updateMembershipSubscription(ctx: StateContext<SubscriptionsStateModel>, action: SubscriptionAction.UpdateMembershipSubscription) {
    return this.subscriptionService.updateMembershipSubscription(action.membershipSubscription).pipe(
      errorHandler(this.snackbar)
    );
	}

	@Action(SubscriptionAction.DeleteMembershipSubscription)
	deleteMembershipSubscription(ctx: StateContext<SubscriptionsStateModel>, action: SubscriptionAction.DeleteMembershipSubscription) {
    return this.subscriptionService.deleteMembershipSubscription(action.membershipSubscriptionId).pipe(
      errorHandler(this.snackbar)
    );
	}

  @Action(SubscriptionAction.CreateMembershipSubscriptionWithPMSEmailTemplate)
	createMembershipSubscription_withPMSEmailTemplate(ctx: StateContext<SubscriptionsStateModel>, action: SubscriptionAction.CreateMembershipSubscriptionWithPMSEmailTemplate) {
    return this.subscriptionService.createMembershipSubscriptionWithPMSEmailTemplate(action.membershipSubscription).pipe(
      errorHandler(this.snackbar)
    );
	}

	@Action(SubscriptionAction.UpdateMembershipSubscriptionWithPMSEmailTemplate)
	updateMembershipSubscription_withPMSEmailTemplate(ctx: StateContext<SubscriptionsStateModel>, action: SubscriptionAction.UpdateMembershipSubscriptionWithPMSEmailTemplate) {
    return this.subscriptionService.updateMembershipSubscriptionWithPMSEmailTemplate(action.membershipSubscription).pipe(
      errorHandler(this.snackbar)
    );
	}

  @Action(SubscriptionAction.SetMembershipSubscriptionOrder)
	setMembershipSubscriptionOrder(ctx: StateContext<SubscriptionsStateModel>, action: SubscriptionAction.SetMembershipSubscriptionOrder) {
    return this.subscriptionService.setMembershipSubscriptionOrder(action.id, action.partnerId, action.orderNumber).pipe(
      errorHandler(this.snackbar)
    );
	}

  @Action(SubscriptionAction.HasAccessToWorkoutOrProgram)
	hasAccessToWorkoutOrProgram(ctx: StateContext<SubscriptionsStateModel>, action: SubscriptionAction.HasAccessToWorkoutOrProgram) {
    return this.partnerMembershipService.hasAccessToWorkoutOrProgram(action.partnerId, action.userId, action.selectedId).pipe(
      tap((hasAccess: boolean) => ctx.patchState({ hasAccessToWorkoutOrProgram: hasAccess }))
    );
	}

  @Action(SubscriptionAction.RemoveSelectedSubscription)
  removeSelectedSubscription(ctx: StateContext<SubscriptionsStateModel>) {
    ctx.patchState({ selectedSubscription: null });
  }

}
