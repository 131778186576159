import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { FitscoveryError, Guid } from '@fitscovery/common/types';
import { Observable } from 'rxjs';
import { catchError, retry, share, map } from 'rxjs/operators';
import { Post, GetPostsByPartnerIdResponse, GetUserPostsPointsByPartnerIdResponse, PostPoints, GetPostsPointsByUserIdResponse } from '../models';
import { PostService } from './abstract-post.service';
import { Select } from '@ngxs/store';
import { Environment } from '@fitscovery/common/environments';
import { APP_ENVIRONMENT } from '../environment-injection';
@Injectable()
export class ApiPostService extends PostService {

  filteredData: Event[];

  constructor(
    @Inject(APP_ENVIRONMENT) private env: Environment,
    private httpClient: HttpClient
  ) {
    super();
  }

  create(post: Post): Observable<Post> {
    post.createdAt = new Date();
    return this.httpClient.post<Post>(`${this.env.azureFunctionsApi.posts}/posts`, post);
  }

  delete(id: string | Guid): Observable<Post | any> {
    return this.httpClient.delete<Post>(`${this.env.azureFunctionsApi.posts}/posts/${id}/`);
  }

  getPostsByUserId(id: Guid | undefined, pageNumber: number = 1, pageSize: number = 5): Observable<Post[]> {
    return this.httpClient.post<Post[]>(`${this.env.azureFunctionsApi.posts}/posts/userId/${id}`, { pageNumber, pageSize })
      .pipe(
        share(),
        retry(3),
        catchError((error) => {
          throw new FitscoveryError({
            message: 'Failed to get posts after retrying 3 times. Kindly refresh or try again later.',
            data: error
          });
        })
      );
  }

  getMemberPostsByPartnerId(id: string | Guid, pageNumber: number = 1, pageSize: number = 5): Observable<Post[]> {
    return (this.httpClient.post(`${this.env.azureFunctionsApi.posts}/posts/members/partnerId/${id}`, { pageNumber: pageNumber, pageSize: pageSize }) as any)
      .pipe(
        map((response: GetPostsByPartnerIdResponse) => {
          return response.data;
        }),
        share(),
        retry(3),
        catchError((error) => {
          throw new FitscoveryError({
            message: 'Failed to get posts after retrying 3 times. Kindly refresh or try again later.',
            data: error
          });
        })
      );
  }

  getUserPostsPointsByPartnerId(id: string | Guid, startDate: Date | string, endDate: Date | string): Observable<GetUserPostsPointsByPartnerIdResponse> {
    const data = {
      partnerId: id,
      startDate: startDate,
      endDate: endDate
    };
    return (this.httpClient.post(`${this.env.azureFunctionsApi.posts}/posts/userPoints/partnerId/${id}`, data) as any)
      .pipe(
        map((response: GetUserPostsPointsByPartnerIdResponse) => {
          return response;
        }),
        share(),
        retry(3),
        catchError((error) => {
          throw new FitscoveryError({
            message: 'Failed to get posts after retrying 3 times. Kindly refresh or try again later.',
            data: error
          });
        })
      );
  }

  getPostsPointsByUserId(userId: Guid | undefined): Observable<GetPostsPointsByUserIdResponse> {
    return this.httpClient.get<GetPostsPointsByUserIdResponse>(`${this.env.azureFunctionsApi.posts}/posts/points/userId/${userId}`);
  }

  update(post: Post): Observable<any> {
    post.createdAt = new Date();
    return this.httpClient.put(`${this.env.azureFunctionsApi.posts}/posts/${post.id}`, post);
  }
}
