import { Inject, Injectable } from '@angular/core';
import { Action, NgxsOnInit, State, StateContext, StateToken, Store } from '@ngxs/store';
import { AngularFireAuth } from '@angular/fire/auth';
import { firstValueFrom } from 'rxjs';
import firebase from 'firebase/app';

import { ApiAuthAction, FirebaseAction, SessionAction } from '../actions';
import { FirebaseStateModel } from '../models';
import { AUTH_METHOD, SegmentAnalytics, USER_AUTH_EVENTS } from '@fitscovery/utils/logger';
import { SnackbarService } from '@fitscovery/ui/snackbar';
import { APP_TOKEN, Token } from '../environment-injection';
import { WINDOW } from '@ng-web-apis/common';
import { SessionState } from './session.state';

export const FIREBASE_STATE_TOKEN = new StateToken<FirebaseStateModel>('firebase');

@State<FirebaseStateModel>({
	name: FIREBASE_STATE_TOKEN
})

@Injectable()
export class FirebaseState implements NgxsOnInit {

	constructor(
    @Inject(WINDOW) private window: any,
    @Inject(APP_TOKEN) private appToken: Token,
    private auth: AngularFireAuth,
    private store: Store,
    private appInsights: SegmentAnalytics,
    private snackbar: SnackbarService
  ) { }

	ngxsOnInit(ctx: StateContext<FirebaseStateModel>): void {
    this.auth.authState.subscribe(async (state) => {

      this.appToken.environment.production
      || console.log('\n\n\n@@@ Firebase (auth state)', state?.toJSON(), '\n\n\n\n');
      
      if (this.store.selectSnapshot(SessionState.authenticated) && !state) {
        try {
          await firstValueFrom(ctx.dispatch(new SessionAction.Authenticate));
        } catch (error) {
          ctx.dispatch(new ApiAuthAction.SignOut);
        }
      }
    });
	}

  @Action(FirebaseAction.SignInWithPassword)
	async signInWithPassword(_: StateContext<FirebaseStateModel>, action: FirebaseAction.SignInWithPassword) {
    try {
      this.appInsights.trackEvent(USER_AUTH_EVENTS.LOGIN, { method: AUTH_METHOD.EMAIL_PASSWORD });
      await this.auth.signInWithEmailAndPassword(action.email, action.password);
    } catch (error) {
      this.snackbar.openSnack({ message: error.message, duration: 5000 });
      throw new Error(error.message);      
    }
  }

  @Action(FirebaseAction.SignInWithGoogle)
	async signInWithGoogle(_: StateContext<FirebaseStateModel>) {
    try {
      this.appInsights.trackEvent(USER_AUTH_EVENTS.LOGIN, { method: AUTH_METHOD.GOOGLE });
      console.log('second')
      await this.auth.signInWithPopup(new firebase.auth.GoogleAuthProvider())
    } catch (error) {
      console.log('signInWithGoogle error', error)
      this.snackbar.openSnack({ message: error.message, duration: 5000 });
      throw new Error(error.message);      
    }
  }

  @Action(FirebaseAction.SignInWithFacebook)
	async signInWithFacebook(_: StateContext<FirebaseStateModel>) {
    try {
      this.appInsights.trackEvent(USER_AUTH_EVENTS.LOGIN, { method: AUTH_METHOD.FACEBOOK });
      await this.auth.signInWithRedirect(new firebase.auth.FacebookAuthProvider())
    } catch (error) {
      this.snackbar.openSnack({ message: error.message, duration: 5000 });
      throw new Error(error.message);      
    }
  }

  @Action(FirebaseAction.SignUpWithPassword)
	async signUpWithPassword(_: StateContext<FirebaseStateModel>, action: FirebaseAction.SignUpWithPassword) {
    try {
      this.appInsights.trackEvent(USER_AUTH_EVENTS.SIGN_UP, { method: AUTH_METHOD.EMAIL_PASSWORD});
      await this.auth.createUserWithEmailAndPassword(action.email, action.password);
    } catch (error) {
      this.snackbar.openSnack({ message: error.message, duration: 5000 });
      throw new Error(error.message);      
    }
  }

}
