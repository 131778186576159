import { Metadata, EventData } from '../interfaces';
import { ApplicationProvider } from './application.provider';

export class PartnerWebProvider extends ApplicationProvider {

  constructor(eventData: EventData) {
    super(eventData);
  }

  get routeMetadata(): Metadata {
    return {
      ... this.customMetadata,
      ...this.defaultMetadataConfig
    }
  }

  private get customMetadata(): Metadata | undefined {

    switch (this.path) {
      case 'route': return {
        title: 'title',
        description: 'description',
      }
      default: return undefined;
    }
  }

}
