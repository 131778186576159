import { Language } from './store.model';

export namespace StoreActions {

  export class ChangeLanguage {
    static readonly type = '[DefaultStore] ChangeLanguage';
    constructor(public language: Language) { }
  }

}
