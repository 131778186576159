import { FormGroup } from '@angular/forms';


export interface AppFormBuilder<T> {
  buildFormGroup(eventData?: any): FormGroup;
}

export abstract class FormGroupBuilder<T> {
  abstract buildFormGroup(data?: T): FormGroup;
  abstract buildObjectFromForm(formData: FormGroup): T;
}
