import { Pipe, PipeTransform } from '@angular/core';

import { dummyImage } from '@fitscovery/common/providers';


@Pipe({
  name: 'dummyImage'
})
export class DummyImagePipe implements PipeTransform {

  transform(text: string): string {
    return dummyImage(text);
  }

}
