import { Injectable } from '@angular/core';
import { Action, NgxsOnInit, Selector, State, StateContext, StateToken } from '@ngxs/store';
import { AngularFireAuth } from '@angular/fire/auth';
import { tap } from 'rxjs/operators';

import { ApiSessionService } from '../services';
import { SessionAction } from '../actions';
import { SessionStateModel, CheckAuthStatusResponse } from '../models';
import { SnackbarService } from '@fitscovery/ui/snackbar';
import { errorHandler } from '@fitscovery/common/providers';
import { firstValueFrom } from 'rxjs';

export const sessionStateDefaults: SessionStateModel = {
  accessToken: null,
  customToken: null
};

export const SESSION_STATE_TOKEN = new StateToken<SessionStateModel>('session');

@State<SessionStateModel>({
	name: SESSION_STATE_TOKEN,
	defaults: sessionStateDefaults
})

@Injectable()
export class SessionState implements NgxsOnInit {

	@Selector()
	static accessToken(state: SessionStateModel): string {
		return state.accessToken!;
	}

	@Selector()
	static customToken(state: SessionStateModel): string {
		return state.customToken!;
	}

	@Selector()
	static authenticated(state: SessionStateModel): boolean {
		return !!state.accessToken;
	}

	constructor(
    private auth: AngularFireAuth,
    private sessionService: ApiSessionService,
    private snackbar: SnackbarService
  ) { }

	ngxsOnInit(): void {
	}

  // TODO remove before finalize
  @Action(SessionAction.SetAccessToken)
	setAccessToken(ctx: StateContext<SessionStateModel>, action: SessionAction.SetAccessToken) {
    ctx.patchState({ accessToken: action.accessToken });
  }

  @Action(SessionAction.CreateSession)
	createSession(_: StateContext<SessionStateModel>, action: SessionAction.CreateSession) {
    return this.sessionService.createSession(action.idToken).pipe(
      errorHandler(this.snackbar)
    );
  }

  @Action(SessionAction.CheckSession)
	checkSession(ctx: StateContext<SessionStateModel>) {
    return this.sessionService.checkAuthStatus().pipe(
      errorHandler(),
      tap((response: CheckAuthStatusResponse) => ctx.patchState({ customToken: response.customToken }))
    );
  }

  @Action(SessionAction.SignInWithCustomToken)
	async signInWithCustomToken(ctx: StateContext<SessionStateModel>, action: SessionAction.SignInWithCustomToken) {
    const state = await this.auth.signInWithCustomToken(action.customToken);
    const accessToken = Object.create(state.user?.toJSON()!).stsTokenManager.accessToken;
    ctx.patchState({ accessToken });
  }

  @Action(SessionAction.Authenticate)
	async authenticate(ctx: StateContext<SessionStateModel>) {
    await firstValueFrom(ctx.dispatch(new SessionAction.CheckSession));
    await firstValueFrom(ctx.dispatch(new SessionAction.SignInWithCustomToken(ctx.getState().customToken!)));
  }

}
