import { NgModule } from '@angular/core';
import { LoadedPipe } from './loaded.pipe';

const PIPES = [
  LoadedPipe
];

@NgModule({
  declarations: [ 
    ...PIPES
  ],
  exports: [
    LoadedPipe
  ]
})
export class LoadedModule { }
