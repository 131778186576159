import { Pipe, PipeTransform } from '@angular/core';

import { TimezoneProvider } from '@fitscovery/common/providers';

@Pipe({
  name: 'toLocalTime'
})
export class ToLocalTimePipe implements PipeTransform {

  transform(datetime: any): any {
    return TimezoneProvider.convertToLocalTime(datetime);
  }

}
