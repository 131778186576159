import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { Guid } from '@fitscovery/common/types';
import { PartnerReportResponse } from '../models';
import { TimeFrame } from '../enums';

import { Environment } from '@fitscovery/common/environments';
import { APP_ENVIRONMENT } from '../environment-injection';

@Injectable()
export class ApiPartnerReportService {
    
  constructor(
    @Inject(APP_ENVIRONMENT) private env: Environment,
    private http: HttpClient
  ) { }

  getPartnerReports(partnerId: Guid, filter: TimeFrame, startTime: Date, endTime: Date): Observable<PartnerReportResponse> {
    return <Observable<PartnerReportResponse>>this.http.post(`${this.env.azureFunctionsApi.reports}/reports/partners/${partnerId}`, {
      filter, startTime, endTime
    });
  }

}
