import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { Guid } from '@fitscovery/common/types';
import { Membership, MembersResponse } from '../models';
import { MembershipStatus } from '../enums';
import { Environment } from '@fitscovery/common/environments';
import { APP_ENVIRONMENT } from '../environment-injection';

@Injectable()
export class ApiPartnerService {

  constructor(
    @Inject(APP_ENVIRONMENT) private env: Environment,
    private http: HttpClient
  ) { }

  getMembershipsByPartnerIdAndMemberId(partnerId: Guid, memberId: Guid): Observable<Membership[]> {
		return this.http.get<Membership[]>(`${this.env.azureFunctionsApi.memberships}/partners/${partnerId}/members/${memberId}/subscriptions`);
	}
  
  getPartnerById(id: Guid): Observable<any> {
    return <Observable<any>>this.http.get(`${this.env.azureFunctionsApi.partners}/partners/${id}`);
  }

  getPartnerByPartnerIdWithLogo(id: Guid): Observable<any> {
    return <Observable<any>>this.http.get(`https://dev-api-partner-websites-sea.fitscovery.com/api/v1/partner-websites?partnerId[]=${id}`);
  }

  getMembersByStatus(partnerId: Guid, offset: number, limit: number, value: MembershipStatus): Observable<MembersResponse> {
    return this.http.get<MembersResponse>(`${this.env.azureFunctionsApi.memberships}/partners/${partnerId}/members-with-memberships-summary/status/${value}/offset/${offset}/limit/${limit}`);
  }

  // getMemberSubscriptionsByStatus(partnerId: Guid, memberId: Guid, status: MembershipStatus) {
  //   return this.http.get(`${this.env.azureFunctionsApi.memberships}/partners/${partnerId}/members/${memberId}/status/${status}`);
  // }

}
