import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Environment } from '@fitscovery/common/environments';
import { Observable } from 'rxjs';
import { APP_ENVIRONMENT } from '../environment-injection';
import { WebPushLoginResponse, WebPushLogoutResponse } from '../models';

@Injectable()
export class WebPushNotificationApiService {
  constructor(@Inject(APP_ENVIRONMENT) private env: Environment, private http: HttpClient) {}

  loginToWebPushApi(subscriptionString: string, userId: string, application: string): Observable<WebPushLoginResponse> {
    return this.http.put<WebPushLoginResponse>(`${this.env.azureFunctionsApi.notifications}/web-push-login`, { subscriptionString, userId, application });
  }

  logoutToWebPushApi(subscriptionString: string): Observable<WebPushLogoutResponse> {
    return this.http.put<WebPushLogoutResponse>(`${this.env.azureFunctionsApi.notifications}/web-push-logout`, { subscriptionString });
  }
}
