import { Injectable } from '@angular/core';
import { State, StateToken } from '@ngxs/store';

import { MembershipsState, SubscriptionsState, PartnerMembershipsState, membershipsStateDefaults, subscriptionsStateDefaults, partnerMembershipsStateDefaults } from '.';
import { ApiMembershipStateModel } from '../models';

export const apiMembershipsStateDefaults: ApiMembershipStateModel = {
  memberships: membershipsStateDefaults,
  subscriptions: subscriptionsStateDefaults,
  partnerMemberships: partnerMembershipsStateDefaults
};

export const API_MEMBERSHIPS_STATE_TOKEN = new StateToken<ApiMembershipStateModel>('api_memberships');

@State<ApiMembershipStateModel>({
	name: API_MEMBERSHIPS_STATE_TOKEN,
	defaults: apiMembershipsStateDefaults,
  children: [
    MembershipsState,
    SubscriptionsState,
    PartnerMembershipsState
  ]
})

@Injectable()
export class ApiMembershipsState {}
