import { NgModule } from '@angular/core';

import { ActivationStartDatePipe } from './activation-start-date.pipe';

const PIPES = [
  ActivationStartDatePipe
];

@NgModule({
  declarations: [
    ...PIPES,
  ],
  exports: [
    ActivationStartDatePipe
  ]
})
export class ActivationStartDateModule { }
