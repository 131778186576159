import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { Environment } from '@fitscovery/common/environments';
import { NgxsModule } from '@ngxs/store';
import { APP_ENVIRONMENT } from './environment-injection';
import { NotificationService, SignalRService, WebPushNotificationApiService } from './services';
import { WebPushNotificationState } from './store';
import { NotificationState } from './store/notification.state';

@NgModule({
  imports: [CommonModule, NgxsModule.forFeature([NotificationState, WebPushNotificationState])],
  providers: [NotificationService, SignalRService, WebPushNotificationApiService],
})
export class NotificationDataAccessModule {
  static forRoot(environment: Environment): ModuleWithProviders<NotificationDataAccessModule> {
    return {
      ngModule: NotificationDataAccessModule,
      providers: [{ provide: APP_ENVIRONMENT, useValue: environment }],
    };
  }
}
