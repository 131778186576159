import { ModuleWithProviders, NgModule } from '@angular/core';
import { NgxsModule } from '@ngxs/store';

import { Environment } from '@fitscovery/common/environments';
import { APP_ENVIRONMENT } from './environment-injection';
import { ApiReportState, PartnerReportState } from './store';

import { ApiPartnerReportService } from './services';

const STATES = [
  ApiReportState,
  PartnerReportState
];

const PROVIDERS = [
  ApiPartnerReportService
];

@NgModule({
  imports: [
    NgxsModule.forFeature(STATES)
  ],
  providers: [
    ...PROVIDERS
  ]
})
export class ReportsDataAccessModule {
  static forRoot(environment: Environment): ModuleWithProviders<ReportsDataAccessModule> {
    return {
      ngModule: ReportsDataAccessModule,
      providers: [{ provide: APP_ENVIRONMENT, useValue: environment }]
    };
  }
}
