export * from './api-partners.service';
export * from './api-admin-partners.service';
export * from './api-classifications.service';

import { ApiPartnersService } from './api-partners.service';
import { ApiAdminPartnersService } from './api-admin-partners.service';
import { ApiClassificationsService } from './api-classifications.service';

export const ApiServices = [
  ApiPartnersService,
  ApiAdminPartnersService,
  ApiClassificationsService
];
