import { ModuleWithProviders, NgModule } from '@angular/core';
import { NgxsModule } from '@ngxs/store';

import { Environment } from '@fitscovery/common/environments';
import { APP_ENVIRONMENT } from './environment-injection';
import { BookingsState } from './store';
import { ApiBookingsService } from './services';

@NgModule({
  imports: [
    NgxsModule.forFeature([ BookingsState ])
  ],
  providers: [
    ApiBookingsService
  ]
})
export class BookingsDataAccessModule {
  static forRoot(environment: Environment): ModuleWithProviders<BookingsDataAccessModule> {
    return {
      ngModule: BookingsDataAccessModule,
      providers: [{ provide: APP_ENVIRONMENT, useValue: environment }]
    };
  }
}
