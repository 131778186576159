import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { pickBy } from 'lodash'

import { GetPartnerBySubdomainResponse, GetPartnerCountResponse, GetEntitiesResponse, GetEntityByIdResponse } from '../interfaces';
import { Guid, PaginationRequest, QueryParamRequest } from '@fitscovery/common/types';
import { Environment } from '@fitscovery/common/environments';
import { APP_ENVIRONMENT } from '../environment-injection';

@Injectable()
export class ApiPartnersService {

  constructor(
    @Inject(APP_ENVIRONMENT) private env: Environment,
    private http: HttpClient
  ) { }

  getPartnerCount({ id, search }: QueryParamRequest): Observable<GetPartnerCountResponse> {
    return this.http.get<GetPartnerCountResponse>(`${this.env.nodeApi.partners}/partners/count`, {
      params: { id, search }
    });
  }

  getPartners({ id, search, limit, offset }: Partial<PaginationRequest>): Observable<GetEntitiesResponse> {
    const params = pickBy({ id, search, limit, offset, orderBy: 'createdAt|desc' }, x => x !== undefined) as any;
    return this.http.get<GetEntitiesResponse>(`${this.env.nodeApi.partners}/partners`, {
      params
    });
  }

  getPartnerById(partnerId: Guid): Observable<GetEntityByIdResponse> {
    return this.http.get<GetEntityByIdResponse>(`${this.env.nodeApi.partners}/partners/${partnerId}`);
  }

  getPartnerBySubdomain(subdomain: string): Observable<GetPartnerBySubdomainResponse> {
    return this.http.get<GetPartnerBySubdomainResponse>(`${this.env.nodeApi.partners}/partners/subdomain/${subdomain}`);
  }

}
