import { Injectable } from "@angular/core";
import { errorHandler } from "@fitscovery/common/providers";
import { SnackbarService } from "@fitscovery/ui/snackbar";
import { ApiUserService, FitnessConsumerProfile, User, UserAndProfileResponse } from "@fitscovery/user/data-access";
import { Action, NgxsOnInit, Selector, State, StateContext, StateToken } from "@ngxs/store";
import { tap } from "rxjs";
import { MyWebUserActions } from "../action/user.action";
import { MyWebUserStateModel } from "../models";

export const myWebUserStateDefaults: MyWebUserStateModel = {
    user: null,
    fitnessConsumerProfile: null,
    isProcessing: false
  };
  
  export const API_MY_WEB_USER_STATE_TOKEN = new StateToken<MyWebUserStateModel>('api_my_web_user');
  
  @State<MyWebUserStateModel>({
    name: API_MY_WEB_USER_STATE_TOKEN,
    defaults: myWebUserStateDefaults
  })

  
@Injectable()
export class MyWebUserState implements NgxsOnInit {
    

  @Selector()
  static user(state: MyWebUserStateModel): User {
    return state.user!;
  }

  @Selector()
  static fitnessConsumerProfile(state: MyWebUserStateModel): FitnessConsumerProfile {
    return state.fitnessConsumerProfile!;
  }

  @Selector()
  static userProfileInfo(state: MyWebUserStateModel): { user: User, profile: FitnessConsumerProfile } {
    return { user: state.user!, profile: state.fitnessConsumerProfile! }
  }

  @Selector()
  static isProcessing(state: MyWebUserStateModel): boolean {
      return state.isProcessing;
  }

  constructor(
    private snackbar: SnackbarService,
    private userService: ApiUserService,
  ) { }

  

  
  
  @Action(MyWebUserActions.GetFitnessConsumerProfileByUsername)
  getFitnessConsumerProfileByUsername(ctx: StateContext<MyWebUserStateModel>, action: MyWebUserActions.GetFitnessConsumerProfileByUsername) {
    return this.userService.getFitnessConsumerProfileByUsername(action.username).pipe(
      errorHandler(this.snackbar),
      tap((response: UserAndProfileResponse) => ctx.patchState({ fitnessConsumerProfile: response.fitnessConsumerProfile, user: response.user }))
    );
  }
  

  ngxsOnInit(): void {}
}
