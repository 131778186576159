import { getActionTypeFromInstance, NGXS_PLUGINS } from "@ngxs/store";

import { ApiAuthAction } from "@fitscovery/auth/data-access";
import { userStateDefaults } from "@fitscovery/user/data-access";

import { accountStoreDefaults } from '@fitscovery/account-web/shared/store';

function accountWebLogoutPlugin(state: any, action: any, next: any) {

  if (getActionTypeFromInstance(action) === ApiAuthAction.SignOut.type) {
    state = {
      api_user: userStateDefaults,

      app: accountStoreDefaults,
      router: state.router,
      shared: state.shared
    };
  }

  return next(state, action);
}

export const AccountWebLogoutPluginProvider = {
  provide: NGXS_PLUGINS,
  useValue: accountWebLogoutPlugin,
  multi: true
};
