import { Exercise } from './exercise.model';
import { Guid } from '@fitscovery/common/types';

export class BaseResponse {
    errors: string[];
    succeeded: boolean;
}

export class WorkoutResponse extends BaseResponse {
    request: any;
    data: Workout[];
}

export class Workout {
    id: string;
    title: string;
    description: string;
    partnerId: Guid;
    mediaUrl?: string;
    exercises: Exercise[];
    isForMembersOnly: boolean;
    isDisplayed: boolean;
    isArchived: boolean;
    partner: {
      id: Guid;
      name: string;
      subdomain: string;
    };
    isAssigned: boolean;
}
