import { ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';
import { Environment } from '@fitscovery/common/environments';
import { APP_ENVIRONMENT } from './environment-injection';
import { SegmentAnalytics, SegmentConfig, SEGMENT_CONFIG } from './segment';


@NgModule()
export class UtilsLoggerModule {
  static forRoot(environment: Environment, config?: SegmentConfig): ModuleWithProviders<UtilsLoggerModule> {
    return {
      ngModule: UtilsLoggerModule,
      providers: [
        { provide: APP_ENVIRONMENT, useValue: environment },
        { provide: SEGMENT_CONFIG, useValue: config },
        SegmentAnalytics
      ],
    };
  }

  constructor(@Optional() @SkipSelf() parentModule: UtilsLoggerModule) {
    if (parentModule) {
      throw new Error('UtilsLoggerModule is already loaded. Import it in the AppModule only.');
    }
  }
}
