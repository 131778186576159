import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FlexLayoutModule } from '@angular/flex-layout';
import { OverlayModule } from '@angular/cdk/overlay';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { LazyLoadImageModule } from 'ng-lazyload-image';

import { AccountWebSharedUserDetailsModule } from '@fitscovery/account-web/shared/user-details';

import { NavbarService } from './navbar/navbar.service';
import { SnackbarService } from '@fitscovery/ui/snackbar';

import { NavbarComponent } from './navbar/navbar.component';

const MODULES = [
  CommonModule,
  RouterModule,
  FlexLayoutModule,
  OverlayModule,
  MatMenuModule,
  MatIconModule,
  TranslateModule,
  LazyLoadImageModule
];

const LIBRARIES = [
  AccountWebSharedUserDetailsModule
];

const PROVIDERS = [
  NavbarService,
  SnackbarService
];

const COMPONENTS = [
  NavbarComponent
];

@NgModule({
  imports: [
    ...MODULES,
    ...LIBRARIES
  ],
  providers: [
    ...PROVIDERS
  ],
  declarations: [
    ...COMPONENTS
  ],
  exports: [
    NavbarComponent
  ]
})
export class AccountWebSharedNavbarModule {}
