import { Guid } from '@fitscovery/common/types';
import { InsightType, Program } from '@fitscovery/workouts/data-access';

export class GetProgramById {
  static readonly type = '[Program] GetProgramById';

  constructor(public id: Guid) { }
}

export class GetProgramsByPartnerId {
  static readonly type = '[Program] GetProgramsByPartnerId';

  constructor(public partnerId: Guid) { }
}

export class GetProgramByIdWithSubdomain {
  static readonly type = '[Program] GetProgramByIdWithSubdomain';

  constructor(public partnerId: Guid, public subdomain: string) { }
}

export class GetProgramByIdAsAdmin {
  static readonly type = '[Program] GetProgramByIdAsAdmin';

  constructor(public id: string, public partnerId: string) { }
}

export class GetProgramsByPartnerIdWithPaging {
  static readonly type = '[Program] GetProgramsByPartnerIdWithPaging';

  constructor(public partnerId: Guid, public pageNumber: number, public pageSize: number) { }
}

export class AddProgramWorkoutUserInsight {
  static readonly type = '[Program] AddProgramWorkoutUserInsight';

  constructor(public programSectionWorkoutId: Guid | string, public insightType: InsightType) { }
}

export class CreateProgram {
  static readonly type = '[Program] CreateProgram';

  constructor(public program: Program) { }
}

export class DeleteProgram {
  static readonly type = '[Program] DeleteProgram';

  constructor(public partnerId: string, public programId: string) { }
}

export class UpdateProgram {
  static readonly type = '[Workout] UpdateProgram';

  constructor(public program: Program) { }
}

export class SetProgramOrder {
  static readonly type = '[Workout] SetProgramOrder';

  constructor(public id: Guid, public partnerId: Guid, public orderNumber: number) { }
}