import { ModuleWithProviders, NgModule } from '@angular/core';

import { ApiServices } from './services';
import { Environment } from '@fitscovery/common/environments';
import { APP_ENVIRONMENT } from './environment-injection';

@NgModule({
  providers: ApiServices
})
export class PartnersDataAccessModule {
  static forRoot(environment: Environment): ModuleWithProviders<PartnersDataAccessModule> {
    return {
      ngModule: PartnersDataAccessModule,
      providers: [{ provide: APP_ENVIRONMENT, useValue: environment }]
    };
  }
}
