import { NgModule } from '@angular/core';

import { PrivacyPipe } from './privacy.pipe';

const PIPES = [
  PrivacyPipe
];

@NgModule({
  declarations: [
    ...PIPES
  ],
  exports: [
    PrivacyPipe
  ]
})
export class PrivacyModule { }
