import { ModuleWithProviders, NgModule } from '@angular/core';
import { NgxsModule } from '@ngxs/store';

import { Environment } from '@fitscovery/common/environments';
import { APP_ENVIRONMENT } from './environment-injection';
import { UserMembershipState } from './store';
import { ApiMembershipService } from './services';
import { ApiPartnerWebsitesService } from '@fitscovery/partner-websites/data-access';

const STATES = [
  UserMembershipState
];

const PROVIDERS = [
  ApiMembershipService,
  ApiPartnerWebsitesService
];

@NgModule({
  imports: [
    NgxsModule.forFeature(STATES)
  ],
  providers: [
    ...PROVIDERS
  ]
})
export class MyWebMembershipsDataAccessModule {
  static forRoot(environment: Environment): ModuleWithProviders<MyWebMembershipsDataAccessModule> {
    return {
      ngModule: MyWebMembershipsDataAccessModule,
      providers: [{ provide: APP_ENVIRONMENT, useValue: environment }]
    };
  }
}
