import { NgModule } from '@angular/core';

import { TimePeriodPipe } from './time-period.pipe';

const PIPES = [
  TimePeriodPipe
];

@NgModule({
  declarations: [
    ...PIPES,
  ],
  exports: [
    TimePeriodPipe
  ]
})
export class TimePeriodModule { }
