import { Injectable } from '@angular/core';
import { InsightType } from '../enums';
import { Guid, FitscoveryError } from '@fitscovery/common/types';
import { HttpClient } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Select } from '@ngxs/store';
import { UserState, User } from '@fitscovery/user/data-access';
import { environment } from 'apps/partner-web/src/environments/environment';

@Injectable()
export class ApiProgramWorkoutUserInsightService {
	@Select(UserState.user) user$: Observable<User>;
	constructor(private httpClient: HttpClient
	) {
	}

	addInsights(programSectionWorkoutId: Guid | string, insightType: InsightType): any {
		let result;
		this.user$.subscribe(async (user: User) => {
			if (user) {
				const urlUserInsight = `${environment.azureFunctionsApi.workouts}/program-workouts/${programSectionWorkoutId}/user/${user.id}/insights`;
				const requestBody = { programSectionWorkoutId: programSectionWorkoutId, userId: user.id, insightType: insightType };

				result = this.httpClient.post(urlUserInsight, requestBody)
					.pipe(
						catchError((error) => {
							throw new FitscoveryError({ message: error.message, data: error });
						})
					);
			}
		});

		return result;
	}
}
