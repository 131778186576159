// import { Guid, User } from 'src/app/core/models';
import { Guid } from "@fitscovery/common/types";
//import { User } from "@fitscovery/auth/data-access";

export class FitnessConsumerProfile {
  userId: string | Guid;
  isProfilePrivate: boolean;
  username: string;
}

export class FullFitnessConsumerProfileInfo {
  constructor() {
    this.fitnessConsumerProfile = new FitnessConsumerProfile();
    // this.user = new User();
  }
  fitnessConsumerProfile: FitnessConsumerProfile;
  //user: User;
}
