import { Metadata, EventData } from '../interfaces';
import { ApplicationProvider } from './application.provider';

export class AccountWebProvider extends ApplicationProvider {

  constructor(eventData: EventData) {
    super(eventData);
  }

  get routeMetadata(): Metadata {
    return {
      ...this.customMetadata,
      ...this.defaultMetadataConfig
    }
  }

  private get customMetadata(): Metadata {

    const path = this.defaultMetadataConfig.url!;

    if (path.includes('#redirecting')) return {
      title: 'Redirecting',
      description: '',
    }
    if (path.includes('auth/login')) return {
      title: 'Login or sign up | Fitscovery',
      description: '',
    }
    if (path.includes('auth/forgot-password')) return {
      title: 'Forgot password | Fitscovery',
      description: '',
    }
    if (path.includes('auth/register')) return {
      title: 'Create Account | Fitscovery',
      description: '',
    }
    if (path.includes('user/details/profile')) return {
      title: 'Update Account Profile | Fitscovery',
      description: '',
    }
    if (path.includes('user/details/basic-info')) return {
      title: 'Update Basic Information | Fitscovery',
      description: '',
    }
    if (path.includes('user/details/contact-info')) return {
      title: 'Update Contact Information | Fitscovery',
      description: '',
    }
    if (path.includes('user/details')) return {
      title: 'Your Account | Fitscovery',
      description: '',
    }
    else return {
      title: 'Account Portal | Fitscovery',
      description: '',
    };
  }

}
