import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Environment } from '@fitscovery/common/environments';
import * as signalR from '@microsoft/signalr';
import { firstValueFrom, map } from 'rxjs';
import { APP_ENVIRONMENT } from '../environment-injection';

@Injectable()
export class SignalRService {
  constructor(@Inject(APP_ENVIRONMENT) private env: Environment, private http: HttpClient) {}

  async initialize(userId?: string): Promise<signalR.HubConnection> {
    // console.log('SignalRService.initialize', userId);

    const hubConnection = await firstValueFrom(
      this.http.get<any>(`${this.env.azureFunctionsApi.notifications}/signal-r-connection?userId=${userId}`).pipe(
        map((response) => {
          const connectionUrl = response.connectionInfo.url;
          const accessToken = response.connectionInfo.accessToken;

          const hubConnection = new signalR.HubConnectionBuilder()
            // .configureLogging(signalR.LogLevel.Trace)
            .withUrl(connectionUrl, {
              accessTokenFactory: () => accessToken,
            })
            .withAutomaticReconnect()
            .build();

          hubConnection.onreconnected((connectionId?: string) => {
            // console.log('SignalRService onreconnected', connectionId);
          });

          hubConnection.onclose((err?: Error) => {
            // console.log('SignalRService onclose', err);
          });

          hubConnection.onreconnecting((err?: Error) => {
            // console.log('SignalRService reconnecting', err);
          });

          return hubConnection;
        })
      )
    );

    await hubConnection.start();

    hubConnection.on('newNotification', (data: any) => {
      // console.log('SignalRService initialize newNotification data', data);
    });

    return hubConnection;
  }
}
