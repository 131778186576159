import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

import { TimezoneProvider } from '@fitscovery/common/providers';

@Pipe({
  name: 'toLocalTime'
})
export class ToLocalTimePipe implements PipeTransform {

  transform(date: any): any {

    date = new Date(TimezoneProvider.convertToLocalTime(date));

    return moment(date).format('MMM D, YYYY');
  }

}
