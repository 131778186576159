import { Directive, OnInit, ViewContainerRef } from '@angular/core';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';

import { WatchNetworkService } from '../watch-network/watch-network.service';

import { NetworkPromptOverlayComponent } from '../ui/network-prompt-overlay/network-prompt-overlay.component';

@Directive({
  selector: '[networkPrompt]'
})
export class NetworkPromptDirective implements OnInit {

  overlayRef: OverlayRef;
  isUIActive: boolean;
  appOnInit: boolean;

  constructor(
    private overlay: Overlay,
    private viewContainerRef: ViewContainerRef,
    private watchNetworkService: WatchNetworkService
  ) {
    this.appOnInit = true;
    setTimeout(() => (this.appOnInit = false), 1000);
  }

  ngOnInit() {
    this.isUIActive = false;

    this.watchNetworkService.checkNetworkStatus();

    this.watchNetworkService.isOnline$.subscribe(() => {
      this.removeOverlay();
      if (!this.appOnInit) {
        setTimeout(() => this.attachOverlay(), 750);
      }
    });
    
    this.watchNetworkService.closeOverlay$.subscribe(
      () => this.removeOverlay()
    );
  }
  
  attachOverlay() {
    this.isUIActive = true;

    const portal = new ComponentPortal(NetworkPromptOverlayComponent, this.viewContainerRef);

    this.overlayRef = this.overlay.create({
      disposeOnNavigation: true,
      hasBackdrop: false 
    });
    
    this.overlayRef.attach(portal);
  }
  
  removeOverlay() {
    if (!this.isUIActive) return;
    this.overlayRef.detach();
    this.overlayRef.dispose();
    this.isUIActive = false;
  }

}
