import { ModuleWithProviders, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NgxsModule } from '@ngxs/store';

import { APP_TOKEN, Token } from './environment-injection';
import { ApiAuthState, FirebaseState, AuthState, SessionState, AuthAzureState } from './store';

import { ApiAuthService, ApiAuthAzureService, ApiSessionService } from './services';

const MODULES = [
  RouterModule
];

const STATES = [
  ApiAuthState,
  FirebaseState,
  AuthState,
  SessionState,
  AuthAzureState
];

const PROVIDERS = [
  ApiAuthService,
  ApiAuthAzureService,
  ApiSessionService
];

@NgModule({
  imports: [
    ...MODULES,
    NgxsModule.forFeature(STATES)
  ],
  providers: [
    ...PROVIDERS
  ]
})
export class AuthDataAccessModule {
  static forRoot(token: Token): ModuleWithProviders<AuthDataAccessModule> {
    return {
      ngModule: AuthDataAccessModule,
      providers: [{ provide: APP_TOKEN, useValue: token }]
    };
  }
}
