import { NgModule } from '@angular/core';

import { GenderPipe } from './gender.pipe';

const PIPES = [
  GenderPipe
];

@NgModule({
  declarations: [
    ...PIPES
  ],
  exports: [
    GenderPipe
  ]
})
export class GenderModule { }
