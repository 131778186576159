import { NgModule } from '@angular/core';
import { NgxsModule } from '@ngxs/store';
import { TranslateModule } from '@ngx-translate/core';

import { StoreState } from './store';

@NgModule({
  imports: [
    NgxsModule.forFeature([ StoreState ]),
    TranslateModule
  ]
})
export class SharedStoreModule {}
