import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxsModule } from '@ngxs/store';

import { Environment } from '@fitscovery/common/environments';
import { APP_ENVIRONMENT } from './environment-injection';
import { UserBookingState } from './store';
import { ApiBookingsService } from './services';
import { SnackbarService } from '@fitscovery/ui/snackbar';

@NgModule({
  imports: [
    CommonModule,
    NgxsModule.forFeature([ UserBookingState ])
  ],
  providers: [
    ApiBookingsService,
    SnackbarService
  ]
})
export class MyWebBookingsDataAccessModule {

  static forRoot(environment: Environment): ModuleWithProviders<MyWebBookingsDataAccessModule> {
    return {
      ngModule: MyWebBookingsDataAccessModule,
      providers: [{ provide: APP_ENVIRONMENT, useValue: environment }]
    };
  }

}
