import { Environment } from './environment.interface';

export const environment: Environment = {
  production: false,
  fitscoveryApps: {
    accountUrl: '',
    onboardingUrl: '',
    adminUrl: '',
    myDiscoveryUrl: '',
    backOfficeUrl: ''
  },
  apiKeys: {
    appInsights: '',
    googleMaps: '',
    recaptchaKey: '',
    segmentKey: '',
    pushKey: '',
    facebookAppId: '',
    facebookClientToken: ''
  },
  azureFunctionsApi: {
    auth: '',
    events: '',
    memberships: '',
    reports: '',
    partners: '',
    payments: '',
    posts: '',
    products: '',
    users: '',
    workouts: '',
    bookings: '',
    notifications: ''
  },
  azureFunctions: {
    images: {
      uploadUrl: '',
      api: '',
      functionsKey: '',
    },
  },
  paymentsApi: {
    paymongo: '',
    paymongoKey: '',
  },
  firebase: {
    apiKey: '',
    authDomain: '',
    databaseURL: '',
    projectId: '',
    storageBucket: '',
    messagingSenderId: '',
    appId: '',
    measurementId: '',
  },
  version: '0.0.0',
  nodeApi: {
    auth: '',
    users: '',
    payments: '',
    partners: '',
    partnerWebsites: ''
  },
};
