export const fitscoveryApps = {
  accountUrl: 'https://dev-account.fitscovery.com',
  adminUrl: 'https://dev-admin.fitscovery.com',
  onboardingUrl: 'https://dev-onboarding.fitscovery.com',
  myDiscoveryUrl: 'https://dev-my.fitscovery.com',
  backOfficeUrl: 'https://dev-backoffice-fitscovery.web.app'
};

export const apiKeys = {
  appInsights: {
    partner: '',
    account: 'InstrumentationKey=d1e7907c-0440-47c6-bb04-7094f89bd173;IngestionEndpoint=https://southeastasia-1.in.applicationinsights.azure.com',
    onboarding: '',
    admin: '',
    my: '',
    backoffice: ''
  },
  googleMaps: 'AIzaSyDQUPOClCBqAJUmBd3-FkcG6tZJaHL_SoI',
  recaptchaKey: '6Lc_78YUAAAAAPJNHe4B-ZyvRY_ZWI76n0cW6VR9',
  segmentKey: {
    partner: 'Iam7weBrmcaMHz6VUlqnfFDKZLi6hNhF',
    account: 'Iam7weBrmcaMHz6VUlqnfFDKZLi6hNhF',
    onboarding: 'Ss7CSsNrMG9HnHSXQFhwnRitlx9JJcPb',
    admin: 'M9UEKeG3mIBC5LnwWwzyDdRuJvuXzeNT',
    my: 'M9UEKeG3mIBC5LnwWwzyDdRuJvuXzeNT',
    backoffice: ''
  },
  pushKey: {
    partner: '',
    account: '',
    onboarding: '',
    admin: 'BALV6uTa98r-yWo6lnVGQymYT5tbOAy2EL-Pf2-2NmjmsCL12ZQdj9v67DhAHAD7PHMXDpfZCp8jQmLwwaVst2I',
    my: '',
    backoffice: ''
  },
  facebookAppId: '2122810874563822',
  facebookClientToken: '77b6485fcc3b72797a4594ade7093fde'
};

export const azureFunctionsApi = {
  auth: 'https://dev-api-auth-fitscovery.azurewebsites.net/api',
  events: 'https://dev-api-events-fitscovery.azurewebsites.net/api',
  memberships: 'https://dev-functions-memberships-fitscovery.azurewebsites.net/api',
  reports: 'https://dev-functions-reports-fitscovery-sea.azurewebsites.net/api',
  partners: 'https://dev-api-partners-fitscovery.azurewebsites.net/api',
  users: 'https://dev-api-users-fitscovery.azurewebsites.net/api',
  workouts: 'https://dev-api-workouts-fitscovery.azurewebsites.net/api',
  products: 'https://dev-functions-products-fitscovery.azurewebsites.net/api',
  payments: 'https://dev-functions-payments-fitscovery.azurewebsites.net/api',
  posts: 'https://dev-functions-posts-fitscovery.azurewebsites.net/api',
  bookings: 'https://dev-functions-bookings-fitscovery.azurewebsites.net/api',
  notifications: 'https://dev-functions-notifications-fitscovery-node.azurewebsites.net/api',
};

export const azureFunctions = {
  images: {
    uploadUrl: 'https://devfitscoverypartnerimg1.blob.core.windows.net/for-image-compression',
    api: 'https://dev-functions-image-fitscovery.azurewebsites.net/api',
    functionsKey: 'zD47av/RJCeA7TxiLQyAN/aaRiUWrrBw3DL31XUI7jQE//7fDCFvVA==',
  },
};

export const paymentsApi = {
  paymongo: 'https://api.paymongo.com/v1',
  paymongoKey: 'pk_test_AqVzUhLdTX5iGV62EX79HofY',
};

export const firebase = {
  apiKey: 'AIzaSyAPSNihWzdt5mU8v7xibCQbo3dDICX4TZw',
  authDomain: 'mymvmnt-dev.firebaseapp.com',
  // authDomain: 'dev-api-auth-sea.fitscovery.com',
  databaseURL: 'https://mymvmnt-dev.firebaseio.com',
  projectId: 'mymvmnt-dev',
  storageBucket: 'mymvmnt-dev.appspot.com',
  messagingSenderId: '498081428868',
  appId: '1:498081428868:web:4e6e27a6a268f50d55d9d1',
  measurementId: 'G-42D18VQ9CM',
};

export const nodeApi = {
  auth: 'https://dev-api-auth-sea.fitscovery.com/api/v1',
  users: 'https://dev-api-users-sea.fitscovery.com/api',
  payments: 'https://dev-api-payments-sea.fitscovery.com/api/v1',
  partners: 'https://dev-api-partners-sea.fitscovery.com/api/v1',
  partnerWebsites: 'https://dev-api-partner-websites-sea.fitscovery.com/api/v1'
};
