import { RegexProvider } from './regex.provider';

class RegexValidatorMessages {

  static min(min: number) {
    return `Number must be greater than ${min}`;
  }

  static max(max: number) {
    return `Number must be less than ${max}`;
  }

  static get required() {
    return 'All fields are required.';
  }

  static email(config: boolean) {
    return config 
      ? 'Invalid email.'
      : null;
  }

  static minlength(requiredLength: number) {
    return `Characters must be greater than ${requiredLength}`;
  }

  static maxlength(requiredLength: number) {
    return `Characters must be less than ${requiredLength}`;
  }

  static pattern(error: string) {
    return {
      email: 'Invalid email.',
      alpha: 'Characters must be letters only.',
      alphaI18n: 'Characters must be letters only.',
      numeric: 'Characters must be numbers only.',
      alphaNumeric: 'Characters must be alphanumeric.',
      alphaNumericI18n: 'Characters must be alphanumeric.',
      accountNumber: 'Invalid card number.',
      contactNumber: 'Invalid mobile number.',
      domain: 'Invalid domain name.',
      referralCode: 'Invalid referral code.'
    }[error];
  }

  static validatePhoneNumber(config: boolean) {
    return 'Invalid mobile number.';
  }

  static matDatepickerParse(text: string) {
    return `Invalid date.`;
  }

}

export class RegexFactory {
  
  private static get collection(): any {
    
    const regexObj = {} as any;

    for (const [key, value] of Object.entries(RegexProvider)) {
      regexObj[key] = value;
    }

    return regexObj;
  }

  static get baseControl() {
    return {
      min: {}, max: {}, required: {}, email: {},
      minlength: {}, maxlength: {}, pattern: {}
    };
  }

  static min(config: any) {
    
    const min = config.min;
    const actual = config.actual;
    const errorMessage = RegexValidatorMessages.min(min);

    return {
      value: actual,
      message: errorMessage
    };
  }

  static max(config: any) {

    const max = config.max;
    const actual = config.actual;
    const errorMessage = RegexValidatorMessages.max(max);

    return {
      value: actual,
      message: errorMessage
    };
  }
  
  static required(config?: any) {

    const errorMessage = RegexValidatorMessages.required;

    return {
      value: null,
      message: errorMessage
    };
  }

  static requiredTrue(config: any) {
  }

  // NOTE: Please use regex pattern of email
  static email(config: boolean) {

    const errorMessage = RegexValidatorMessages.email(config);

    return {
      value: null,
      message: errorMessage
    };
  }
  
  static minlength(config: any) {

    const requiredLength = config.requiredLength;
    const actualLength = config.actualLength;
    const errorMessage = RegexValidatorMessages.minlength(requiredLength);

    return {
      value: actualLength,
      message: errorMessage
    };
  }
  
  static maxlength(config: any) {

    const requiredLength = config.requiredLength;
    const actualLength = config.actualLength;
    const errorMessage = RegexValidatorMessages.maxlength(requiredLength);

    return {
      value: actualLength,
      message: errorMessage
    };
  }

  static pattern(config: any) {

    const requiredPattern = config.requiredPattern;
    const actualValue = config.actualValue;

    // Temporary workaround to use fromEntries 
    // TODO: Upgrade to angular 12
    const collectionEntries = Object.entries(
      this.collection
    );
    const collection = Object.fromEntries(
      collectionEntries.filter((e: any) => e[1].toString().includes(requiredPattern))
    );

    const error = Object.keys(collection)[0];
    const errorMessage = RegexValidatorMessages.pattern(error);

    return {
      value: actualValue,
      message: errorMessage
    };
  
  }

  static validatePhoneNumber(config: any) {

    const actualValue = config;
    const errorMessage = RegexValidatorMessages.validatePhoneNumber(actualValue);

    return {
      value: actualValue,
      message: errorMessage
    };
  }

  static matDatepickerParse(config: any) {

    const actualValue = config.text;
    const errorMessage = RegexValidatorMessages.matDatepickerParse(actualValue);

    return {
      value: actualValue,
      message: errorMessage
    };
  }

}
