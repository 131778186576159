import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'includes'
})
export class IncludesPipe implements PipeTransform {

  transform(url: string, value: string): boolean {
    return url?.includes(value);
  }

}
