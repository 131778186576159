import { Post } from './post.model';

export interface GetPostsByPartnerIdRequest {
  partnerId: string;
}

export interface GetPostsByPartnerIdResponse {
  request: GetPostsByPartnerIdRequest;
  data: Post[];
  succeeded: boolean;
  errors: any[];
}

export interface GetUserPostsPointsByPartnerIdRequest {
  partnerId: string;
  startDate: string;
  endDate: string;
}

export interface UserPostsPointsModel {
  rank: string;
  displayName: string;
  username: string;
  hasUsername: boolean;
  postPoints: number;
  category: number;
}

export interface GetUserPostsPointsByPartnerIdResponse {
  request: GetUserPostsPointsByPartnerIdRequest;
  resultAll: UserPostsPointsModel[];
  resultWorkout: UserPostsPointsModel[];
  resultNutrition: UserPostsPointsModel[];
  succeeded: boolean;
  errors: any[];
}

export class PostPoints {
  currentWeekPostsPoints: number;
  lastWeekPostsPoints: number;
  currentMonthPostsPoints: number;
  lastMonthPostsPoints: number;
  workoutPostsCount: number;
  nutritionPostsCount: number;

  constructor(){
    this.currentWeekPostsPoints = 0;
    this.lastWeekPostsPoints = 0;
    this.currentMonthPostsPoints = 0;
    this.lastMonthPostsPoints = 0;
    this.workoutPostsCount = 0;
    this.nutritionPostsCount  = 0;
  }
}
export interface GetPostsPointsByUserIdRequest {
  userId: string;
}

export interface GetPostsPointsByUserIdResponse {
  data: PostPoints;
  request: GetPostsPointsByUserIdRequest;
}
