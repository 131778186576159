import { ModuleWithProviders, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { MetaTagService, UpdateTabTitleService, RoutePathService } from './services';

import { RouteObserverDirective } from './directives';
import { APP_TOKEN, Token } from './environment-injection';

const MODULES = [
  RouterModule
];

const PROVIDERS = [
  UpdateTabTitleService,
  RoutePathService
];

const DIRECTIVES = [
  RouteObserverDirective
];

@NgModule({
  imports: [
    ...MODULES
  ],
  providers: [
    ...PROVIDERS
  ],
  declarations: [
    ...DIRECTIVES
  ],
  exports: [
    RouteObserverDirective
  ]
})
export class SharedRouteObserverModule {
  public static forRoot(token: Token): ModuleWithProviders<SharedRouteObserverModule> {
    return {
      ngModule: SharedRouteObserverModule,
      providers: [
        MetaTagService,
        { provide: APP_TOKEN, useValue: token }
      ]
    };
  }
}
