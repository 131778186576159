import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Environment } from '@fitscovery/common/environments';
import * as qs from 'qs';
import { map, Observable } from 'rxjs';
import {
  GetNotificationsCountRequest,
  GetNotificationsCountResponse,
  GetNotificationsRequest,
  GetNotificationsResponse,
  SeenNotificationResponse,
} from '../models';
import { APP_ENVIRONMENT } from '../environment-injection';
import { Notification } from '../models';

@Injectable()
export class NotificationService {
  constructor(@Inject(APP_ENVIRONMENT) private env: Environment, private http: HttpClient) {}

  getNotificationsCount(getRequest?: GetNotificationsCountRequest): Observable<number> {
    const query = getRequest ? qs.stringify(getRequest) : '';
    return this.http
      .get<GetNotificationsCountResponse>(`${this.env.azureFunctionsApi.notifications}/notifications${query ? `?${query}` : ''}`)
      .pipe(map((response) => response.data.count));
  }

  getNotifications(getRequest?: GetNotificationsRequest): Observable<Notification[]> {
    const query = getRequest ? qs.stringify(getRequest) : '';
    return this.http
      .get<GetNotificationsResponse>(`${this.env.azureFunctionsApi.notifications}/notifications${query ? `?${query}` : ''}`)
      .pipe(map((response) => response.data.notifications));
  }

  seenNotification(notificationId: string): Observable<Notification> {
    return this.http
      .put<SeenNotificationResponse>(`${this.env.azureFunctionsApi.notifications}/notifications/${notificationId}/seen`, {})
      .pipe(map((response) => response.data.notification));
  }

  seenAllNotification(application: string, partnerId?: string): Observable<any> {
    return this.http.put(`${this.env.azureFunctionsApi.notifications}/notifications/seen-all`, { application, partnerId });
  }
}
