import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'digits'
})
export class DigitsPipe implements PipeTransform {

  transform(price: number): string {
    return price ? price.toLocaleString('en-US', {
      maximumFractionDigits: 2,
      // minimumFractionDigits: 2 
    }) : '0';
  }

}
