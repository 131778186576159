import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { Guid } from '@fitscovery/common/types';
import { MemberAndSubscriptionsResponse } from '../models';
import { Environment } from '@fitscovery/common/environments';
import { APP_ENVIRONMENT } from '../environment-injection';

@Injectable()
export class ApiMemberService {

  constructor(
    @Inject(APP_ENVIRONMENT) private env: Environment,
    private http: HttpClient
  ) { }

  getMemberByPartnerId(partnerId: Guid, memberId: Guid): Observable<MemberAndSubscriptionsResponse> {
    return this.http.get<MemberAndSubscriptionsResponse>(`${this.env.azureFunctionsApi.memberships}/members/${memberId}/partners/${partnerId}`);
  }

}
