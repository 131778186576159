import { Goal } from '@fitscovery/my-web/shared/models';
//import { HttpClientService } from '@fitscovery/partner-web/shared/services/http';
// import { environment } from '@fitscovery/common/environments';
import { DataGateway, IDataGateway, Serializer } from '../infrastructure/data';
import { Injectable } from '@angular/core';

//@Injectable()
// export class HttpGoalService extends DataGateway<Goal> implements IGoalService {
//   protected items: Goal[];

//   constructor(httpClient: HttpClientService) {
//     // super(httpClient, `${environment.azureFunctionsApi.goals}`, new GoalSerializer());
//     super(httpClient, `test`, new GoalSerializer());
//   }
// }

export interface IGoalService extends IDataGateway<Goal> {

}

export class GoalSerializer implements Serializer<Goal> {
  fromJson(json: any): Goal {
    const item = new Goal();
    item.id = json.id;
    item.userId = json.userId;
    item.activityName = json.activityName;
    item.progress = json.progress;
    item.activityTarget = json.activityTarget;
    item.currentProgress = json.currentProgress;
    item.goalMeasurementId = json.goalMeasurementId;
    item.isActive = json.isActive;
    item.status = json.status;
    return item;
  }

  toJson(item: Goal): any {
    return {
      id: item.id,
      userId: item.userId,
      activityName: item.activityName,
      progress: item.progress,
      activityTarget: item.activityTarget,
      currentProgress: item.currentProgress,
      goalMeasurementId: item.goalMeasurementId,
      isActive: item.isActive,
      status: item.status
    };
  }
}


