import { Guid } from '@fitscovery/common/types';
import { MembershipSubscription } from '../models';

export namespace SubscriptionAction {
  
  export class GetMembershipSubscriptionById {
    static readonly type = '[Memberships API] GetMembershipSubscriptionById';
    constructor(public id: Guid) { }
  }
  
  export class GetMembershipSubscriptionsByPartnerId {
    static readonly type = '[Memberships API] GetMembershipSubscriptionsByPartnerId';
    constructor(public partnerId: Guid) { }
  }
  
  export class GetMembershipSubscriptionsByPartnerIdAndByMemberId {
    static readonly type = '[Memberships API] GetMembershipSubscriptionsByPartnerIdAndByMemberId';
    constructor(public partnerId: Guid, public memberId: Guid) { }
  }

  export class CreateMembershipSubscription {
    static readonly type = '[Memberships API] CreateMembershipSubscription';
    constructor(public membershipSubscription: MembershipSubscription) { }
  }
  
  export class UpdateMembershipSubscription {
    static readonly type = '[Memberships API] UpdateMembershipSubscription';
    constructor(public membershipSubscription: MembershipSubscription) { }
  }
  
  export class DeleteMembershipSubscription {
    static readonly type = '[Memberships API] DeleteMembershipSubscription';
    constructor(public membershipSubscriptionId: string, public partnerId: Guid) { }
  }

  export class CreateMembershipSubscriptionWithPMSEmailTemplate {
    static readonly type = '[Memberships API] CreateMembershipSubscriptionWithPMSEmailTemplate';
    constructor(public membershipSubscription: MembershipSubscription) { }
  }

  export class UpdateMembershipSubscriptionWithPMSEmailTemplate {
    static readonly type = '[Memberships API] UpdateMembershipSubscriptionWithPMSEmailTemplate';
    constructor(public membershipSubscription: MembershipSubscription) { }
  }
  
  export class SetMembershipSubscriptionOrder {
    static readonly type = '[Memberships API] SetMembershipSubscriptionOrder';
    constructor(public id: Guid, public partnerId: Guid, public orderNumber: number) { }
  }
  
  export class HasAccessToWorkoutOrProgram {
    static readonly type = '[Memberships API] HasAccessToWorkoutOrProgram';
    constructor(public partnerId: Guid, public userId: Guid, public selectedId: Guid) { }
  }
  
  export class RemoveSelectedSubscription {
    static readonly type = '[Memberships API] RemoveSelectedSubscription';
    constructor() { }
  }

}
