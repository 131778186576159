import { Pipe, PipeTransform } from '@angular/core';

import { Membership } from '@fitscovery/memberships/data-access';

@Pipe({
  name: 'searchMembers'
})
export class SearchMembersPipe implements PipeTransform {

  transform(members: Membership[], search: string): Membership[] {
    return search ? members.filter((member: any) => member?.displayName?.includes(search)
      || member?.email?.includes(search)
      || member?.fullname?.includes(search)
    ) : members
  }

}
