import { Gender, Guid, UserDetailsResponse } from '@fitscovery/common/types';
import { BaseResponse, ModifiedResponse, RequestResponse } from '@fitscovery/common/types';

export interface UserStateModel {
  info: User | null;
  fitnessConsumerProfile: FitnessConsumerProfile | null;
  partners: PartnerRoleResponse[] | null;
}

export interface UserDetails {
  displayName: string;
  email: string;
	firstName: string;
	lastName: string;
}

// ************************
// User response interfaces
// ************************

export interface UserResponse extends BaseResponse {
  data: User[];
  request: RequestResponse;
}

// ********************************************
// Fitness consumer profile response interfaces
// ********************************************

export interface FitnessConsumerProfileResponse extends BaseResponse {
  data: FitnessConsumerProfile;
  request: RequestResponse;
}

export interface UserAndProfileResponse extends BaseResponse {
  fitnessConsumerProfile: FitnessConsumerProfile;
  user: User;
}

// ***********************************
// Fitness consumer profile interfaces
// ***********************************

export interface FitnessConsumerProfile extends ModifiedResponse {
  userId: Guid;
  isProfilePrivate: boolean;
  username: string;
}

// ************************
// User partners interfaces
// ************************

export interface PartnerData {
  id?: Guid;
  firestoreId?: string;
  name?: string;
  logoUrl?: string;
  subdomain?: string;
  email?: string;
  referralCode?: string;
}

export enum PartnerRole {
  User = 0,
  PartnerManager,
  PartnerAdmin,
  SuperAdmin = 100
}

export interface VendorAdmin extends UserDetailsResponse {
  partners: PartnerRoleResponse[];
  userGuid: Guid;
  userUuid: string;
}

export interface PartnerDataResponse extends PartnerData {
  partnerId: Guid;
  userId: Guid;
}

export interface PartnerRoleResponse {
  partnerData: PartnerData;
  role: PartnerRole;
}

export interface VendorAdminResponse extends BaseResponse {
  data: VendorAdmin;
  request: RequestResponse;
}

// ********************
// User core interfaces
// ********************

export interface Role extends ModifiedResponse {
  id: string;
  name: string;
}

export interface UserRole extends ModifiedResponse {
  id: string;
  userId: string;
  roleId: string;
  role: Role;
}

export interface BaseUser {
	id?: Guid;
	email: string;
	firstName: string;
	lastName: string;
	createdAt: Date;
	updatedAt: Date;
}

export interface User extends BaseUser {
  firebaseId: string;
  displayName: string;
  gender: Gender;
  phoneNumber: string;
  imageUrl: string;
  birthdate: Date;
  isEnabled: boolean;
  isVerified: boolean;
  isLockedOut: boolean;
  userRoles?: UserRole[],
  isProfilePrivate: boolean;
}
