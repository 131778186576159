import { ModuleWithProviders, NgModule } from '@angular/core';

import { Environment } from '@fitscovery/common/environments';
import { APP_ENVIRONMENT } from './environment-injection';
import { TokenInterceptorProvider } from './token.interceptor';

@NgModule()
export class AuthTokenInterceptorModule {
  public static forRoot(environment: Environment): ModuleWithProviders<AuthTokenInterceptorModule> {
    return {
      ngModule: AuthTokenInterceptorModule,
      providers: [
        TokenInterceptorProvider,
        { provide: APP_ENVIRONMENT, useValue: environment }
      ]
    };
  }
}
