import { Injectable } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { firstValueFrom, Observable } from 'rxjs';

import { UserState, User } from '@fitscovery/user/data-access';
import { StoreState, Language, StoreActions } from '@fitscovery/shared/store';
import { ApiAuthAction, SessionAction, SessionState } from '@fitscovery/auth/data-access';

@Injectable()
export class NavbarService {

  @Select(UserState.user) user$: Observable<User>;
  @Select(StoreState.language) language$: Observable<Language>;

  constructor(
    private store: Store
  ) { }

  changeLanguage(language: Language) {
    this.store.dispatch(new StoreActions.ChangeLanguage(language));
  }

  async checkSession(): Promise<void> {
    if (this.store.selectSnapshot(SessionState.authenticated)) return;
    try {
      await firstValueFrom(this.dispatchCheckSessionAction());
    } catch (error) {
      this.store.dispatch(new ApiAuthAction.SignOut);
    }
  }

  async checkSessionCookie(): Promise<void> {
    try {
      await firstValueFrom(this.dispatchCheckSessionAction());
    } catch (error) {
      this.logout();
    }
  }

  dummyImgUrl(initial: string): string {
    return `https://dummyimage.com/48x48/c76262/ffffff.png&text=${initial[0]}`;
  }

  logout(): void {
    if (!this.store.selectSnapshot(SessionState.authenticated)) return;
    this.store.dispatch(new ApiAuthAction.SignOut);
  }

  private dispatchCheckSessionAction(): Observable<any> {
    return this.store.dispatch(new SessionAction.CheckSession)
  }

}
