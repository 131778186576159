import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { Guid } from '@fitscovery/common/types';
import { EventDownloadEventAttendeesResponse, EventsResponse, UpdateEventAttendeeResponse } from '../models';

import { Environment } from '@fitscovery/common/environments';
import { APP_ENVIRONMENT } from '../environment-injection';

@Injectable()
export class ApiEventPartnerService {

  constructor(
    @Inject(APP_ENVIRONMENT) private env: Environment,
    private http: HttpClient
  ) { }

  getEventsBySubdomain(subdomain: string): Observable<EventsResponse> {
    return this.http.get<EventsResponse>(`${this.env.azureFunctionsApi.events}/partners/${subdomain}/events`);
  }

  updateEventAttendee(userId: Guid, partnerId: Guid, eventId: Guid, isPresent: boolean): Observable<UpdateEventAttendeeResponse> {
    return this.http.put<UpdateEventAttendeeResponse>(`${this.env.azureFunctionsApi.events}/partners/${partnerId}/events/${eventId}/user/${userId}/attendance`, {
      eventId, partnerId, userId, isPresent
    });
  }

  updateAllEventAttendee(partnerId: Guid, eventId: Guid, isPresent: boolean): Observable<UpdateEventAttendeeResponse> {
    return this.http.put<UpdateEventAttendeeResponse>(`${this.env.azureFunctionsApi.events}/partners/${partnerId}/events/${eventId}/attendance`, {
      eventId, partnerId, isPresent
    });
  }

  downloadCsvEventAttendees(eventId: Guid, partnerId: Guid): Observable<EventDownloadEventAttendeesResponse> {
    return this.http.get<EventDownloadEventAttendeesResponse>(`${this.env.azureFunctionsApi.events}/partners/${partnerId}/events/${eventId}/attendees/download`);
  }

}
