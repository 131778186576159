import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, catchError } from 'rxjs';

import { UserResponse, FitnessConsumerProfile, FitnessConsumerProfileResponse, UserAndProfileResponse } from '../models';

import { Guid } from '@fitscovery/common/types';
import { VendorAdminResponse } from '@fitscovery/user/data-access';
import { APP_TOKEN, Token } from '../environment-injection';
import { WINDOW } from '@ng-web-apis/common';

@Injectable()
export class ApiUserService {

  constructor(
    private http: HttpClient,
    @Inject(WINDOW) private window: Window,
    @Inject(APP_TOKEN) private token: Token
  ) { }

  getCurrentUser(): Observable<UserResponse> {
    return this.http.get<UserResponse>(`${this.token.environment.azureFunctionsApi.users}/users/firebase/current-user`).pipe(
      catchError<any, any>((response: HttpErrorResponse) => this.errorResponseCatch(response))
    );
  }

  getUserPartners(userId: Guid): Observable<VendorAdminResponse> {
    return this.http.get<VendorAdminResponse>(`${this.token.environment.azureFunctionsApi.users}/users/${userId}/partners`).pipe(
      catchError<any, any>((response: HttpErrorResponse) => this.errorResponseCatch(response)),
    );
  }

  getFitnessConsumerProfileByUserId(userId: Guid): Observable<FitnessConsumerProfileResponse> {
    return this.http.get<FitnessConsumerProfileResponse>(`${this.token.environment.azureFunctionsApi.users}/users/${userId}/fitness-consumer-profile`).pipe(
      catchError<any, any>((response: HttpErrorResponse) => this.errorResponseCatch(response)),
    );
  }

  updateFitnessConsumerProfile(profile: FitnessConsumerProfile): Observable<FitnessConsumerProfileResponse> {
    return this.http.put<FitnessConsumerProfileResponse>(`${this.token.environment.azureFunctionsApi.users}/users/${profile.userId}/fitness-consumer-profile`, profile);
  }

  getFitnessConsumerProfileByUsername(username: string): Observable<UserAndProfileResponse> {
    return this.http.get<UserAndProfileResponse>(`${this.token.environment.azureFunctionsApi.users}/users/username/${username}/fitness-consumer-profile`);
  }

  private errorResponseCatch(response: HttpErrorResponse): unknown {
    if (response.status === 400 && this.token.app === 'admin') {
      this.window.location.href = this.token.environment.fitscoveryApps.onboardingUrl;
    }
    throw response;
  }

}
