import { Metadata, EventData } from '../interfaces';
import { ApplicationProvider } from './application.provider';

export class MyWebProvider extends ApplicationProvider {

  constructor(eventData: EventData) {
    super(eventData);
  }

  get routeMetadata(): Metadata {
    return {
      ...this.customMetadata,
      ...this.defaultMetadataConfig
    }
  }

  private get customMetadata(): Metadata | undefined {

    const path = this.defaultMetadataConfig.url!;

    if (path.includes('auth/login')) return {
      title: 'Admin Portal',
      description: '',
    }
    
    return undefined;
  }

}
