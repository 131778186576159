import { getActionTypeFromInstance, NGXS_PLUGINS } from "@ngxs/store";

import { ApiAuthAction } from "@fitscovery/auth/data-access";
import { userStateDefaults } from "@fitscovery/user/data-access";
import { partnerMembershipsStateDefaults } from "@fitscovery/memberships/data-access";

function partnerWebLogoutPlugin(state: any, action: any, next: any) {

  if (getActionTypeFromInstance(action) === ApiAuthAction.SignOut.type) {
    state = {
      api_user: userStateDefaults,
      api_partners: state.api_partners,
      api_partner_websites: state.api_partner_websites,
      api_memberships: {
        ...state.api_memberships,
        partnerMemberships: {
          ...partnerMembershipsStateDefaults,
          subscriptions: state.api_memberships.partnerMemberships.subscriptions
        }
      },
      api_events: state.api_events,
      payment: state.payment,

      workout: state.workout,
      program: state.program,
      
      posts: state.posts,
      postUserPoints: state.postUserPoints,

      domain_schedules: state.domain_schedules,
      application: state.application,
      shared: state.shared
    };
  }

  return next(state, action);
}

export const PartnerWebLogoutPluginProvider = {
  provide: NGXS_PLUGINS,
  useValue: partnerWebLogoutPlugin,
  multi: true
};
