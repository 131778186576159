export * from './api-partner-websites.service';
export * from './api-admin-partner-websites.service';
export * from './api-backoffice-partner-websites.service';
export * from './api-private-partner-websites.service';

import { ApiPartnerWebsitesService } from './api-partner-websites.service';
import { ApiAdminPartnerWebsitesService } from './api-admin-partner-websites.service';
import { ApiBackofficePartnerWebsitesService } from './api-backoffice-partner-websites.service';
import { ApiPrivatePartnerWebsitesService } from './api-private-partner-websites.service';

export const ApiServices = [
  ApiPartnerWebsitesService,
  ApiAdminPartnerWebsitesService,
  ApiBackofficePartnerWebsitesService,
  ApiPrivatePartnerWebsitesService
];
