import { MediaType } from '../enums';

export class Exercise {
	id: string;
	title?: string;
	description?: string;
	mediaUrl: string;
	mediaType: MediaType;
	order: number;
	equipment: string;
	difficulty: boolean;
	workoutId: string;
	partnerId: string;
}
