export namespace SessionAction {

  export class SetAccessToken {
    static readonly type = '[Auth API] SetAccessToken';
    constructor(public accessToken: string) { }
  }

  export class CreateSession {
    static readonly type = '[Auth API] CreateSession';
    constructor(public idToken: string) { }
  }

  export class CheckSession {
    static readonly type = '[Auth API] CheckSession';
    constructor() { }
  }

  export class SignInWithCustomToken {
    static readonly type = '[Auth API] SignInWithCustomToken';
    constructor(public customToken: string) { }
  }

  export class Authenticate {
    static readonly type = '[Auth API] Authenticate';
    constructor() { }
  }

}
