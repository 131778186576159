import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'clientTime'
})
export class ClientTimePipe implements PipeTransform {

  transform(date: Date, option?: string) {
    const toClientTime = date.toString().includes('Z') ? date : (<any>`${date.toString()}Z`);
    return option === 'booked-sessions' 
      ? this.bookedSessionsDateTimeFormat(date) 
      : toClientTime;
  }

  bookedSessionsDateTimeFormat(date: Date) {
    return moment(date).format('dddd, MMMM Do, YYYY')
  }

}
